//Style news-article page
.news-article-page {
    padding-top: 35px;

    .main-container {
        padding-bottom: 260px;
    }

    .news-title {
        color: $orange;
        letter-spacing: 0.2px;
        margin-bottom: 50px;
        padding-right: 20px;
        padding-top: 14px;
    }

    .news-author {
        margin-bottom: 27px;
    }

    .sub-title {
        margin-bottom: 60px;
    }

    .sub-title,
    h3 {
        font-size: 34px;
        line-height: 44px;
        font-weight: 400;
    }

    .image-caption {
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
        color: $color-focus-input;
    }

    .text-content {
        margin-bottom: 33px;
        display: flex;

        p > strong {
            font-size: 18px;
            line-height: 24px;
        }

        h3 {
            margin-top: 48px;
            margin-bottom: 48px;

            & + h3 {
                margin-top: 0;
            }
        }

        h4 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            // margin-bottom: 8px;
            // margin-top: 38px;
        }

        // p {
        //     margin-bottom: 8px;
        // }

        h3 {
            color: #008065;
        }

        ol,
        ul {
            // margin-bottom: 8px;
            padding-left: 25px;

            li::marker {
                font-size: 16px;
                letter-spacing: 1.5px;
            }
        }
    }

    .share-news {
        margin-top: 75px;
        margin-bottom: 50px;

        .share-text {
            width: 40%;
        }

        .share-button {
            width: 60%;
            max-height: 30px;
            text-align: right;
            font-size: 0;
        }

        a {
            width: 30px;
            height: 30px;
            margin-left: 13px;
            display: inline-block;
            position: relative;
            background: unset;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 30px;
                height: 30px;
            }

            &:hover {
                &::before {
                    opacity: 0.8;
                }
            }
        }

        .share-email {
            &::before {
                background: url("../../public/images/share-message.svg") no-repeat;
            }
        }

        .share-facebook {
            &::before {
                background: url("../../public/images/share-facebook.svg") no-repeat;
            }
        }

        .share-instagram {
            &::before {
                background: url("../../public/images/share-instagram.svg") no-repeat;
            }
        }

        .share-linkedin {
            &::before {
                background: url("../../public/images/share-linkedin.svg") no-repeat;
            }
        }
    }

    .more-button {
        display: flex;

        .btn {
            width: 159px;
            height: 41px;
            text-transform: uppercase;
        }

        .btn-primary {
            margin-right: 31px;
        }
    }

    .author-name,
    .share-container {
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
    }

    .author-name {
        padding: 13px 0;
    }

    .share-container {
        padding: 9px 0;
        display: flex;
        align-items: center;
    }

    .text-content.short-subhead {
        margin-bottom: 50px;

        .f-body {
            font-size: 34px;
            line-height: 44px;
            font-weight: 400;
            color: #000;
        }
    }

    .video--iframe {
        aspect-ratio: 16 / 9;
        margin-top: 44px;
        margin-bottom: 35px;
        position: relative;

        img {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .play {
            height: 77px;
            left: 50%;
            margin-left: -38px;
            margin-top: -38px;
            position: absolute;
            top: 50%;
            width: 77px;
            background: url(/images/play-button.svg) no-repeat center;

            &:hover {
                background: url(/images/play-button-hover.svg) no-repeat center;
            }
        }
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: inherit;
    }

    .m-media__img--video,
    .m-media {
        height: 100%;
    }

    .video--iframe {
        .video-article {
            position: relative;
            aspect-ratio: 16/9;
        }
    }

}

@media all and (min-width: 768px) {
    .news-article-page {
        .container {
            position: relative;
        }

        .right-side {
            max-width: 60%;
            margin-left: auto;
            padding-left: 31px;

            &.share-news {
                display: block;
            }
        }

        .news-image-left {
            position: absolute;
            top: 0;
            left: 20px;
            width: calc(40% - 15px);
            max-width: 445px;

            .image-caption {
                margin-top: 10px;
            }
        }

        .news-image-right {
            width: 75%;
            margin-left: auto;
            margin-top: 44px;
            max-width: 825px;

            .image {
                max-width: 825px;
                max-height: 500px;
                width: 100%;
                margin-left: auto;
            }

        }

        .news-content .image-caption {
            margin: 21px 0 14px 0;
        }

        .video--iframe {
            width: 75%;
            margin-left: auto;
            max-width: 825px;
        }

        .text-content {
            h2 {
                font-weight: 400;
                // margin-bottom: 66px;
            }

            &.right-side:first-child {
                min-height: 100px;
            }

            h3 {
                width: 130%;
                float: right;
                padding-right: 20px;

                strong {
                    font-weight: 400;
                }
            }
        }
    }
}

@media all and (max-width: 767px) {
    .news-article-page {
        padding-top: 25px;

        .main-container {
            padding-bottom: 56px;
        }

        .news-title {
            letter-spacing: 0;
            padding-right: 0;
            margin-bottom: 24px;
        }

        .news-author {
            margin-bottom: 24px;
        }

        .news-image-left {
            margin-bottom: 40px;

            img {
                width: 100%;
            }
        }

        .image-caption {
            margin-top: 12px;
            font-size: 12px;
            line-height: 14px;
        }

        .sub-title,
        h3 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 30px;
        }

        .text-content sub + p {
            margin-top: 29px;
        }

        .news-image-right {
            margin-top: 40px;
            margin-bottom: 40px;

            img {
                width: 100%;
            }
        }

        .share-news {
            margin-top: 55px;
            margin-bottom: 40px;
            width: 100%;
        }

        .more-button {
            .btn-primary {
                display: none;
            }

            .btn-secondary {
                width: 100%;
            }
        }

        .text-content {
            h3 {
                margin-bottom: 40px;
                margin-top: 40px;
            }

            p > strong {
                display: inline-block;
            }
        }

        .text-content.short-subhead {
            .f-body {
                font-size: 28px;
                line-height: 38px;
                font-weight: 700;
            }
        }
    }
    .g-intro-only > .mobile {
        display: block !important;
    }
}

//update
.news-article-page {
    .text-content .f-body {
        h3 + * {
            clear: both;
        }
    }

    .short-subhead {
        .f-body p {
            display: block;
        }
    }

}
