.membership-application {
    .mt-16 {
        margin-top: 16px;
    }
    
    &.dealer {
        h1.g-intro-title {
            color: $blue;
        }

        .image-Text-right .btn-primary {
            background-image: none;
            position: static;
            padding: 6px 15px;
        }
    }

    &.supporter {
        h1.g-intro-title {
            color: $green;
        }

        .image-Text-right .btn-primary {
            background-image: none;
            position: static;
            padding: 6px 15px;
        }

        .image-Text-right li {
            margin-bottom: 23px;
            padding: 0;

            &:after {
                display: none;
            }
        }

        .image-Text-right ol {
            margin-top: 9px;
        }
    }

    .text-danger {
        color: $orange;
        font-size: 14px;
        line-height: 18px;
        display: inline-block;
        margin-top: 3px;
    }

    .featured-artist {
        display: none;
    }

    .g-section__image--rounded-frame {
        border-radius: 9999px 9999px 0 0;
        margin-top: 48px;
        overflow: hidden;
        padding-bottom: 50%;
        position: relative;

        img {
            bottom: 0;
            height: 100%;
            left: 0;
            -o-object-fit: cover;
            object-fit: cover;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }

    .g-intro-title {
        letter-spacing: 0.2px;
    }

    .form-group {
        label,
        input,
        select {
            font-size: 18px;
            line-height: 24px;
            width: 100%;
        }
    }

    .image-Text {
        overflow: hidden;

        ul {
            display: inline-block;
        }

        .title {
            color: $green-heading;
        }

        .btn {
            width: 100%;
        }

        .btn-primary {
            position: relative;

            &:hover {
                background-color: $light-black;
            }
        }

        strong {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .image-caption {
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
        color: $color-focus-input;
        margin-top: 16px;
    }

    + .g-content__question-and-link {
        background: $color-border-load;
    }

    + .g-content__question-and-link .g-content__question-and-link-inner .question-and-link-row {
        border-top: none;
    }
}

.membership-apply-form {
    .custom-select .selected-item {
        &:before {
            background: $color-border-load;
        }
    }

    .col-4 {
        border: 1px solid $black;
        padding: 17px 18px 19px 18px;
        overflow: hidden;

        .form-title {
            font-size: 14px;
            line-height: 22px;
            border-bottom: 1px solid $black;
            padding-bottom: 12px;
        }
    }

    .after-validated.col-4 {
        border: solid 2px #008065;
    }

    .form-group {
        margin-bottom: 15px;

        label,
        input,
        select {
            font-size: 18px;
            line-height: 24px;
            width: 100%;
        }
    }

    form {
        margin-bottom: 0;
    }

    .btn {
        text-transform: uppercase;
        width: 100%;
        padding: 6px;
    }

    .form-register {
        background: $color-border-load;
        border-width: 2px;

        input:-webkit-autofill {
            @include css-prefix((box-shadow: 0 0 0 50px $color-border-load inset));
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
            -webkit-text-fill-color: $black;
        }

        .form-control::placeholder,
        .form-group input {
            color: $black;
        }

        .note,
        .note a {
            font-size: 10px;
            line-height: 14px;
            color: $color-note1;
            letter-spacing: 0.3px;
        }

        .note {
            margin-top: 33px;
            padding-right: 16px;
        }

        .note a {
            background: linear-gradient(90deg, $color-note1 calc(100% / 3), transparent 0 calc(200% / 3), $color-note1 0) var(--d, 0%) 90% /300% 1px no-repeat;
        }

        .btn {
            margin-top: 16px;
        }

        form {
            margin-top: 66px;
        }

        .selected-item {
            background: transparent;
            padding-top: 12px;
            color: $color-focus-input;
        }
    }

    .form-login {
        input:-webkit-autofill {
            @include css-prefix((box-shadow: 0 0 0 50px $white inset));
        }

        .note {
            font-size: 14px;
            line-height: 18px;
            color: $color-note2;
            margin-top: 15px;
        }

        form {
            margin-top: 66px;
        }

        .btn {
            margin-top: 31px;
            max-width: 188px;
        }

        .forgot-pass {
            margin-top: 16px;
        }
    }

    .form-contact {
        .question {
            font-size: 18px;
            line-height: 24px;
            margin-top: 101px;
            color: $color-text1;
            margin-bottom: 23px;
        }

        .compare {
            margin-top: 18px;
        }

        .contact {
            margin-top: 96px;
            position: relative;
            padding-left: 25px;

            &:before {
                content: '';
                background: url("../../public/images/question-icon.png") center/100% 100% no-repeat;
                width: 25px;
                height: 26px;
                display: block;
                position: absolute;
                top: 0;
                left: -3px;
            }
        }
    }

}

// Desktop
@media only screen and (min-width: 1366px) {
    .membership-application {
        &.dealer {
            .g-intro-title {
                padding-right: 148px;
            }
        }

        .image-caption {
            padding-right: 85px;
        }
    }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
    .membership-application {
        .o-step__become-member {
            .step .custom-link {
                margin: 8px auto;
            }
        }
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .membership-application {
        .o-step__become-member {
            .step .custom-link {
                width: 100px;
            }
        }

        .form-contact .compare {
            padding-right: 68px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .membership-application {
        margin-top: 38px;

        &.supporter .image-Text {

            .buttons--container {
                margin-top: 76px;
            }

            .g-section__image--mobile {
                display: none;
            }

            .g-section__image-right {
                padding-left: 15px;
            }

            .g-section__description {
                margin-top: 25px;
                padding-left: 0;
            }

            .g-section__description .f-body {
                margin-top: 25px;
            }

            .image-caption {
                padding-right: 35px;
                margin-top: 19px;
            }
        }

        .form-register {
            .btn {
                max-width: 187px;
            }
        }

        .form-contact {
            .question {
                padding-right: 15px;
            }
        }

        .g-intro-title {
            padding-right: 90px;
        }

        .paragraph-content {
            ol,
            ul {
                display: flex;
                flex-wrap: wrap;
            }

            a {
                margin-right: 52px;
            }
        }

        .image-Text-right {
            .image-caption {
                padding-right: 35px;
                margin-top: 19px;
            }
        }

        + .g-content__question-and-link .g-content__question-and-link-inner .question-and-link-row {
            padding-bottom: 113px;

            .g-content_question-item {
                &:nth-child(3) {
                    padding-left: 11px;
                }

                h3 {
                    margin-bottom: 72px;
                }
            }
        }
    }

    .membership-apply-form {
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
        padding: 20px 0;
    }
}

// Mobile
@media only screen and (max-width: 767px) {
    .membership-application {
        margin-top: 28px;

        &.supporter {
            .image-Text-right {
                .g-section__image--mobile {
                    margin-top: 24px;
                }

                li {
                    &:last-child {
                        margin-bottom: 20px;
                    }
                }

                ol {
                    list-style-type: decimal;
                    padding-left: 24px;
                }

                .g-section__description > .f-body {
                    margin-top: 23px;
                }

                .buttons--container {
                    display: inline-block;
                    width: 100%;
                }
            }
        }

        .image-caption {
            font-size: 12px;
            line-height: 14px;
            margin-top: 12px;
        }

        .membership-apply-form-wrapper {
            margin-top: 24px;
        }

        .o-step__become-member,
        .image-Text {
            max-width: 100%;
            padding: 0 32px;
        }

        .paragraph-content {
            margin-top: 39px;

            .softbreak {
                display: none;
            }
        }

        .image-Text {
            display: inline-block;
            width: 100%;

            img {
                width: 100%;
            }

            .title {
                font-size: 34px;
                line-height: 44px;
            }

            .btn-primary {
                background-position: 94.5% 46%;
                padding: 6px 38px;

                &:after {
                    right: 15px;
                }
            }

        }

        .image-Text-right {

            .g-section__image--mobile {
                margin-top: 24px;
            }

            li {
                &:last-child {
                    margin-bottom: 20px;
                }
            }

            ol {
                list-style-type: decimal;
                padding-left: 24px;
            }

            .g-section__description > .f-body {
                margin-top: 23px;
            }

            .buttons--container {
                display: inline-block;
                width: 100%;
            }
        }

        + .g-content__question-and-link {

            .question-and-link-row {
                padding-bottom: 67px;
            }

            .question-and-link-row .g-content_question-item,
            .g-content__question-and-link-inner .question-and-link-row .g-content_question-item h3 {
                padding: 0;
            }

            .question-and-link-row .g-content_question-item a .arrows-long:after {
                left: 0;
            }
        }
    }

    .membership-apply-form {
        .col-4 {
            margin-bottom: 32px;
            padding-bottom: 24px;
        }

        .form-register {
            .login-continue {
                margin-top: 23px;
            }

            .login-continue,
            .login-continue a {
                color: $color-note2;
                font-size: 14px;
            }

            .login-continue a {
                padding-left: 2px;
                background: linear-gradient(90deg, $color-note2 calc(100% / 3), transparent 0 calc(200% / 3), $color-note2 0) var(--d, 0%) 90% /300% 1px no-repeat;
            }
        }

        .form-login {
            .forgot-pass {
                margin-top: 12px;
            }

            .forgot-pass,
            .forgot-pass a {
                font-size: 14px;
            }
        }

        .form-contact {
            padding-bottom: 20px;

            .question {
                margin-top: 23px;
                font-size: $font-size-base;
                line-height: $line-height-base;
            }

            .compare {
                margin-top: 6px;
            }

            .contact {
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .membership-application {
        + .g-content__question-and-link .question-and-link-row .g-content_question-item {
            padding: 0;
        }
    }
}

.membership-application .o-step__become-member .step {
    background-color: #fff;
    z-index: 1;
}

html:not(.no-js) .membership-application .o-step__become-member .line[data-aos=fade-right] {
    transform: translate3d(-36px, 0, 0);
}

html:not(.no-js) .membership-application .o-step__become-member .line[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
}

//style for section g-member
.g-member .g-member__slider {
    .owl-stage-outer {
        z-index: 2;
    }
}
