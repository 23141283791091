.g-intro-shortdesc > .f-body > * {
    display: none;
}

.o-membership-item {
    border: 2px solid;
    padding: 23px 19px;
    position: relative;
    margin-bottom: 20px;

    .name > * {
        margin-bottom: 12px;
    }

    .btn {
        width: 100%;
        max-width: 145px;
    }

    .btn-secondary {
        max-width: 137px;
    }
}

.o-membership-item-description {
    padding-top: 24px;
    border-top: 1px solid;
}

.o-membership-items {
    word-break: break-word;
}


.o-membership-block {
    .m-title-bar {
        text-align: center;
        letter-spacing: .2px;
    }
}


// Desktop

@media only screen and (min-width: 992px) {
    .o-membership-items {
        display: flex;
        margin: 43px 0 0;
        column-gap: 30px;
    }

    .o-membership-item {
        width: calc(100% / 3);
        margin-bottom: 0;

        .actions {
            position: absolute;
            bottom: 32px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .paragraph-content {
        display: flex;
        flex-wrap: wrap;
        max-width: 1150px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 20px;

        ol,
        ul {
            display: flex;
            flex-wrap: wrap;
        }

        a {
            margin-right: 51px;
        }
    }

    .membership {
        .paragraph-content {
            column-gap: 40px;

            ol,
            ul {
                display: flex;
                flex-wrap: wrap;
                column-gap: 40px;
            }
        }
    }

    .o-membership-item {
        padding-bottom: 109px;

        .actions {
            display: flex;
            justify-content: center;
            gap: 10px 20px;
            width: calc(100% - 42px);
        }
    }

    .o-membership-block {
        max-width: 1150px;
        width: 100%;
        margin: 50px auto 0;
        padding: 0 20px;
    }

    .panel.membership {
        .link-action {
            flex-wrap: wrap;
            row-gap: 16px;
        }
    }
}

// Mobile

@media (max-width: 991.98px) {
    .o-membership-items {
        margin-top: 32px;
    }

    .o-membership-item {
        margin-bottom: 48px;
        padding: 23px 22px;

        .btn {
            margin-top: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .o-membership-block {
        h1 {
            font-size: 28px;
            line-height: 38px;
        }

        .m-title-bar {
            letter-spacing: 0;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .paragraph-content {
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .paragraph-content,
    .o-membership-block {
        max-width: 100%;
        padding: 0 32px;
    }

    .o-membership-item {
        .btn {
            max-width: 100%;
            margin-bottom: 10px;
        }

        .btn:first-child {
            margin-top: 31px;
        }

        .actions {
            margin-bottom: 7px;
        }
    }
}

