// Typography
$font-family-sans-serif: "DM Sans", sans-serif;
$font-awesome-pro: "Font Awesome 5 Pro";
$font-awesome-brands: "Font Awesome 5 Brands";
$font-size-base: 16px;
$line-height-base: 22px;

// Colors
$black: #000;
$light-black: #333;
$white: #fff;
$orange: #e74c39;
$light-orange: #f1745d;
$green: #008065;
$middle-green: #008264;
$light-green: #009a81;
$green-heading: #008264;
$blue: #004b87;
$light-blue: #146a9f;
$color-focus-input: #939598;
$color-border-radio: #0a0a0b;
$color-form-label: #777;
$color-border-load: #ececec;
$color-gray-tab: #a6a2a2;
$color-text-light-black: #373f41;
$color_orange_slider_dot_active: #f29584;
$color_orange_slider_dot: #f1745d;
$color-note1: #3d4041;
$color-note2: #6a6a6a;
$color-text1: #161616;
$color-text2: #675F5F;
$color-text3: #C4C4C4;
$color-text4: #D9D9D9;
$color-text5: #101010;
$bg-color-f1: #F1F1F1;
$bg-scroll: rgba(51, 51, 51, 0.24);
