.news-article-page {
    .news-content{
        > *{
            display: inline-block;
        }
    }
    .article-head {
        padding-top: 24px;

        .article-head-outer {
            display: flex;
        }

        .article-head-outer-col {
            &:first-child {
                width: 40.1%;
            }

            &:nth-child(2) {
                width: 59.9%;
                padding-left: 31px;
                padding-top: 14px;
            }

            .img-outer {
                max-width: 445px;

                img {
                    width: 100%;
                }
            }

            p.image-description {
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: $color-focus-input;
            }

            h1.title {
                font-size: 42px;
                line-height: 51px;
                letter-spacing: 0.2px;
                color: $orange;
                margin-bottom: 20px;
            }

            p.author {
                font-size: 16px;
                padding: 25px 0;
                line-height: 22px;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                margin-bottom: 20px;
            }

            p.desctiption {
                font-size: 34px;
                line-height: 44px;
            }
        }
    }

    .article-main {
        &-inner {
            display: flex;
            justify-content: flex-end;
        }

        &-content {
            width: 59.9%;
            padding-left: 31px;

            h2 {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 30px;
            }

            .img-frame {
                display: flex;
                justify-content: flex-end;

                img {
                    max-width: 825px;
                }
            }

            .social-links {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $black;
                border-top: 1px solid $black;

                p.title {
                    margin-bottom: 0;
                }

                .socials-col {
                    .g-footer__link {
                        a {
                            display: inline-block;
                            background: transparent;
                            position: relative;
                            width: 40px;
                            height: 40px;
                            margin-right: 16px;

                            &::before {
                                background-color: transparent;
                                @include css-prefix(
                                    (
                                        transition: 0.1s,
                                    )
                                );
                            }

                            &::after {
                                background-color: transparent;
                            }

                            &:first-child {
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 40px;
                                    height: 40px;
                                    background-image: url("../../public/images/icons/footer_facebook_black.svg");
                                }

                                &:hover {
                                    &::before {
                                        background-image: url("../../public/images/icons/footer_facebook_light_black.svg");
                                    }
                                }
                            }

                            &:nth-child(2) {
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 40px;
                                    height: 40px;
                                    background-image: url("../../public/images/icons/footer_instagram_black.svg");
                                }

                                &:hover {
                                    &::before {
                                        background-image: url("../../public/images/icons/footer_instagram_light_black.svg");
                                    }
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 40px;
                                    height: 40px;
                                    background-image: url("../../public/images/icons/footer_instagram_black.svg");
                                }

                                &:hover {
                                    &::before {
                                        background-image: url("../../public/images/icons/footer_instagram_light_black.svg");
                                    }
                                }
                            }

                            &:nth-child(4) {
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 40px;
                                    height: 40px;
                                    background-image: url("../../public/images/icons/footer_instagram_black.svg");
                                }

                                &:hover {
                                    &::before {
                                        background-image: url("../../public/images/icons/footer_instagram_light_black.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .print-share-buttons {
                button {
                    text-transform: uppercase;
                }

                .print {
                }

                .share {
                }
            }
        }
    }
}
