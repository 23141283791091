/*
*   Video section
*/
//thumbnail customizations

body {
    margin: 0;            /* Reset default margin */
}
iframe {
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
}

.title.mobile{
    display: none;
}

.article-video__caption{
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color-focus-input;
    padding-top: 10px;
    margin-bottom: 20px;
}
.article-video{
    width: 100%;

    .video--iframe{
        position: relative;
        aspect-ratio: 16/9;
    }
    .play:hover{
        opacity: 0.7;
    }
    figure{
        height: 100%;
        position: relative;

        img{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .m-media__img--video{
        height: 100%;
    }
    .play{
        height: 77px;
        left: 50%;
        margin-left: -38px;
        margin-top: -38px;
        position: absolute;
        top: 50%;
        width: 77px;
        background: url(../../public/images/play-button.svg) no-repeat center;
        &:hover{
            background: url(../../public/images/play-button-hover.svg) no-repeat center;
        }
    }


}

.youtube .thumb{
    background-color: #D1CFCF;
}
.article-video{
    width: 100%;
}
.video--iframe{
    iframe{
        border: none;
    }
}
.video--caption, 
.video--caption p{
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color:#939598;
    margin-top: 10px;
    max-width: 727px;
}
.video-section{
    padding-top: 30px;
    padding-bottom: 30px;

    &-button{
        margin-top: 30px;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    .flex-box {
        padding-bottom: 30px;
    }

    .mobile-title {
        display: none;
    }

    .article-video {
        @include display-flex-cs();
        flex-wrap: wrap;

        .video--iframe {
            width: 100%;
        }
    }

    .article-headings {
        width: 100%;
        .title {
            margin-bottom: 24px;
        }
    }
}

@media (min-width: 921px) {
    .video-section {
        .article-headings {
            max-width: calc((417/1110)*100%);
            margin-right: 58px;
        }

        .article-video {
            max-width: calc((635/1110)*100%);
        }
    }

    .homepage {
        .video-section {
            .article-headings {
                max-width: calc((236/1110)*100%);
                margin-right: 163px;
            }

            .article-video {
                max-width: calc((711/1110)*100%);
            }
        }
    }
}

@media screen and (max-width: 920px){
    
    .video-section {
        .flex-box{
            flex-direction: column;
        }

        .arrows-long:after {
            margin-left: 0;
            left: 0;
            bottom: -12px;        
        }

        .article-headings {
            max-width: 100%;
            margin-right: 0;
        }

        .article-video{
            max-width: 100%;
        }

        .article-headings{
            max-width: unset;
            margin-right: 0;
        }

        .title{
            margin-bottom: 48px;
        }        
    }

    .homepage .video-section .article-headings{
        max-width: unset;
        margin-right: 0;
    }
    
    .homepage .video-section .title{
        margin-bottom: 48px;
    }

    .homepage{
        .g-articles{
            .article-video{
                max-width: unset;
                margin-top: 24px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .video-section {
        h2{
            font-weight: 700;
            font-size: 34px;
            line-height: 44px;
        }

        .article-headings{
            .title{
                margin-bottom: 24px;
            }
        }

        .video-section-button {
            .f-buttons {
                width: 100%;
                min-height: 48px;
                line-height: 34px;
            }
        }
    }
}
@media screen and (max-width:480px) {
    .homepage .g-articles.video-section{
        padding-bottom: 34px;
    }

    .video--caption{
        font-size: 12px;
        line-height: 14px;
    }

    .video-section {
        .article-video{
            margin-top: 32px;
        }
    }
}