.hide{
    display: none;
}
.success-message{
    margin-bottom: 71px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
.submit-newsletter{
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
}
.g-newsletter{
    .box-loading{
        background-color: #009a81;
    }
    .load::after,
    .load {
        background: none repeat scroll 0 0 #009a81;
    }
}
@media screen and (max-width: 375px){
    .submit-newsletter{
        height: 48px;
        width: 100%;
        justify-content: center;
    }
    .success-message{
        margin-bottom: 51px;
    }
}