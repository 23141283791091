.homepage ~ .funders-and-partners{
    .owl-stage{
        display: flex;
        align-items: center;
    }
    .owl-nav{
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: calc(100% + 82px);
        top: 10%;
        transform: translate(-41px);
    }
    .owl-theme .owl-nav.disabled + .owl-dots,
    .owl-dots{
        margin-top: 44px;
    }
    .owl-nav .owl-next svg{
        transform: rotate(-90deg);
        transition: all 0.5s;
    }
    .owl-nav .owl-next:hover svg {
        transform: rotate(-90deg) translateY(5px);
    }
    .owl-nav .owl-prev svg{
        transform: rotate(90deg);
        transition: all 0.5s;
    }
    .owl-nav .owl-prev:hover svg {
        transform: rotate(90deg) translateY(5px);
    }
    .owl-nav [class*=owl-]:hover{
        background: unset;
        color: unset;
    }
    .owl-theme .owl-nav [class*=owl-]:hover {
        background: unset;
    }
    .owl-theme .owl-dots .owl-dot.active span {
        background: #939598;
    }
    .owl-theme .owl-dots .owl-dot span{
        background: #c4c4c4;
    }
    .item{
        max-height: 81px;
        width: auto;
    }
    .item a{
        display: flex;
        max-height: 80px;
        background:none;
        margin: auto;
    }
    .item a img{
        width: auto !important;
        object-fit: contain;

    }
}

.blocker {
    z-index: 101;
}

.funders-and-partners-inner{
    padding-top: 67px;
    // border-bottom: solid 1px #000;
    padding-bottom: 46px;
}


.partners-title{
    text-align: center;
    margin-bottom: 56px;
    font-weight: 400;
}


@media screen and (min-width: 1180px){
    .homepage ~ .funders-and-partners{
        .owl-carousel .owl-nav.disabled{
            display: flex;
        }
        .owl-carousel .owl-dots.disabled{
            display: block;
        }
    }
}
@media screen and (max-width: 1180px){
    .partners-slider{
        max-width: 90%;
        margin: auto;
    }
    .homepage ~ .funders-and-partners{
        .owl-nav{
            width: calc(100% + 60px);
            transform: translateX(-30px);
        }
    }
}
@media screen and (max-width: 480px) {
    .homepage ~ .funders-and-partners{
        .owl-nav{
            display: none;
        }
    }
}
@media screen and (max-width: 375px) {
    .funders-and-partners-inner{
        padding: 45px 0;
        padding-bottom: 43px;
    }
    .homepage ~ .funders-and-partners{
        .owl-theme .owl-nav.disabled + .owl-dots,
        .owl-dots{
            margin-top: 65px;
        }
    }
}
