// Jquery
@import "~jquery-ui/themes/base/all.css";

// Animate On Scroll Library
@import "aos/dist/aos.css";
@import "jquery-modal/jquery.modal.css";
@import "jquery-confirm/css/jquery-confirm.css";

// Owl Carousel
@import "owl.carousel.min";
@import "owl.theme.default.min";

// Font awesome
@import "awesome";

// Other file scss
@import "variables";
@import "styleguide";

// header
@import "header";

// Popup news
@import "popup-news";

// Newsletter
@import "newsletter";

// Partners section
@import "content--partners";

// Video section
@import "video-article";

// editor faqs
@import "quill/dist/quill.core";

// timepicker
@import "timepicker";

// fancybox
@import "@fancyapps/ui/dist/fancybox.css";
@import "magnific-popup";

// croppie image
@import "croppie.min.scss";

// Pages
@import "footer.scss";
@import "about-us";
@import "homepage";
@import "faqs";
@import "artist-member-form";
@import "membership";
@import "artistmembership";
@import "news-article-page.scss";
@import "news-article.scss";
@import "latestnews";
@import "dealer-member-form";
@import "supporter-member-form";
@import "contact-us";
@import "policy";
@import "customer-dashboard";
@import "artist-page";
@import "dealer-page";
@import "public-profile-page";
@import "event-form";
@import "event-search";
@import "error-page";
@import "no-longer-member";
@import "supporter-page";
@import "search-page";
@import "artists_tab";