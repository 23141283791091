.event--search {
    .search__action {
        padding-bottom: 30px;
        position: relative;
        z-index: 2;

        .form-search {
            @include display-flex-cs();
            @include css-prefix(
                    (
                        align-items: flex-end,
                    )
            );
            column-gap: 30px;
        }
    }

    .custom-select .all-items {
        z-index: 1;
    }

    .box__filter {
        padding-top: 43px;
    }

    .result__list {
        margin-top: 25px;
        width: 67%;

        .item {
            display: none;
            margin-bottom: 48px;

            .event-name {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                position: relative;

                h2 {
                    color: $orange;
                    margin-right: 15px;
                    font-weight: 400;

                    &::after {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        width: 47px;
                        height: 11px;
                        background: url(/images/icons/arrow-latest-news.svg);
                        margin-left: 17px;
                        transition: all 0.5s;
                    }
                }

                &:hover h2::after {
                    margin-left: 22px;
                }

                .icon {
                    margin-top: 5px;
                }
            }

            .location {
                margin-bottom: 8px;

                h3 {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #373F41;
                }
            }

            .time {
                margin-bottom: 16px;

                p {
                    font-style: italic;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #373F41;
                }
            }

            .description {
                margin-bottom: 16px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                margin-top: 17px;
                overflow: hidden;
            }

            p, a {
                color: #373F41;
            }
        }

        .item:last-child {
            margin-bottom: 0;
        }
    }

    .result__show-more {
        margin-top: 130px;
    }
}

.search__action .form-search .form-group {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .event--search {
        .box__filter {
            padding-top: 30px;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
        }

        .result__list {
            margin-top: 48px;
            width: 100%;
        }

        .search__action {
            h1 {
                letter-spacing: unset;
            }
        }

        .result__list {
            .item {
                margin-bottom: 56px;

                .event-name {
                    flex-direction: column;
                    align-items: flex-start;

                    h2 {
                        font-weight: 700;
                        display: flex;
                        flex-direction: column;
                        row-gap: 12px;
                    }

                    .icon {
                        margin-top: 0;
                    }
                }

                .location {
                    margin-bottom: 12px;

                    h3 {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .time {
                    margin-bottom: 24px;
                }

                .description {
                    margin-bottom: 12px;
                    -webkit-line-clamp: 5;
                }
            }
        }

        .result__show-more {
            margin-top: 56px;
            column-gap: 0;
            justify-content: space-between;
        }

        .dropdown--text {
            position: static;
            width: 100%;
            text-align: left;

            &::after {
                right: 0;
            }
        }

    }
    .event--search .result__list .item .event-name h2::after {
        margin-left: 0;
    }
}


@media screen and (max-width: 450px) {
    .event--search .dropdown--text {
        min-width: unset;
    }
}
