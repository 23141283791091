/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 6 version
*/

@media all {
    .search-page {
        margin-top: 39px;

        .custom-select .all-items {
            max-height: 408px;
        }

        input:-webkit-autofill {
            @include css-prefix((box-shadow: 0 0 0 50px $white inset));
        }

        .page-main-title {
            color: $orange;
            letter-spacing: .2px;
        }

        .form-search-wrapper {
            margin-top: 28px;
        }

        .form-search {
            input,
            select {
                width: 100%;
            }

            input {
                padding: 4px 40px 7px 0;
            }

            .form-label {
                margin-bottom: 6px;
            }

            .action {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: end;
                -ms-flex-align: end;
                align-items: flex-end;
            }

            .btn {
                width: 100%;
                text-transform: uppercase;
            }
        }

        .custom-select .selected-item {
            padding-top: 4px;
            padding-right: 28px;
        }

        .key-words {
            position: relative;
        }

        .key-words .icon {
            position: absolute;
            right: 0;
            bottom: 8px;
        }

        .artwork-types {
            margin-top: 59px;

            .label-group {
                font-size: 18px;
                line-height: 24px;
                display: inline-block;
                margin-bottom: 4px;
            }

            .list-artwork-types {
                display: none;
                margin-top: 12px;
                margin-bottom: 16px;
            }

            #filter-artwork-types-btn {
                font-weight: 700;
            }
        }

        .list-artwork-types {
            background: $color-border-load;

            .clear-all {
                font-family: 'DM Sans';
                font-size: $font-size-base;
                line-height: $line-height-base;
                color: $color-gray-tab;
                --d: 100%;
                cursor: pointer;
                text-decoration: none;
                background: -webkit-gradient(linear, left top, right top, from($color-gray-tab), color-stop(0, transparent), color-stop(0, $color-gray-tab)) var(--d, 0%) 90% /300% 1px no-repeat;
                background: -o-linear-gradient(left, $color-gray-tab calc(100% / 3), transparent 0 calc(200% / 3), $color-gray-tab 0) var(--d, 0%) 90% /300% 1px no-repeat;
                background: linear-gradient(90deg, $color-gray-tab calc(100% / 3), transparent 0 calc(200% / 3), $color-gray-tab 0) var(--d, 0%) 90% /300% 1px no-repeat;
                -webkit-transition: 0.5s;
                -o-transition: 0.5s;
                transition: 0.5s;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                border: none;
                height: auto;
                padding: 0;

                &:hover {
                    --d: 0;
                }
            }

            .action {
                padding: 0 43px 18px;
            }

            .checkbox-label {
                position: relative;
                padding-left: 31px;
                display: block;

                &:after,
                &:before {
                    position: absolute;
                    top: 2px;
                    left: 0;
                }
            }
        }

        .form-check .form-check-input[type=checkbox]:checked + .checkbox-label:after {
            top: 2px;
        }

        .search__result {
            padding-bottom: 93px;

            .print-button a {
                text-transform: uppercase;
                width: 160px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }

            .print-button {
                position: relative;
                top: 10px;
            }

            .satellite {
                margin-top: 9px;
                font-size: 14px;
            }

            .author__name,
            .satellite,
            .description {
                color: $color-text-light-black;
                .ql-editor {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .filter.dropdown--box {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        .result__tile-view .item-list {
            &.is-news-article .small-image{
                width: auto;
                border-radius: 0;
                img{
                    object-fit: cover;
                }
            }
            margin-right: 0;
            //max-width: 320px;
            .small-image {
                border-radius: 50%;
                border: 1px solid $color-text3;
                overflow: hidden;
                width: 225px;
                height: 225px;
                padding: 0;
            }
        }

        .result__tile-view {
            .news-article__name {
                h2 span {
                    padding-right: 8px;
                }

                h2:after {
                    display: inline-block;
                    margin-top: 0;
                    margin-left: 0;
                }

                &:hover h2:after {
                    -webkit-transform: translateX(5px);
                    -ms-transform: translateX(5px);
                    transform: translateX(5px);
                    margin-left: 0;
                }
            }
        }

        .result__list-view {
            .information {
                max-width: 755px;
            }

            .news-article__name:hover h2:after {
                margin-left: 22px;
            }
        }

        .result__list-view .item-list {
            .small-image {
                display: none;
            }
        }

        .result__list {
            .result__show-more {
                margin-top: 0;
            }

            .artists .category__name {
                color: $orange;
            }

            .dealers .category__name {
                color: $light-blue;
            }

            .supporters .category__name {
                color: $light-green;
            }

            .category__name {
                text-transform: capitalize;
                color: #373F41;
            }
        }

        + .g-content__question-and-link {
            background-color: $color-border-load;
        }

        + .g-content__question-and-link .question-and-link-row {
            border: none;
        }
    }
}


.link-news-article {
    display: block;
    max-width: 100%;
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    img {
        max-height: 225px;
        width: 100%;
        object-fit: cover;
    }
}
.result__tile-view .item-list .news-article__name {
    position: relative;
}



.search-page .result__tile-view {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    //grid-column-gap: 30px;
    //grid-row-gap: 60px;
    //padding-top: 32px;

    display: flex;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 0;
    .item-list {
        position: relative;
        flex-direction: column;
        display: flex;
        width: calc(100% / 3 - 40px);
        max-width: 320px;
        transition: 1s;
        transform: translateY(15%);
    }
}

.search-page .result__list-view {
    row-gap: 0;
    .item-list {
        transition: 1s;
        transform: translateY(15%);
        display: flex;
        &.showing {
            margin-bottom: 45px;
        }
    }
}

// Desktop
@media all and (min-width: 1200px) {
    .search-page {
        .form-search {
            -ms-grid-columns: 350px 30px 196px 30px 108px 30px 231px 30px 132.3px;
            grid-template-columns: 350px 196px 108px 231px 132.3px;

            &.ownership-container {
                -ms-grid-columns: 350px 30px 266px 30px 267px 30px 132.3px;
                grid-template-columns: 350px 266px 267px 132.3px;
                gap: 30px;
            }

            .action {
                position: relative;
                left: 3px;
            }
        }
    }
}

@media all and (min-width: 768px) and (max-width: 1199px) {
    .search-page {
        .form-search {
            //-ms-grid-columns: (1fr)[3];
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media all and (min-width: 768px) {
    .search-page {
        .form-search {
            display: -ms-grid;
            display: grid;
            gap: 22px;
        }

        .box__filter {
            margin-top: 17px;
        }

        .result__tile-view {
            margin-top: 30px;
        }

        .result__tile-view {
            .item-list.showing {
                margin: 0 0 40px 0;
            }
        }

        + .g-content__question-and-link .question-and-link-row {
            padding: 90px 0;

            .g-content_question-item h3 {
                margin-bottom: 70px;
            }

            .g-content_question-item:nth-child(3) {
                padding-left: 11px;
            }
        }
    }
    .list-artwork-types {
        .list {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 18px 1fr;
            grid-template-columns: repeat(2, 1fr);
            padding: 42px 66px 11px;
            gap: 18px;
        }

        .action {
            text-align: right;
        }
    }
    .search__result {
        .dropdown--list {
            top: 43px !important;
        }
    }
}

@media screen and (max-width: 1110px) {
    .search-page .result__tile-view {
        column-gap: 50px;
        .item-list {
            width: calc(100% / 2 - 25px);
            max-width: unset;
        }
    }
}

// Mobile
@media all and (max-width: 767px) {
    .search-page {
        margin-top: 67px;

        .form-search-wrapper {
            margin-top: 40px;
        }

        .form-search {
            > .form-group {
                margin-bottom: 24px;
            }

            .action {
                margin-top: 16px;
                display: inline-block;
                width: 100%;
            }

            .action .btn {
                min-height: 48px;
            }
        }

        .search__result {
            padding-bottom: 56px;

            .print-button {
                display: none;
            }
        }

        .artwork-types {
            margin: 17px -14px 0;

            #filter-artwork-types-btn {
                margin-left: 14px;
            }
        }

        .filter.dropdown--box {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            position: relative;
        }

        .list-artwork-types {
            border-top: none;
            padding: 32px 0;

            .list {
                padding: 0 18px 16px;
            }

            li:not(:last-child) {
                margin-bottom: 18px;
            }

            .action {
                padding: 0 18px;
            }
        }

        .dropdown--text {
            padding-right: 40px;
            text-align: left;
            max-width: 75%;

            &:after {
                right: 10px;
            }
        }

        .result__list-view {
            padding-top: 45px;
            row-gap: 56px;

            .news-article__name h2:after {
                margin-left: 0;
                margin-top: 11px;
                display: block;
            }

            .news-article__name:hover h2:after {
                margin-left: 0;
                -webkit-transform: translateX(5px);
                -ms-transform: translateX(5px);
                transform: translateX(5px);
            }
        }

        .news-article__name h2 {
            &:after {
                margin-left: 0;
            }
        }

        .result__tile-view .item-list {
            width: calc(50% - 16px);
            .small-image {
                display: block;
                width: 124px;
                //height: 124px;
            }

            .description {
                display: none;
            }

            &.showing {
                margin-bottom: 55px;
            }
        }

        .result__list .result__show-more {
            //margin-top: 58px;
        }

        .result__tile-view {
            //-ms-grid-columns: 1fr 12px 1fr;
            //grid-template-columns: 1fr 1fr;
            //grid-gap: 58px 12px;
            column-gap: 32px;
            padding-top: 49px;

            .satellite {
                display: none;
            }

            .news-article__name h2 span {
                display: block;
                padding-right: 0;
            }

            .news-article__name h2 {
                font-size: 18px;
                line-height: 24px;
                font-weight: bold;

                &:after {
                    margin-top: 0;
                }
            }
        }

        .result__tile-view .item-list {
            .category__name {
                font-size: 14px;
                line-height: 18px;
                margin-top: 14px;
            }

            .news-article__name {
                margin-top: 6px;
            }

            .author__name {
                font-size: 14px;
                line-height: 18px;
                color: $color-note1;
                margin-top: 8px;
            }
        }

        .view--type__mobile {
            position: absolute;
            top: 15px;
            right: 4px;
        }

        .tile-view--type.active svg * {
            stroke: $black;
            fill: $black;
        }

        .box__filter {
            padding-top: 55px;
        }

        + .g-content__question-and-link {
            margin-top: 40px;

            .question-and-link-row {
                padding-bottom: 67px;
            }

            .question-and-link-row .g-content_question-item,
            .question-and-link-row .g-content_question-item h3 {
                padding: 0;
            }

            .question-and-link-row .g-content_question-item a .arrows-long:after {
                left: 0;
            }
        }
    }

    .search-page .result__list-view{
        row-gap: 0;
        .item-list.showing {
            margin-bottom: 56px;
        }
    }

    .search-page .result__tile-view .item-list {
        .small-image {
            position: relative;
            height: auto;
            padding-bottom: 90%;
            img {
                max-height: 100%;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .link-news-article {
            position: absolute;
        }
    }

    .search-page .result__tile-view .item-list:not(.is-news-article) {
        .small-image {
            padding: 45%;
        }
    }

}

@media all and (max-width: 375px) {
    .search-page {
        + .g-content__question-and-link .question-and-link-row .g-content_question-item {
            padding: 0;
        }
    }
}

@media all and (max-width: 350px) {
    .search-page {
        .result__tile-view {
            .item-list .small-image {
                width: 100px;
                height: 100px;
            }
        }
    }
}

@media print {
    header,
    footer,
    main .search-page ~ * {
        display: none !important;
    }
    .search-page {
        .page-main-title,
        .section-1,
        .box__filter,
        .dropdown--box,
        .result__show-more {
            display: none !important;
        }

        margin: auto;
    }
}

.lds-dual-ring.hidden {
    display: none;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 5% auto;
    border-radius: 50%;
    border: 6px solid #E74C39;
    border-color: #E74C39 transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
    animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes lds-dual-ring {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    z-index: 999;
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.load-search {
    width: 100%;
    height: 100vh;
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    top: 0;
    background: rgba(0,0,0,.5);
    z-index: 199;
}

.circle__item {
    width: 100px;
    height: 100px;
}

.circle__spinner {
    width: 125px;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.circle__spinner .path {
    stroke: #E74C39;
    stroke-linecap: round;
    -webkit-animation: dash 1s linear infinite;
    animation: dash 1s linear infinite;
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dasharray: 130, 150;
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dasharray: 130, 150;
        stroke-dashoffset: 0;
    }
}

@media print {
    .search-page .result__tile-view {
        margin: 0;
        padding: 0;
        column-gap: 40px;
        .item-list {
            width: calc(100%/3 - 30px);
            max-width: unset;
            .news-article__name{
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-top: 3px;
            }
            .author__name {
                margin-top: 3px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            // .small-image {
            //     position: relative;
            //     padding-bottom: 400px;
            //     max-width: 175px;
            //     height: unset;
            //     width: unset;
            // }

            .link-news-article {
                height: 100%;
                width: 100%;
                max-width: 100%;
            }
            .description {
                display: block;
                margin-top: 3px;
            }
        }
    }
    .search-page .search__result .author__name .ql-editor,
    .search-page .search__result .satellite
    .ql-editor, .search-page .search__result .description .ql-editor {
        -webkit-line-clamp: 1;
    }
    .search-page .result__tile-view .item-list.showing {
        margin-bottom: 15px;
        height: 30%;
    }
    .search-page .result__tile-view{
        page-break-after: auto;
        page-break-inside: avoid;
        .item-list.showing{
            page-break-after: auto;
            &:nth-child(9n + 7),
            &:nth-child(9n + 8),
            &:nth-child(9n + 9) {
                page-break-after: always;
            }
        }
    }
    .search-page {
        .result__list-view {
            page-break-after: auto;
            page-break-inside: avoid;
            display: block;
            padding: 0;
            .item-list.showing {
                margin-bottom: 5px;
                page-break-after: auto;
                page-break-inside: avoid;
            }
            .description {
                margin-top: 10px;
                height: 44px;
            }
            .news-article__name h2 {
                display: flex;
                align-items: center;
            }
        }
    }
    .search-page .result__tile-view .item-list .small-image {
        width: 50%;
        height: auto;
        padding-bottom: 158px;
    }
}
