sup, sub {
    font-weight: normal;
}

sup {
    vertical-align: super;
}

sub {
    vertical-align: sub;
}

.ql-editor {
    padding: 0;
}

.show-inline-block {
    display: inline-block !important;
}

.faqs {
    margin-bottom: 160px;

    ::-webkit-scrollbar {
        width: 4px;
        margin-top: 15px;
    }

    ::-webkit-scrollbar-track {
        background-color: $bg-scroll;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $light-black;
        border-radius: 4px;
    }

    .change-stroke {
        stroke: #e74c39;
    }

    .ql-editor {
        br {
            display: block;
            height: 24px;
            content: "";
        }
    }

    .desc-page {
        font-size: 18px;
        line-height: 24px;
    }

    .form-check .checkbox-label {
        display: flex;
        gap: 10px;
    }

    .form-check .checkbox-label:before {
        position: relative;
        top: 2px;
        min-width: 18px;
    }

    .button-filter-outer {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        margin-top: 40px;
    }

    .button-filter-outer .btn {
        min-width: 144px;
        text-transform: uppercase;
    }

    .questions-list-search-result {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }

    .list-filter {
        position: absolute;
        z-index: 1;
        border: 1px solid $black;
        padding: 12px 7px;
        background-color: $white;
        width: 100%;
        font-size: 18px;
        line-height: 24px;

        .last {
            border-top: 1px solid $black;
            padding-top: 12px;
            margin-top: 12px;
        }

        .questions-list-answer {
            display: flex;
            flex-direction: column;
            gap: 12px;
            max-height: 250px;
            overflow-y: auto;
        }

        .questions-list-answer a {
            background: initial;
            font-size: 18px;
            line-height: 24px;
        }

        .gray {
            background-color: $color-border-load;
        }

        .questions-list-answer a:hover {
            color: $green;
        }
    }

    .search-section {
        padding: 50px 0;

        h2 {
            font-size: 42px;
            line-height: 51px;
            letter-spacing: 0.2px;
            color: $green;
            margin-bottom: 20px;
        }

        .search-block,
        .filter-topics {
            margin-top: 40px;
        }

        .filter-buttons {
            display: flex;
            gap: 20px 15px;
        }

        .filter-buttons .clear-search-filter {
            text-transform: uppercase;
            min-width: 160px;
        }

        .filter-topics i {
            font-weight: 700;
            font-size: 10px;
        }

        .filter-topics .plus,
        .filter-topics.open .minus {
            display: block;
        }

        .filter-topics.open .plus,
        .filter-topics .minus {
            display: none;
        }

        .filter-topics-btn {
            border-color: $light-green;
            color: $light-green;
            width: 100%;
            max-width: 171px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }

        .btn-secondary.active-filter,
        .filter-topics-btn:hover,
        .filter-topics.open .filter-topics-btn {
            background-color: $light-green;
            color: $white;
        }

        .filter-topics .list-groups {
            background-color: $color-border-load;
            padding: 50px 45px;
            margin-top: 20px;
            height: auto;
        }

        .filter-topics .list-membership-categories {
            display: grid;
            grid-template-columns: 232px 1fr;
            gap: 30px;
        }

        .list {
            display: grid;
            grid-template-columns: initial;
            gap: 18px 30px;
        }

        .list-categories .list {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .list-topics {
            margin-top: 30px;
        }

        .list-topics .list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        .filter-topics .list-groups .title {
            font-size: 18px;
            color: $color-border-radio;
            border-bottom: 0.5px solid $black;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }

        .button-search-outer {
            display: flex;
            gap: 20px 15px;
        }

        .form-search-inner {
            display: flex;
            flex-wrap: wrap;
            min-height: 42px;
            align-items: flex-start;
            gap: 20px 15px;

            .input-search-faq-outer {
                width: 100%;
                max-width: 676px;
                margin-top: 10px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    background-image: url("../../public/images/icons/icon-search.svg");
                    z-index: -1;
                    transition: 0.2s;
                }

                input.input-text-search {
                    width: 100%;
                    padding-right: 63px;
                    padding-top: 0;
                    height: auto;
                }
            }

            button.search-submit {
                min-width: 133px;
                text-transform: uppercase;
            }

            .clear-search {
                min-width: 160px;
                text-transform: uppercase;
                display: none;
            }
        }
    }

    .owl-nav [class*=owl-]:hover {
        background: unset;
        color: unset;
    }

    .carosel-questions {
        background-color: $orange;
        padding: 57px 0 14px;

        .owl-carousel .owl-stage-outer {
            position: unset;
        }

        .carosel-questions-inner {
            .owl-nav {
                display: flex;
                justify-content: space-between;
                position: absolute;
                width: calc(100% + 160px);
                top: 80px;
                left: 50%;
                transform: translate(-50%, -50%);
                margin-top: 0;
                z-index: -1;

                button.owl-prev {
                    transition: 0.5s;
                    cursor: pointer;

                    span {
                        display: none;
                    }

                    &:hover {
                        transition-delay: unset;

                        svg {
                            transform: rotate(180deg) translateX(10px);
                            transition: 0.5s;
                        }
                    }
                }

                button.owl-next {
                    cursor: pointer;
                    transition: 0.5s;

                    span {
                        display: none;
                    }

                    &:hover {
                        transition-delay: unset;

                        svg {
                            transform: translateX(10px);
                            transition: 0.5s;
                        }
                    }
                }
            }

            .owl-dots {
                margin-top: 45px;
                display: none;
                justify-content: center;

                .owl-dot {
                    &.active {
                        span {
                            background: $color_orange_slider_dot_active;
                        }
                    }

                    span {
                        margin: 5px 5px;
                        background: $color_orange_slider_dot;
                    }
                }
            }
        }

        .carosel-questions-col {
            p {
                font-weight: 700;
                font-size: 18px;
                height: 45px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 12px;
            }

            a {
                font-weight: 400;
                font-size: 34px;
                line-height: 44px;
                background: transparent;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-decoration: none;
            }
        }
    }

    .tab__mobile {
        position: relative;
    }

    .questions-list {
        padding: 50px 0 20px;

        h3.questions-list-title {
            display: flex;
            align-items: center;
        }

        h2.questions-list-title,
        .result-title {
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 38px;
        }

        .no-result {
            margin-bottom: 30px;
        }

        .no-result-title {
            margin-bottom: 8px;
        }

        .no-result-desc {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            color: $color-text-light-black;
        }

        .tab-nav {
            overflow-x: auto;
        }

        .tab-outer {
            position: relative;
            display: flex;

            &.tab_desktop {
                overflow-x: auto;
            }

            .tab-title {
                text-transform: capitalize;
            }

            &.tab-detail {
                display: block;
            }

            .tab-link-outer {
                .arrow-title {
                    display: inline-block;
                    transition: 0.3s;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &:last-child {
                    a.tab-title {
                        &:first-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            a.tab-title {
                font-size: 34px;
                line-height: 44px;
                text-align: center;
                color: $color-gray-tab;
                background: transparent;
                padding-bottom: 5.5px;
                transition: 0s;
                border-bottom: 3.5px solid transparent;

                &.active {
                    color: #232222;

                    & + .tab-show {
                        display: block;
                    }
                }

            }
        }


        .questions-list-detail-outer {
            display: none;

            &.tab-show {
                display: block;
            }
        }

        .questions-list-detail {

            h3.questions-list-title {
                font-weight: 400;
                font-size: 34px;
                line-height: 44px;
                position: relative;
                cursor: pointer;
                display: flex;
                padding-left: 40px;

                &.arrow-down {
                    color: $orange;

                    &::before {
                        width: 16px;
                        height: 13px;
                        background-image: url("../../public/images/icons/arrow_down_faqs_orange.svg");
                    }
                }
            }

            .questions-list-item {
                &:last-of-type {
                    .questions-list-answer {
                        border-bottom: none;
                    }
                }
            }

            .arrow-down-show {
                display: block;
                padding-bottom: 80px;
            }

            h4.questions-list-question {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: $color-text-light-black;
                cursor: pointer;
                display: flex;
                gap: 30px;

                span {
                    display: inline-block;
                    padding-top: 2px;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    background: transparent url("../../public/images/icons/circle_plus.svg") center/30px 30px no-repeat;
                    transition: 0.2s
                }

                &.plus {

                    &::before {
                        background-image: url("../../public/images/icons/circle_minus.svg");
                    }
                }
            }

            .questions-list-answer {
                margin-top: 27px;
                padding-top: 21px;
                padding-bottom: 6px;
                border-top: 1px solid $black;
                border-bottom: 1px solid $black;
                display: none;
                margin-left: 62px;

                .softbreak:last-child {
                    display: none;
                }
            }

            .show-plus {
                display: block;
                margin-bottom: 23px;
            }
        }
    }

    .questions-list-item:not(:last-child) {
        margin-bottom: 30px;
    }

    .search-result {
        padding-top: 40px;
        padding-bottom: 67px;
        margin: 0 auto;
        margin-top: -35px;
        border-top: 1px solid $black;
        border-bottom: none;

        .search-section-result-title {
            margin-bottom: 11px;
            color: $color-focus-input;

            span {
                color: $black;
            }

        }

        .questions-list-answer {
            color: #0A0A0B;
        }

        .questions-list-detail-outer {
            display: block;
            height: auto;
            max-height: 425px;
            overflow-y: auto;
            padding-top: 39px;
            border-top: 1px solid $black;
            background-color: $color-border-load;
            position: unset;
            transition: 0.5S;
            scrollbar-width: auto;
            scrollbar-color: #8f54a0 #ffffff;


            /* Chrome, Edge, and Safari */
            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: rgba(147, 149, 152, .2);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #000000;
            }

        }

        .expand-height {
            height: auto;
            max-height: 1170px;
        }

        .questions-list-detail {
            padding-left: 0;

            h4.questions-list-question {
                margin-left: 91px;
                padding-bottom: 0;
                transition: 0.5s;
            }

            .questions-list-answer {
                margin-left: 91px;
                margin-right: 80px;
                margin-bottom: 44px;
                margin-top: 24px;
            }

            .questions-list-item {
                &:last-child {
                    h4.questions-list-question {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .questions-list-detail {
        padding: 22px 20px;

        &:nth-child(odd) {
            background-color: $bg-color-f1;
        }
    }

    .membership-type {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 30px;

        &.dealer {
            color: $blue;
        }

        &.artist {
            color: $orange;
        }

        &.supporter {
            color: $green;
        }
    }

    .most-frequently {
        .questions-list-detail {
            padding: 0;
            background-color: transparent;
        }

        .questions-list-items {
            padding: 0;
        }
    }

    .questions-list-items {
        padding: 0 44px;
        margin-top: 30px;
    }

    .search-result .questions-list-detail h4.questions-list-question {
        margin-right: 80px;
    }

    .carosel-questions .carosel-questions-col h6 {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .questions-list .tab-outer a.tab-title {
        font-weight: 400;
        margin-top: 0;
        margin-right: 60px;
        white-space: nowrap;
    }

    .content-bottom {
        margin-top: 21px;
        line-height: 21px;

        .content-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 50px;
            max-width: calc(100% - 62px);
        }

        .go-top {
            position: relative;
            top: 6px;
            color: $color-text5;
        }

        p:not(:last-child) {
            margin-bottom: 8px;
        }

        a:not(.go-top) {
            color: $orange;
            background: linear-gradient(90deg, $orange 33.3333333333%, transparent 0 66.6666666667%, $orange 0) var(--d, 0%) 90% / 300% 1px no-repeat;
        }
    }

}

.tab__mobile {
    display: none;
}


@media screen and (max-width: 1439.98px) {
    .faqs .questions-list {
        .tab-outer {
            &:last-child {
                a.tab-title:first-child {
                    margin-right: 0;
                }
            }
        }
    }

    .faqs .carosel-questions .carosel-questions-inner .owl-nav button.owl-next {
        right: -25px;
        background: unset;
    }

    .faqs .carosel-questions .carosel-questions-inner .owl-nav button.owl-prev {
        left: -32px;
        background: unset;
    }
}

@media screen and (max-width: 1279.98px) {
    .faqs .carosel-questions .carosel-questions-inner .owl-nav {
        display: none;
    }

    .faqs .questions-list {
        .tab-outer {

            &:last-child {
                a.tab-title:first-child {
                    margin-right: 0;
                }
            }

            .tab-link-outer {
                a.tab-title {
                    &:first-child {
                        font-size: 24px;
                        line-height: 38px;
                        transition: 0.5s;
                    }
                }

                &:not(:first-child) {
                    a.tab-title {
                        &:first-child {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    body .ql-editor {
        ol,
        ul {
            padding-left: 1em;
        }
    }
    .ql-editor {
        .ql-indent-1:not(.ql-direction-rtl),
        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-left: 1.5em;
        }

        .ql-indent-2:not(.ql-direction-rtl),
        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-left: 2em;
        }

        .ql-indent-3:not(.ql-direction-rtl),
        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-left: 2.5em;
        }

        .ql-indent-4:not(.ql-direction-rtl),
        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-left: 3em;
        }

        .ql-indent-5:not(.ql-direction-rtl),
        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-left: 3.5em;
        }

        .ql-indent-6:not(.ql-direction-rtl),
        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-left: 4em;
        }

        .ql-indent-7:not(.ql-direction-rtl),
        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-left: 4.5em;
        }

        .ql-indent-8:not(.ql-direction-rtl),
        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-left: 5em;
        }

        .ql-indent-9:not(.ql-direction-rtl),
        li.ql-indent-9:not(.ql-direction-rtl) {
            padding-left: 5.5em;
        }
    }

    .rotate-opener-icon {
        font-size: 25px;
    }
    .faqs .btn {
        padding: 12px;
    }
    .faqs .questions-list .tab__desktop {
        display: none;
    }
    .tab__mobile {
        display: flex;
        align-items: center;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
        }

        .full-text-span {
            overflow: unset;
            text-overflow: unset;
            white-space: pre-wrap;
        }
    }
    .faqs {
        margin-bottom: 80px;

        .desc-page {
            font-size: initial;
            line-height: 22px;
        }

        .line-end {
            display: none;
        }

        .list-filter {
            position: static;
            font-size: 14px;

            .questions-list-answer {
                gap: 8px;
            }

            .questions-list-answer a {
                font-size: 14px;
            }
        }

        .form-check .checkbox-label {
            font-size: 14px;
        }

        .search-result .search-section-result-title {
            padding-left: 17px;
        }

        .questions-list-search-result {
            gap: 88px;
        }

        .button-filter-outer,
        .filter-buttons {
            flex-direction: column;
            width: 100%;
        }

        .button-filter-outer .btn,
        .filter-buttons .btn {
            width: 100%;
        }

        .filter-buttons .btn {
            max-width: 100%;
        }

        .button-search-outer {
            flex-direction: column;
        }

        .search-section {
            padding: 28px 0 50px;

            h2 {
                font-size: 34px;
                line-height: 44px;
                margin-bottom: 20px;
            }

            .search-block {
                margin-top: 45px;
            }

            .filter-topics {
                margin-top: 55px;
            }

            .list-topics {
                margin-top: 26px;
            }

            .list-categories .list,
            .list-topics .list {
                grid-template-columns: initial;
                gap: 15px;
            }

            .filter-topics .list-groups {
                padding: 30px 20px;
            }

            .filter-topics > .btn-secondary {
                max-width: 100%;
            }

            .filter-topics .list-membership-categories {
                grid-template-columns: initial;
                gap: 30px;
            }

            .list {
                grid-template-columns: 1fr;
                row-gap: 15px;
            }

            .form-search-inner {
                .input-search-faq-outer {
                    width: 100%;
                    margin-right: 0;
                }

                .button-search-outer {
                    width: 100%;

                    button {
                        width: 100%;
                    }

                    .search-submit {
                        margin-right: 0;
                    }

                    .clear-search {
                        border: 1px solid #0A0A0B;
                    }
                }
            }

            .questions-list-items {
                padding: 0;
            }
        }

        .membership-type {
            font-size: 20px;
            line-height: 26px;
        }

        .carosel-questions {
            background-color: $orange;
            padding: 41px 0 45px;

            .carosel-questions-inner {
                .owl-nav {
                    margin-top: 7px;

                    button.owl-prev {
                        span {
                            display: none;
                        }
                    }

                    button.owl-next {
                        span {
                            display: none;
                        }
                    }
                }

                .owl-theme .owl-nav.disabled + .owl-dots {
                    margin-top: 13px;
                }

                .owl-dots {
                    margin-top: 13px;

                    .owl-dot {
                        &.active {
                            span {
                                background: $color_orange_slider_dot_active;
                            }
                        }

                        span {
                            margin: 5px 5px;
                            background: $color_orange_slider_dot;
                        }
                    }
                }
            }

            .carosel-questions-col {
                p {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                }

                h6 {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 38px;
                    text-align: center;
                    display: -webkit-box;
                }

                a {
                    margin: auto;
                }
            }
        }

        .questions-list {
            padding-top: 30px;
            padding-bottom: 28px;

            .container {
                padding: 0 15px;
                width: 100%;
            }

            .container.most-frequently {
                padding: 0 32px;
            }

            h2.questions-list-title,
            .result-title {
                font-size: 28px;
                padding: 0 18px;
                margin-bottom: 30px;
            }

            .no-result {
                padding: 0 17px;
                margin-bottom: 7px;
            }

            .no-result-title {
                font-size: 28px;
                margin-bottom: 11px;
            }

            .no-result-desc {
                font-size: 16px;
            }

            .most-frequently h2.questions-list-title {
                padding: 0;
            }

            .tab-outer {
                flex-wrap: wrap;
                flex-direction: column;

                .tab-link-outer {
                    a.tab-title {
                        &:first-child {
                            font-size: 24px;
                            line-height: 38px;
                        }
                    }

                    &:not(:first-child) {
                        a.tab-title {
                            &:first-child {
                                margin-right: 30px;
                            }
                        }
                    }

                    .arrow-title {
                        position: unset;
                    }
                }

                a.tab-title {
                    width: 100%;
                    border-width: 1px;
                    font-weight: 400;
                    text-align: left;
                    color: $color-border-radio;
                    padding-bottom: 0;
                    margin-right: 3px;

                    span {
                        position: relative;
                        margin-right: 20px;
                    }

                    &.active {
                        border-width: 1px;
                        color: black;
                        border-bottom: 1px solid $black;

                        span {
                            position: relative;
                            color: #0A0A0B;
                        }
                    }

                    &:first-child {
                        margin-right: 130px;
                    }
                }

                .questions-list-detail-outer {
                    display: none;
                    position: unset;
                }

                .tab-show {
                    display: block;
                }
            }

            a.custom-link.go-top {
                margin-left: 0;
            }

            .questions-list-detail-outer-under {
                display: none;
            }

            .arrow-down-show {
                padding-bottom: 41px;
            }

            .questions-list-detail {

                .show-plus {
                    border-bottom: none;
                    margin-bottom: 0px;
                }

                h3.questions-list-title {
                    font-size: 24px;
                    line-height: 30px;
                    display: flex;
                    padding-left: 22px;

                    .arrow-title {
                        margin-left: 0;
                        position: absolute;
                        top: 1px;
                        left: 0;
                    }

                    .rotate-opener-icon {
                        left: -4px;
                    }

                    &.arrow-down {
                        color: $color-border-radio;

                        &::before {
                            top: 5px;
                            left: -29px;
                            width: 18px;
                            height: 11px;
                            transform: rotate(0);
                            background-image: url("../../public/images/icons/arrow_down.svg");
                            @include css-prefix(
                                            (
                                                    transition: 0.2s,
                                            )
                            );
                        }
                    }
                }

                .arrow-down-show {
                    display: block;
                    padding-bottom: 41px;
                }

                h4.questions-list-question {
                    margin-left: 0;
                    font-size: 16px;
                    gap: 24px 15px;

                    &::before {
                        background-size: 24px 24px;
                        width: 24px;
                        height: 24px;
                        min-width: 24px;
                    }

                    &.plus {
                        &::before {
                            background-image: url("../../public/images/icons/circle_minus.svg");
                        }
                    }
                }

                .questions-list-item:last-of-type {
                    h4.questions-list-question {
                        border-bottom: none;
                    }

                    .questions-list-answer {
                        padding-bottom: 0;
                    }
                }

                .questions-list-answer {
                    margin: 28px 0 0 39px;
                }

                .show-plus {
                    display: block;
                }
            }
        }

        .search-result {
            border: none;
            padding-bottom: 48px;

            .questions-list-detail-outer {
                padding-top: 0;
            }
        }

        .search-result .questions-list-detail {
            .questions-list-answer {
                margin-right: 0;
            }

            h4.questions-list-question {
                margin-right: 0;
            }

            .questions-list-detail {
                padding-left: 0;
            }
        }

        h3.questions-list-title {
            font-size: 24px;
            line-height: 30px;
        }

        .questions-list-detail {
            padding-left: 15px;
            padding-right: 15px;
        }

        .questions-list-items {
            padding: 0;
        }

        .content-bottom {
            margin-top: 17px;

            .content-wrapper {
                flex-direction: column;
                max-width: 100%;
                gap: 45px;
            }

            .go-top {
                position: static;
            }
        }
    }
}
