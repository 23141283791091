//General

.membership-application-form-body {
    .g-header {
        z-index: 99;
    }

    .blocker {
        z-index: 99;

        &:before {
            content: "";
            background: $color-focus-input;
            opacity: .4;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0;
        }
    }

}

.color-333 {
    color: $light-black;
}

.size-16 {
    font-size: 16px;
    line-height: 22px;
}

.mt-30 {
    margin-top: 30px;
}

.jconfirm {
    &.jconfirm-light .jconfirm-bg {
        background-color: $color-focus-input;
        opacity: .4;
    }

    &.jconfirm-white .jconfirm-box,
    &.jconfirm-light .jconfirm-box {
        box-shadow: none;
        width: 485px;
        min-height: 193px;
        border: 1px solid $black;
        border-radius: 0;
        padding: 38px 20px;
        text-align: center;
    }

    .jconfirm-box {
        .jconfirm-buttons button.btn {
            border-radius: 3px;
        }

        .jconfirm-buttons {
            padding-bottom: 0;
        }

        .jconfirm-buttons > button {
            margin: 0 12px;
            min-width: 190.5px;
            line-height: 22px;
            min-height: 40px;
            text-align: center;
        }

        div.jconfirm-content-pane {
            padding: 0 30px;
            margin-bottom: 25px;
        }
    }

    &.jconfirm-light .jconfirm-box .jconfirm-buttons button.btn-default {
        background: $white;
        color: $black;
        border: 1px solid $black;

        &:hover {
            background: $black;
            color: $white;
        }
    }

    .jconfirm-content {
        font-size: 18px;
        line-height: 24px;
    }

    .jconfirm-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.membership-application-form {
    background-color: $color-border-load;
    font-size: 18px;
    line-height: 24px;

    .size-16 {
        a {
            font-size: 16px;
            line-height: 22px;
        }
    }

    input:-webkit-autofill {
        @include css-prefix((box-shadow: 0 0 0 50px $color-border-load inset));
        -webkit-text-fill-color: $black;
    }

    .disabled {
        display: block;
    }

    .page-title-wrapper {
        color: $orange;
        letter-spacing: 0.2px;
        border: none;
        padding-bottom: 0;
    }

    a {
        font-size: 18px;
        line-height: 24px;
    }

    a.remove_field {
        font-size: 0;
        background: none;
        position: absolute;
        line-height: 1;
        top: 0;
        right: 0;

        &:after {
            content: "";
            width: 19px;
            height: 22px;
            display: block;
            background: url("../../public/images/icons/delete-icon.svg") center/100% no-repeat;
        }
    }

    .custom-select,
    .custom-select-state {
        .selected-item {
            height: 36px;

            &:before {
                background: $color-border-load;
            }

            &.parsley-error {
                color: $color-focus-input;
            }
        }
    }

    .parsley-errors-list {
        display: none !important;
    }

    .link-action {
        .contact-us.icon-question {
            margin-left: 0;
        }

        .contact-us > a {
            margin-bottom: 0;
        }
    }

    .link-action a,
    .note.icon-question,
    .note.icon-question a {
        font-size: 16px;
        line-height: 22px;
    }

    .link-action a {
        display: block;
    }

    .action {
        display: inline-block;
        width: 100%;
    }

    .paginate {
        font-size: 18px;
        line-height: 24px;
    }

    .form-box {
        margin-bottom: 40px;
    }

    .label-wrapper {
        font-size: 14px;
        line-height: 22px;
        font-weight: bold;
        margin-top: 24px;

        label {
            text-transform: uppercase;
        }
    }

    .form-check {
        &.form-group .form-control {
            padding: 0;
        }

        .form-control {
            font-size: $font-size-base;
            line-height: $line-height-base;
            border-color: $color-focus-input;
            position: relative;
            top: -9px;
        }

        .checkbox-label {
            font-size: $font-size-base;
            line-height: $line-height-base;
            color: $color-focus-input;

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
            }
        }

        .checkbox-label {
            padding-left: 31px;
            display: flex;
        }

        .radio-label {
            min-width: 64px;

            &:hover {
                background-color: #000;
                color: #fff;
            }
        }

        .radio-label.circle {
            min-width: 0;
        }
    }

    .form-content {
        display: flex;

        .form-check {
            margin: 15px 30px 0 0;
        }
    }

    .form-content.three-buttons-inline {
        flex-wrap: nowrap;

        .form-check:last-child {
            margin-right: 0;
        }
    }

    .parsley-error .form-check-label:before,
    .parsley-error .radio-label-circle:after,
    .form-control.parsley-error,
    .list-special-radio .parsley-error ~ .form-check .radio-label-circle:after {
        border-color: $orange;
    }

    .form-check.big-size {
        margin-top: 12px;
        display: inline-block;

        &.parsley-error + .parsley-errors-list {
            margin-top: 15px;
        }

        .checkbox-label:before {
            width: 25px;
            height: 25px;
            box-sizing: border-box;
        }

        .form-check-input[type=checkbox]:checked + .checkbox-label:after {
            top: 3px;
            left: 9px;
            width: 7px;
            height: 15px;
        }

        .checkbox-label {
            color: $black;
            padding-left: 43px;
            display: block;
            font-size: 18px;
            line-height: 24px;

            &:first-of-type {
                margin-bottom: 12px;
            }
        }

        &:last-child .checkbox-label:first-of-type {
            margin-bottom: 0;
        }
    }

    .dealer .membership-application-form .form-check.big-size .checkbox-label:first-of-type {
        margin-bottom: 18px;
    }

    .button-action .btn {
        width: 100%;
    }

    .arrows:before {
        display: none;
    }

    .btn {
        text-transform: uppercase;
        padding: 6px 28px;
        font-size: 14px;
        line-height: 22px;

        &.arrows:after {
            width: 28px;
            height: 19px;
            top: 50%;
            margin-left: 0;
        }
    }

    .btn,
    .btn.btn-secondary {
        border-color: $color-border-radio;
    }

    .btn-secondary {
        background: transparent;

        &.arrows:after {
            left: 3px;
            @include css-prefix((transform: translateY(-50%) rotate(180deg)));
        }

        &.arrows:hover:after {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
        }
    }

    .btn-primary.arrows:after {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
        @include css-prefix((transform: translateY(-50%)));
        right: 3px;
    }

    .label-group {
        color: $black;
        display: inline-block;
        margin-bottom: 11px;
    }

    textarea {
        resize: none;
        width: 100%;
    }

    .form-control {
        display: flex;
    }

    .list {
        .form-control,
        .selected-item {
            margin-top: 15px;
        }

        label + .form-control,
        label + .custom-select .selected-item {
            margin-top: 11px;
        }

        label + div .form-control {
            margin-top: 0;
        }
    }

    list .selected-item {
        margin-top: 15px;
    }

    .custom-select,
    .custom-select-state {
        .all-items {
            max-height: 220px;
            overflow-y: auto;
        }
    }

    .form-group {
        &.items .form-label {
            padding-right: 25px;
        }

        &.items .item {
            position: relative;
            margin-top: 24px;
            display: inline-block;
            width: 100%;
        }

        .form-label {
            color: $black;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 11px;
        }

        .form-control {
            padding: 3px 0 16px;
            width: 100%;
            color: $black;

            &::placeholder {
                color: $color-focus-input;
            }
        }

        textarea.form-control {
            padding: 7px 12px;
            height: 120px;
            display: flex;
            max-width: 100%;
        }

        textarea.parsley-error:focus {
            border-width: 1px;
        }
    }

    .custom-select-special .selected-item {
        color: $black;
        font-size: 14px;
        line-height: 18px;
        border: 1px solid;
        display: flex;
        align-items: center;
        padding: 10px 35px 10px 13px;
        min-height: 40px;

        &:after {
            right: 10px;
        }

        &:before {
            width: 38px;
            top: 1px;
            right: 1px;
        }

        &.parsley-error {
            border-color: $orange;
        }

        &.parsley-success {
            border-color: $black;
        }
    }

    .custom-select-special .item {
        font-size: 14px;
        line-height: 18px;
        padding-left: 13px;
    }

    .custom-select,
    .custom-select-state {
        .selected-item {
            padding: 3px 28px 5px 0;
            color: $black;
            display: flex;
            align-items: flex-end;
        }
    }

    .custom-select,
    .custom-select-state {
        .selected-item.arrowanim {
            padding-left: 7px;
        }
    }

    .custom-select-special .selected-item.arrowanim {
        padding-left: 13px;
    }

    .upload-artwork-wrapper .icon-question:before {
        top: -2px;
    }

    .icon-question {
        position: relative;
        padding-left: 24px;
        margin-left: 3px;

        &:before {
            content: "";
            background: url("../../public/images/question-icon.png") center/100% 100% no-repeat;
            width: 25px;
            height: 26px;
            display: block;
            position: absolute;
            top: 0;
            left: -3px;
        }
    }

    .message-warning,
    .parsley-required,
    .validate-message-form {
        color: $orange;
        font-size: 14px;
        line-height: 18px;
    }

    .parsley-required {
        margin-top: 15px;
    }

    .validate-message-form {
        margin-top: 27px;
    }

    .add-another {
        margin-top: 16px;
        font-size: 14px;
        line-height: 18px;
    }

    .btn.add {
        width: 30px;
        min-height: 30px;
        padding: 0;
        @include css-prefix((border-radius: 50%));
        margin-right: 8px;
    }

    .label-add {
        font-size: 14px;
        line-height: 18px;
    }

    .note {
        font-size: 14px;
        line-height: 18px;
        display: inline-block;
    }

    .note-page {
        padding: 22px 24px;
        border: 1px solid $black;
    }

    .note-special {
        padding: 27px 30px;
        background: #e74c39;
        color: #fff;
        border: none;

        i {
            margin-right: 5px;
            font-size: 31px;
        }

        .important {
            display: flex;
            align-items: center;
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 18px;
        }
    }

    .aai-cn {
        .form-content {
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .postal-address {
        .form-check {
            margin-top: 11px;

            .checkbox-label {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .form-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .hour-items {
        display: flex;
        justify-content: space-between;
    }

    .hour-item {
        flex-basis: calc(50% - 12.5px);
    }

    .form-group .hour-item .form-label {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 9px;
    }

    .operation-hours {
        display: inline-block;

        .all-items {
            max-height: 123px;
            overflow-y: auto;
        }

        .list-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .list-wrapper .form-check {
            margin: 10px 19px 0 0;
        }

        > .form-group {
            margin-bottom: 14px;
        }

        .remove_field {
            top: 40px;
            left: 465px;
            right: auto;
        }

        .form-check .checkbox-label {
            color: $black;
            padding-left: 26px;
        }

    }

    .operation-hours-box {
        .add-operation {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .list-director,
    .list-related {
        position: relative;
        margin-top: 17px;

        .director-role .form-label,
        .related-aai-cn .form-label {
            padding-right: 25px;
        }
    }

    .mb-0 {
        margin-bottom: 0;
    }

    .form-box-valid {
        .title {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 12px;
        }

        .desc {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 12px;
        }

        .parsley-errors-list,
        + .parsley-errors-list {
            display: block !important;
        }

        .parsley-required,
        .form-check.big-size.parsley-error + .parsley-errors-list {
            margin-top: 0;
            margin-bottom: 8px;
        }
    }

    .action .width-auto {
        width: 100%;

        .arrows-short {
            min-width: auto;
            justify-content: center;
            padding-right: 40px;

            &:last-child {
                padding-left: 28px;
            }

            &.arrows:before {
                display: none;
            }
        }
    }
}

// Form 1

.membership-form-start {
    .description,
    .description a {
        font-size: 16px;
        line-height: 22px;
    }

    .list .form-control {
        margin-top: 27px;
    }
}

//Form 2
.membership-form-step2 {
    .form-content {
        position: relative;

        .parsley-errors-list {
            position: absolute;
            left: 0;
            bottom: -22px;
        }
    }

    .form-wrapper {
        margin-top: 40px;
    }

    .note {
        margin: 3px 0 10px;

        strong {
            @media screen and (max-width: 768px) {
                font-weight: 400;
            }
        }
    }
}

// Form 3
.membership-form-step3 {

    .label-group {
        display: inline-block;
    }

    .contact-method {
        margin-bottom: 0;

        .selected-item {
            padding-top: 0;
        }
    }

    .user-contact-email {
        color: rgba(0, 0, 0, 0.3) !important;
        cursor: not-allowed;
    }
}

// Form 4
.membership-form-step4 {
    .profile-image {
        figure {
            width: 170px;
            height: 170px;
            border-radius: 50%;
            border: 1px solid $black;
            overflow: hidden;
            position: relative;
        }

        img {
            object-fit: contain;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .cabinet {
            @include display-flex-cs();
            @include css-prefix((align-items: center));
        }

        &.parsley-error .upload-img {
            color: $orange;
            background: linear-gradient(90deg, $orange calc(100% / 3), transparent 0 calc(200% / 3), $orange 0) var(--d, 0%) 90% /300% 1px no-repeat;
        }

        &.parsley-error figure {
            border-color: $orange;
        }
    }

    .about-yourself {
        .note {
            margin-bottom: 0;
        }
    }

    .upload-image-wrapper {
        padding-left: 41px;
        flex: 2;
        position: relative;

        .upload-label {
            font-size: 18px;
            line-height: 24px;
        }

        .form-label {
            display: block;
        }

        .upload-img {
            font-size: $font-size-base;
            line-height: $line-height-base;
            --d: 100%;
            cursor: pointer;
            text-decoration: none;
            color: $color-text2;
            background: linear-gradient(90deg, $color-text2 calc(100% / 3), transparent 0 calc(200% / 3), $color-text2 0) var(--d, 0%) 90% /300% 1px no-repeat;
            width: fit-content;

            &:hover {
                transition: 0.5s;
                --d: 0;
            }
        }

        input.file {
            position: absolute;
            left: -500000px;
            color: $color-text2;

            &:focus {
                + .parsley-errors-list + input + .upload-img {
                    outline: 2px solid $orange;
                    border-radius: 5px;
                }

                + input + .upload-img {
                    outline: 2px solid $black;
                    border-radius: 5px;
                }
            }
        }

        .read-more,
        .read-more a {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .languages {
        button {
            margin-right: 8px;
        }
    }

    .language-group {
        position: relative;

        &:first-child .form-label {
            margin-top: 0;
        }

        .form-label {
            padding-right: 25px;
            margin-top: 15px;
        }
    }

    .list-all-artwork {
        .form-group {
            margin-top: 16px;
        }
    }

    .social-links {
        .form-group {
            margin-top: 24px;
        }
    }
}

// Form 5

.membership-form-step5 {
    .form-check {
        margin-top: 41px;
    }
}

.upload-artwork-wrapper {
    + .form-check.big-size {
        margin-top: 54px;
    }

    .upload-box {
        .note {
            margin-top: 16px;
        }
    }

    .artwork-item {
        margin-bottom: 40px;

        .media-body {
            @include display-flex-cs();
            @include css-prefix((flex-wrap: wrap));
            position: relative;
        }

        .file-name {
            font-size: $font-size-base;
            line-height: $line-height-base;
        }

        .status {
            font-size: 14px;
            line-height: 18px;
            color: $color-focus-input;
        }

        .description {
            margin-top: 7px;
        }

        img {
            width: 100%;
            object-fit: contain;
        }

        .image {
            flex-basis: 79px;
            padding-right: 18px;
        }

        .info {
            padding-right: 25px;
            flex-basis: calc(100% - 79px);
            word-break: break-word;
        }

        .progress {
            flex: 100%;
            height: 4px;
            background: $color-text3;
            margin-top: 8px;
        }

        .progress-bar {
            background: $light-green;
            height: 100%;
            font-size: 0;
        }

        .remove_field {
            &.remove-progress {
                &:after {
                    width: 16px;
                    height: 15px;
                    background: url("../../public/images/icons/close-icon-black.svg") center/100% no-repeat;
                }
            }
        }
    }

    &.file {
        .image {
            flex-basis: 47px;
            padding-right: 18px;
        }

        .info {
            flex-basis: calc(100% - 47px);
        }

        .artwork-item {
            margin-bottom: 0;
        }
    }
}

.file {
    .dm-uploader-box {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), $color-border-load url("../../public/images/icons/file-bg.svg") 48.5% 40%/auto no-repeat;
    }
}

.dm-uploader-box {
    border: 1px dashed $black;
    text-align: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), $color-border-load url("../../public/images/icons/icon-image-upload.png") 48.5% 40%/auto no-repeat;
    min-height: 210px;
    @include display-flex-cs();
    @include css-prefix((flex-direction: column, justify-content: flex-end));
    padding: 8px;

    &.parsley-error {
        border-color: $orange;
    }

    .btn {
        opacity: 0;
        position: absolute;
        left: -99999999px;
    }

    .text-muted {
        position: relative;
        bottom: 23px;

        a {
            color: $light-blue;
            background: linear-gradient(90deg, $light-blue calc(100% / 3), transparent 0 calc(200% / 3), $light-blue 0) var(--d, 0%) 90% /300% 1px no-repeat;
        }
    }
}

.dm-uploader.active {
    border-color: red;
    border-style: solid;
}

.dm-uploader {
    cursor: default;
    @include css-prefix((user-select: none, touch-callout: none));
}

.dm-uploader .btn input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: solid transparent;
    width: 100%;
    opacity: .0;
    filter: alpha(opacity=0);
    cursor: pointer;
}

// Crop image
#cropImagePop {
    background-color: $white;
    padding: 40px;
    border: 1px solid $black;
    box-shadow: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: $black;
        text-align: left;
    }

    a.close-modal {
        background-image: url("../../public/images/icons/close-icon-black.svg");
    }

    .modal-body {
        background: $color-border-load;
        margin: 24px 0 18px;
    }

    .rotate {
        font-size: 0;
        background: none;
        border: none;
        padding: 0;
        position: relative;
        top: 10px;

        &::before {
            content: "";
            background: url("../../public/images/icons/rotate-icon.svg") center/100% no-repeat;
            width: 22px;
            height: 25px;
            display: block;
        }
    }

    .modal-content {
        position: relative;
    }

    .modal-footer {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .save {
        text-transform: uppercase;
        margin-left: 24px;
    }
}

#cropSatellitesImagePop {
    background-color: $white;
    padding: 40px;
    border: 1px solid $black;
    box-shadow: none;
    top: 50%;
    transform: translateY(-50%);

    h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: $black;
        text-align: left;
    }

    a.close-modal {
        background-image: url("../../public/images/icons/close-icon-black.svg");
    }

    .modal-body {
        background: $color-border-load;
        margin: 24px 0 18px;
    }

    .rotate {
        font-size: 0;
        background: none;
        border: none;
        padding: 0;
        position: relative;
        top: 10px;

        &::before {
            content: "";
            background: url("../../public/images/icons/rotate-icon.svg") center/100% no-repeat;
            width: 22px;
            height: 25px;
            display: block;
        }
    }

    .modal-content {
        position: relative;
    }

    .modal-footer {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .save {
        text-transform: uppercase;
        margin-left: 24px;
    }
}

.cr-slider::-webkit-slider-runnable-track {
    height: 2px;
    border-radius: 0;
    background: $color-text4;
}

.cr-slider::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    background: $black;
}

.croppie-container {
    .cr-slider-wrap {
        width: 100%;
        text-align: left;
    }

    .cr-resizer,
    .cr-viewport {
        border: 1px solid $black;
        box-shadow: 0 0 2000px 2000px rgba(51, 51, 51, 0.5);
    }

    .cr-slider-wrap {
        margin: 15px 0;
    }
}

// Form 6
.membership-form-step6 {
    .form-wrapper {
        display: inline-block;
    }

    .form-box {
        > .label-group {
            display: inline-block;
        }
    }

    .learn-artist-membership {
        margin-top: 0;
    }

    .form-group.form-check {
        margin-top: 16px;
    }
}

// form 7
.membership-form-step7 {
    .form-description {
        ul {
            margin-top: 26px;
            list-style: decimal;
            padding-left: 26px;
        }

        li {
            margin-bottom: 25px;
        }
    }

    .form-check {
        margin-top: 40px;

        &.parsley-error + .parsley-errors-list {
            margin-top: 25px;
        }
    }
}


// Desktop

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    // Form 5
    .upload-artwork-wrapper .artwork-item .form-group {
        flex-basis: calc(50% - 15px);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    // Form 5
    .upload-artwork-wrapper .artwork-item .form-group {
        flex-basis: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .membership-application-form {
        .message-warning {
            padding: 19px 115px 0 0;
        }
    }
    // Form 5
    .upload-artwork-wrapper .artwork-item .form-group {

        &.title {
            flex-basis: 665px;
            padding-right: 30px;
        }

        &.year {
            flex-basis: 253px;
        }

        &.photo,
        &.copy-right {
            flex-basis: calc(50% - 15px);
        }
    }
}

@media only screen and (min-width: 768px) {

    //General
    .membership-application-form {
        padding: 17px 95px 56px;
        margin: 21px 0 68px;

        .paginate {
            position: relative;
            top: -2px;
        }

        .label-wrapper {
            padding: 0 0 9px;
            border-bottom: 1px solid $black;
            @include display-flex-cs();
            @include css-prefix((justify-content: space-between));
        }

        .link-action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            width: auto;
        }

        .action .width-auto .max-w-180 {
            max-width: 180px;
        }

        .button-action .btn {
            max-width: 160px;
        }

        .submit-form,
        .action {
            margin-top: 56px;

        }

        .submit-form {
            display: inline-block;
            width: 100%;
        }

        .button-action {
            @include display-flex-cs();

            .btn-secondary {
                margin-right: 30px;
            }
        }

        textarea {
            margin-top: 17px;
        }

        .message-warning {
            padding-top: 15px;
            margin-left: -4px;
        }

        .upload-artwork-wrapper {
            .form-control,
            .custom-select {
                max-width: 100%;
            }
        }

        .form-control,
        .custom-select,
        .custom-select-state,
        .aai-cn .form-content,
        input + .parsley-required {
            max-width: 445px;
        }

        .full-size {
            .form-control,
            .custom-select,
            .custom-select-state {
                max-width: 100%;
            }
        }

        .form-content {
            display: flex;
        }

        .form-group {
            &.items .item {
                max-width: 445px;
            }
        }

        .note-page {
            margin-top: 56px;

            p {
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .aai-cn {
            .custom-select {
                flex-basis: 159px;
            }

            .form-control {
                flex-basis: calc(100% - 189px);
                padding: 3px 20px 7px 0;
            }
        }

        .hour-items {
            max-width: 445px;
        }

        .list-director,
        .list-related {
            display: flex;
            justify-content: space-between;

            > div {
                flex-basis: calc(50% - 15px);
            }
        }

        .list-special {
            margin-left: -15px;
            margin-right: -15px;

            > label,
            .form-group {
                padding-left: 15px;
            }
        }
    }

    // Crop image
    #cropImagePop {
        width: 485px;
        height: 512px;

        .save {
            min-width: 93px;
        }

        a.close-modal {
            top: 44px;
            right: 38px;
        }
    }
    #cropSatellitesImagePop {
        width: 485px;
        height: 512px;

        .save {
            min-width: 93px;
        }

        a.close-modal {
            top: 44px;
            right: 38px;
        }
    }
    .croppie-container {
        height: 320px;

        .cr-slider-wrap {
            margin-top: 33px;
        }

        .cr-slider {
            max-width: 247px;
        }
    }

    // Form 1
    .membership-form-start {
        .form-check.big-size .checkbox-label {
            display: inline-block;
        }

        .form-check.big-size.parsley-error + .parsley-errors-list {
            margin-top: 24px;
        }

        .form-wrapper {
            width: 60%;
            float: left;
            padding-right: 12px;
        }

        .form-label {
            margin-bottom: 4px;
        }

        .description {
            width: 40%;
            float: left;
            padding-left: 19px;

            > p {
                margin-bottom: 38px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .action,
        .form-check {
            clear: both;
        }

        .submit-form {
            > .form-check {
                margin: 0;
                margin-top: 38px;
            }
        }

        .form-check {
            display: inline-block;
            margin-top: 17px;
            width: 100%;

            .parsley-errors-list {
                margin-bottom: 29px;
            }
        }
    }

    //Form 2
    .membership-form-step2 {
        .submit-form {
            margin-top: 0;
        }

        .form-check {
            @include display-flex-cs();
        }
    }

    // Form 4
    .membership-form-step4 {
        > .note {
            margin-top: 56px;
        }

        .language-group {
            max-width: 445px;
        }

        textarea.form-control {
            max-width: 100%;
        }

        .profile-image {
            margin-top: 0;

            .form-label {
                margin-top: 15px;
            }

            .read-more {
                margin-top: 17px;
            }
        }

        .about-yourself {
            .note {
                margin-top: 24px;
            }
        }

        .list-all-artwork {
            .list-wrapper {
                margin-top: -5px;
                @include display-flex-cs();
                @include css-prefix((flex-wrap: wrap));
            }

            .list-wrapper .form-group {
                flex-basis: 50%;
            }
        }
    }

    // Form 5
    .upload-artwork-wrapper {
        .artwork-item {
            .media-description {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        .artwork-item:first-child .media-body {
            margin-top: 40px;
        }
    }
    .dm-uploader-box {
        .text-muted {
            a {
                text-transform: lowercase;
            }
        }
    }
    .dm-uploader {
        margin-top: 16px;
    }

    // Form 6
    .form-box.learn-artist-membership {
        margin-bottom: 33px;
    }
    .membership-form-step6 {
        .list-place {
            display: flex;
            flex-wrap: wrap;

            .form-group {
                flex-basis: 50%;

                &:nth-child(2n) {
                    padding-left: 15px;
                }
            }
        }
    }

    // Form 7
    .membership-form-step7 {
        .button-action .btn-primary {
            max-width: 261px;
        }
    }

    // Form Success
    .membership-form-success {
        .button-action .btn {
            max-width: 261px;
        }

        .button-action {
            flex-basis: 261px;
        }

        .success-content {
            margin-top: 56px;
        }

        .action {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}


// Mobile
@media only screen and (max-width: 767px) {

    // General
    .membership-application-form-wrapper {
        padding: 0 12px;
        margin-bottom: 56px;
    }

    .membership-application-form {
        padding: 24px 20px 56px;
        font-size: $font-size-base;
        line-height: $line-height-base;

        a {
            font-size: $font-size-base;
            line-height: $line-height-base;
        }

        .label-group {
            font-size: 18px;
            line-height: 24px;
        }

        .label-wrapper {
            margin-top: 25px;

            label {
                display: inline-block;
                width: 100%;
                padding: 0 0 12px;
                border-bottom: 1px solid $black;
            }

            .paginate {
                margin-top: 12px;
            }
        }

        .form-content {
            flex-wrap: wrap;
        }

        .form-check .checkbox-label {
            padding-left: 31px;
        }

        input,
        textarea,
        .custom-select .selected-item,
        .custom-select .item,
        .custom-select-state .selected-item,
        .custom-select-state .item {
            font-size: 14px;
            line-height: 18px;
        }

        .form-group {
            .form-control {
                padding: 8px 0 5px;
                margin-top: 6px;
            }

            .form-label {
                margin-bottom: 0;
            }

            textarea.form-control {
                padding: 10px 12px;
            }
        }

        .submit-form {
            margin-top: 40px;
        }

        .action {
            margin-top: 40px;
        }

        .button-action {
            @include display-flex-cs();
            @include css-prefix((flex-direction: column));
            gap: 25px;
            .btn-secondary {
                order: 2;
            }
        }

        .btn-secondary.arrows:after {
            left: -1px;
        }

        .btn-primary.arrows:after {
            right: -1px;
        }

        .custom-select-state .selected-item,
        .custom-select .selected-item {
            padding: 8px 28px 5px 0;
            margin-top: 6px;
        }

        .link-action {
            display: block;
            margin-top: 22px;
        }

        .save_and_back + .contact-us {
            margin-top: 56px;
        }

        .contact-us {
            display: block;
        }

        .form-check {
            .form-control {
                top: -20px;
                left: 31px;
                width: calc(100% - 31px);
            }
        }

        .list {
            label + input.form-control,
            label + .custom-select .selected-item,
            label + .custom-select-state .selected-item {
                margin-top: 3px;
            }
        }

        .form-group label + input.form-control,
        label + input.form-control,
        label + .form-group input.form-control,
        label + .custom-select .selected-item,
        label + .custom-select-state .selected-item {
            margin-top: 3px;
        }

        .form-group label + textarea.form-control {
            margin-top: 16px;
        }

        .note-page {
            margin-top: 40px;
            padding: 12px 12px 14px;

            p {
                margin-bottom: 29px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .custom-select-special .all-items {
            margin-top: 0;
        }

        .aai-cn {
            &.form-group .form-control {
                margin-top: 0;
            }

            .custom-select {
                flex-basis: 66px;
            }

            .custom-select .selected-item {
                margin-top: 0;

                &:after {
                    margin-top: 2px;
                }
            }

            .form-control {
                flex-basis: calc(100% - 82px);
            }
        }

        .operation-hours {
            .list-wrapper .form-check {
                margin-top: 15px;
            }

            .list-wrapper .form-group {
                min-width: 77px;
                margin-right: 0;
                padding-right: 10px;
            }

            .remove_field {
                top: 0;
                right: 0;
                left: auto;
            }

            > .form-group {
                padding-top: 30px;
            }

            label + .form-group {
                padding-top: 0;
            }
        }

        .hour-item {
            flex-basis: calc(50% - 8px);
        }

        .list-director,
        .list-related {
            margin-top: 24px;
        }

        .director-role,
        .related-aai-cn {
            margin-top: 24px;
        }

        .form-description,
        .form-description a {
            font-size: 18px;
            line-height: 24px;
        }

        .note-special {
            .important {
                font-size: 20px;
                line-height: 24px;
            }

            i {
                font-size: 24px;
            }
        }
    }

    .jconfirm {
        &.jconfirm-white .jconfirm-box,
        &.jconfirm-light .jconfirm-box {
            width: 100%;
            // max-width: 485px;
        }

        .jconfirm-box {
            // .jconfirm-buttons > button {
            //     margin: 10px 0;
            //     min-width: 100%;
            // }

            div.jconfirm-content-pane {
                padding: 0 15px;
            }
        }
    }

    // Form 1
    .membership-form-start {
        &.membership-application-form .button-action .btn-primary {
            margin-bottom: 0;
        }

        .form-wrapper {
            .form-control {
                padding-bottom: 9px;
            }
        }

        .submit-form {
            @include display-flex-cs();
            @include css-prefix((flex-direction: column));

            > .parsley-errors-list {
                order: 3;
            }
        }

        .form-check {
            order: 2;
            margin-top: 16px;
        }

        .validate-message-form {
            order: 3;
        }

        .description {
            order: 4;
            margin-top: 41px;

            > p:not(:last-child) {
                margin-bottom: 38px;
            }
        }

        .action {
            order: 5;
            margin-top: 40px;
        }

        .btn {
            padding: 6px 32px;
        }

        .btn-primary {
            &.arrows:after {
                right: 1px;
            }
        }
    }

    // Form 2
    .membership-form-step2 {
        .form-check {
            display: inline-block;
            margin-right: 25px;
        }

        .message-warning {
            margin-top: 17px;
        }

        .form-wrapper {
            margin-top: 41px;
        }
    }

    // Form 4
    #cropImagePop {
        padding: 16px 25px;
        width: 312px;
        height: 329px;

        .modal-body {
            margin: 16px 0 18px;
        }

        .rotate {
            top: 5px;

            &::before {
                background-image: url("../../public/images/icons/rotate-icon-small.svg");
                width: 15px;
                height: 17px;
            }
        }

        .modal-footer {
            bottom: 9px;
        }

        .save {
            width: 88px;
            margin-left: 15px;
        }

        a.close-modal {
            background-image: url("../../public/images/icons/close-icon-black-big.svg");
            width: 20px;
            height: 19px;
            top: 22px;
            right: 24px;
        }
    }

    .croppie-container {
        height: 205px;

        .cr-resizer,
        .cr-viewport {
            width: 172px !important;
            height: 172px !important;
        }

        .cr-slider-wrap {
            max-width: 123px;
        }
    }

    .cr-slider {
        padding: 16px 0;
    }

    .cr-slider::-webkit-slider-runnable-track {
        height: 1px;
    }

    .cr-slider::-webkit-slider-thumb {
        width: 8px;
        height: 8px;
        margin-top: -4px;
    }

    .artist .membership-form-step4 .upload-image-wrapper .read-more {
        display: none;
    }

    .membership-form-step4 {
        > .note {
            margin-top: 40px;
            padding: 11px 12px 15px;
        }

        .profile-image figure {
            width: 62px;
            height: 62px;
        }

        .upload-image-wrapper {
            padding-left: 14px;
        }

        .upload-image-wrapper {
            .upload-img {
                margin-top: 12px;
            }

            .read-more {
                margin-top: 12px;
            }
        }

        .about-yourself {
            .note {
                margin-top: 17px;
            }
        }

        .form-group textarea.form-control {
            margin-top: 16px;
        }
    }

    // Form 5
    .upload-artwork-wrapper {
        margin-top: 40px;

        .media-description {
            flex-direction: column;
        }
    }

    .artwork-item {
        margin-top: 36px;

        .status {
            margin-top: 4px;
        }

        .file-name {
            padding-right: 30px;
        }
    }

    .dm-uploader {
        margin-top: 17px;
        min-height: 188px;
        background-position: 50% 36%;

        a {
            text-transform: capitalize;
        }
    }

    .dm-uploader,
    .dm-uploader a {
        font-size: 18px;
        line-height: 24px;
    }

    .dm-uploader-box {
        .text-muted {
            top: -23px;
        }

        .text-muted span {
            display: none;
        }
    }

    // Success Form
    .membership-form-success {
        .success-content {
            margin-top: 40px;
        }
    }
}

@media only screen and (max-width: 390px) {
    .membership-application-form {
        .three-buttons-inline {
            justify-content: space-between;

            .form-check {
                margin: 0;
                margin-top: 15px;
            }
        }
    }
}

.artist .membership-form-step2 .form-wrapper > :not(.action, .parsley-errors-list),
.artist .membership-form-step3 .form-wrapper > :not(.action, .parsley-errors-list),
.artist .membership-form-step4 .form-wrapper > :not(.action, .parsley-errors-list),
.artist .membership-form-step5 .form-wrapper > :not(.action, .parsley-errors-list),
.artist .membership-form-step6 .form-wrapper > :not(.action, .parsley-errors-list),
.dealer .membership-form-step2 .form-wrapper > :not(.action, .parsley-errors-list),
.dealer .membership-form-step3 .form-wrapper > :not(.action, .parsley-errors-list),
.dealer .membership-form-step4 .form-wrapper > :not(.modal.fade, .parsley-errors-list),
.dealer .membership-form-step5 .form-wrapper > :not(script, .action, .parsley-errors-list),
.dealer .membership-form-step6 .form-wrapper > :not(.action, .parsley-errors-list),
    //.dealer .membership-form-step7 .form-wrapper > :not(.action, .parsley-errors-list),
.dealer .membership-form-step9 .form-description.certify .certify-list > *,
.supporter .membership-form-step2 .form-wrapper > :not(.action, .parsley-errors-list),
.supporter .membership-form-step3 .form-wrapper > :not(.action, .parsley-errors-list),
.supporter .membership-form-step4 .form-wrapper > .form-box,
.supporter .membership-form-step5 .form-wrapper > :not(.action, .parsley-errors-list),
.supporter .membership-form-step6 .form-wrapper > :not(.action, .parsley-errors-list),
.supporter .membership-form-step7 .form-description.certify ul li {
    display: none;
}
