// Announcement
.g-header {
    top: 0;
    z-index: 100;
    background: $white;
    &.sticky {
        position: sticky;
        position: -webkit-sticky;
        box-shadow: 0 1px 10px 2px rgb(0 0 0 / 15%);
        transition: all 1s;
    }
}

.w-content {
    max-width: 1210px;
    margin: auto;
    padding: 0 50px;
}

.n-bg {
    background: none;
}

.header-mobile {
    display: none;
}

.show{
    display: block !important;
}

.hidden{
    display: none !important;
}

.announcement {
    background-color: $light-orange;
    padding: 11px 50px;
    text-align: center;
    .announcement-bar__message {
        font-size: 14px;
        line-height: 18px;
        color: $black;
    }
    .close-btn {
        position: absolute;
        top: 13px;
        right: 26px;
        display: flex;
    }
}

.g-header__inner {
    padding-top: 8px;
    padding-bottom: 11px;
}

.search-button{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    border: 0;
    padding: 0;
    background: transparent;
    min-height: unset;
    .link {
        display: flex;
        column-gap: 5px;
    }
}

.open-menu {
    overflow: hidden;
}

.position-static {
    position: static;
}

nav {
    @include display-flex-cs();
    @include css-prefix((justify-content: space-between));
    .g-header__logo {
        width: 110px;
        background: none;
    }
}

.alert-warning.login-popup {
    margin-top: -26px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #e74c39;
}

.g-header__nav-primary {
    height: 100%;
    align-self: center;
    width: 80%;
    padding-top: 6px;
    .f-main-nav {
        @include display-flex-cs();
        @include css-prefix(
                (
                    justify-content: flex-end,
                    column-gap: 4px,
                    flex-wrap: wrap,
                    row-gap: 8px,
                )
        );
        padding-right: 16px;
        li {
            padding: 0 15px;
            @include display-flex-cs();
            @include css-prefix(
                (
                    align-items: center
                )
            );
            position: relative;
        }
        .u-show.search {
            position: relative;
            padding-left: 29px;
            padding-right: 10px;
            &::before {
                content: "";
                height: 27px;
                width: 1px;
                position: absolute;
                background: #000;
                top: -5px;
                left: 6px;
            }
        }
        .search{
            .nav-link {
                @include display-flex-cs();
                @include css-prefix(
                        (
                            align-items: center,
                            column-gap: 5px
                        )
                );
                color: $black;
            }
        }
        a,
        button.nav-link{
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            border: 0;
            padding: 0;
            color: $black;
            min-height: unset;
        }
    }
}

.top-banner {
    background: $black;
    ul{
        @include display-flex-cs();
        padding: 10px 30px 8px 10px;
        @include css-prefix(
            (
                justify-content: flex-end,
            )
        );
    }
    li{
        position: relative;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: $white;
        margin-left: 43px;
        .nav-link {
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 22px;

        }
    }

    .nav-item.orange {
        a:hover {
            color: $light-orange;
        }
    }
    .nav-item.green {
        a:hover {
            color: $light-green;
        }
    }
    .nav-item.blue {
        a:hover {
            color: $light-blue;
        }
    }
    @mixin dots {
        content: "";
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -17px;
        transform: translate(0, -50%);
        width: 12px;
        height: 12px;
    }
    li.green:before {
        @include dots;
        background: $green;
    }
    li.orange:before {
        @include dots;
        background: $orange;
    }
    li.blue:before {
        @include dots;
        background: $blue;
    }
}

main .search-content {
    display: block;
    z-index: 100;
}

.search-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #ECECEC;
    padding: 23px 30px 0;
    z-index: 1;
    .close-search {
        @include display-flex-cs();
        width: fit-content;
        margin-left: auto;
        @include css-prefix(
            (
                place-content: flex-end,
                column-gap: 15px,
                align-items: center
            )
        );
    }
    .form-group {
        @include display-flex-cs();
        @include css-prefix(
                (
                    flex-direction: column,
                    row-gap: 64px
                )
        );
        width: 730px;
        margin-left: auto;
        margin-right: auto;
        button {
            width: 133px;
            border-radius: 3px;
            text-transform: uppercase;
        }
    }
    .search-box {
        @include display-flex-cs();
        @include css-prefix(
            (
                column-gap: 32px
            )
        );
        margin-top: 77px;
        .search-input {
            width: 100%;
            position: relative;
            &::after {
                content: "";
                background: url("../../public/images/icons/icon-search.svg");
                height: 20px;
                width: 20px;
                display: block;
                position: absolute;
                top: 3px;
                right: 1px;
            }
        }
        input {
            width: 100%;
            padding-right: 40px;
            padding-bottom: 7px;
            padding-top: 0;
            height: auto;
        }
        .selected-item {
            background: transparent;
            padding-top: 0;
            padding-bottom: 7px;
        }
    }
    .custom-select {
        width: 100%;
        max-width: 254px;
    }
}

.close-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    &::after {
        content: "";
        display: block;
        background: url(/images/icon-close-black.svg);
        width: 16px;
        height: 15px;
        &:hover {
            cursor: pointer;
        }
    }

}

.u-show {
    position: relative;
}

.container__login-box,
.container__forget-box{
    top: 29px;
    position: absolute;
    right: 15px;
    padding: 48px 20px 57px;
    background: $white;
    border: 1px solid $black;
    z-index: 99;
    display: none;
}

.container__forget-box {
    padding-bottom: 38px;
    padding-top: 50px;
}

.login-box,
.forget-password--box{
    width: 308px;
    .title {
        padding-bottom: 7px;
        border-bottom: 1px solid $black;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
    }
    form {
        @include display-flex-cs();
        @include css-prefix(
            (
                flex-direction: column,
            )
        );
        margin-top: 95px;
        .form-control {
            width: 100%;
            padding-top: 0;
        }
        button {
            min-width: 188px;
            text-transform: uppercase;
        }
        > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    .box__btn-reset {
        margin-top: 16px;
        a.btn-reset {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .label {
        margin-top: 12px;
        span {
            font-size: 14px;
            line-height: 18px;
            color: #6A6A6A;

        }
    }
}

.forget-password--box {
    form {
        margin-top: 35px;
    }
    .title {
        padding-bottom: 12px;
    }
}

.box__btn-login {
    margin-top: 16px;
    button {
        text-transform: uppercase;
    }
}

main {
    position: relative;
}
form{
    .form-group{
        .box-errors {
            li {
                color: $orange;
                padding: 0;
                margin-bottom: 4px;
            }
        }
    }
}

.parsley-errors-list {
    li {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}

// new design

.container-nav {
    position: absolute;
    top: 16px;
    min-width: 129px;
    z-index: 2;
    .navbar-nav--child {
        display: none;
        margin-top: 8px;
        li:not(:first-child) {
            margin-top: 12px;
        }
    }
}

@media screen and (max-width: 1280px) {
    .navbar-nav--child {
        right: 0;
    }
}

.nav-item--child:hover .navbar-nav--child {
    display: block;
}

.g-header__nav-primary {
    .nav-item--child {
        svg {
            margin-left: 3px;
        }
    }
    .nav-link--child {
        @include display-flex-cs();
        align-items: baseline;
        background: transparent;
    }
    .navbar-nav--child {
        padding: 12px 16px 12px 8px;
        background: $white;
        border: 1px solid $black;
        li {
            padding: 0;
        }
    }
}

.nav-link--child:hover + .navbar-nav--child {
    display: block;
}

.g-header__nav-primary .nav-item--child .account-header svg {
    margin: 0;
}

@media (min-width: 1111px) {
    main {
        z-index: 1;
    }

    .g-header {
        position: relative;
    }
}

@media screen and (max-width: 1110px) {
    .search-content {
        padding: 0;
        .close-search {
            margin-right: 20px;
            margin-top: 20px;
        }
        .form-group{
            margin: auto;
        }
    }
}

@media screen and (max-width: 1110px) and (min-width: 768px) {
    main .search-content .close-search {
        margin-right: 0;
    }
    .close-container::after {
        margin-top: 25px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 1110px) {
    .header-desktop {
        display: none;
    }

    .header-mobile {
        display: block;
    }

    .announcement {
        padding: 11px 32px;
        .close-btn {
            right: 11px;
        }
    }

    .search-button {
        img {
            width: 18px;
            height: 18px;
        }
    }

    .container-mobile {
        padding: 0 32px;
        &.sticky-container {
            .g-header__logo:first-of-type {
                visibility: hidden;
                opacity: 0;
                width: 0;
            }
            .g-header__logo:last-of-type {
                visibility: visible;
                opacity: 1;
                width: auto;
            }
        }
        .g-header__logo:last-of-type {
            visibility: hidden;
            opacity: 0;
            width: 0;
        }
        .g-header__logo:first-of-type {
            visibility: visible;
            opacity: 1;
        }
        .g-header__logo {
            transition: visibility .5s, opacity .5s linear;
        }
    }


    .header-mobile.header {
        @include display-flex-cs();
        @include css-prefix(
            (
                justify-content: space-between
            )
        );
        padding-top: 11px;
        padding-bottom: 11px;
        .icon--mobile {
            button {
                --d: 0;
            }
            @include display-flex-cs();
            padding-top: 7px;
            column-gap: 36px;
            &> * {
                height: fit-content;
            }
        }
        .g-header__logo {
            max-width: 110px;
            background: none;
        }
    }
    .menu__user--mobile {
        margin-bottom: 14px;
        .nav-link::before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: transparent;
            border-radius: 50%;
        }
        .nav-link.orange::before {
            background: $orange;
        }
        .nav-link.green::before {
            background: $green;
        }
        .nav-link.blue::before {
            background: $blue;
        }
    }
    .nav--mobile {
        @include display-flex-cs();
        @include css-prefix(
            (
                flex-direction: column,
                row-gap: 12px
            )
        );
        margin-bottom: 14px;
        &::before{
            content: "";
            height: 1px;
            width: 100%;
            background: $white;
        }
        a {
            color: $white;
            font-weight: 400;
            font-size: 24px;
            line-height: 44px;
            margin-left: 33px;
            background: transparent;
        }
    }
    .search-login--mobile {
        @include display-flex-cs();
        @include css-prefix(
                (
                    flex-direction: column,
                    row-gap: 12px
                )
        );
        margin-bottom: 5px;
        &::before{
            content: "";
            height: 1px;
            width: 100%;
            background: $white;
            margin-bottom: 2px;
        }
        a{
            color: $white;
            font-weight: 400;
            font-size: 24px;
            line-height: 44px;
            margin-left: 33px;
        }
    }
    //    menu
    #menu-mobile {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        background: #050505;
        overflow: auto;
        z-index: 2;
    }
    .close__menu-mobile {
        position: absolute;
        top: 20px;
        right: 27px;
    }
    .menu__user--mobile {
        @include display-flex-cs();
        @include css-prefix(
            (
                flex-direction: column,
                row-gap: 12px
            )
        );
        margin-top: 66px;
        .nav-link{
            @include display-flex-cs();
            @include css-prefix(
                    (
                        align-items: center,
                        column-gap: 17px
                    )
            );
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            line-height: 44px;
            position: relative;
        }
    }

    //    search
    .search-content{
        .form-group {
            width: auto;
            input {
                font-size: 16px;
            }
        }
        .search-box {
            @include css-prefix(
                (
                    flex-direction: column,
                    row-gap: 29px
                )
            );
            margin-top: 70px;
            .search-input {
                width: 100%;
            }
        }
        .close-search {
            margin-top: 25px;
            margin-right: 32px;
            --d: 0;
            svg {
                width: 25px;
                height: 25px;
            }
        }
        .custom-select {
            max-width: 100%;
            .selected-item,
            .item {
                font-size: 16px;
            }
        }

        .form-group {
            @include css-prefix(
                (
                    row-gap: 70px
                )
            );
            button {
                width: 100%;
                padding: 12px 15px;
            }
        }
    }

    .login-box-mobile,
    .forget-password--box-mobile{
        background: $white;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 110px;
        overflow: hidden;
        display: none;
        .close__login-mobile,
        .close__forget-password-mobile{
            position: absolute;
            top: 25px;
            right: 32px;
            width: 25px;
            height: 25px;
            background: none;
        }
        .title{
            font-size: 14px;
            font-weight: 700;
            border-bottom: 1px solid $black;
            padding-bottom: 7px;
            text-transform: uppercase;
        }
        form {
            @include display-flex-cs();
            @include css-prefix(
                (
                    flex-direction: column,
                    row-gap: 30px
                )
            );
            margin-top: 95px;
            .form-group {
                input {
                    width: 100%;
                    padding-top: 0;
                }
            }
            button {
                margin-top: 16px;
                margin-bottom: 16px;
                width: 188px;
                text-transform: uppercase;
            }
        }
        .label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #6A6A6A;
            margin-top: 10px;
        }
    }

    .forget-password--box-mobile {
        form {
            margin-top: 60px;
            button {
                margin: 0;
            }
        }
        .title{
            padding-bottom: 12px;
        }
    }
    //    new design
    .navbar-nav-mobile--child {
        display: none;
        margin-left: 24px;
    }
    .nav-item-mobile--child {
        display: flex;
        align-items: baseline;
    }
    .navbar-box {
        svg {
            margin-left: 8px;
            transition: all 0.5s;
        }
        .arrowanim {
            svg {
                @include css-prefix(
                        (
                            transform: rotate(180deg)
                        )
                );
            }
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .top-banner {
        li {
            margin: 0 22px;
        }
    }

    .menu__user--mobile {
        .nav-link::before {
            margin-right: 17px;
        }
    }

    .login-box,
    .login-box-mobile{
        form {
            .form-group {
                margin-bottom: 30px;
            }
        }
    }
    .forget-password--box,
    .forget-password--box-mobile {
        form {
            .form-group {
                margin-bottom: 30px;
            }
        }
    }

    .g-header__nav-primary {
        width: 80%;
        @include css-prefix(
                (
                    align-self: center,
                )
        );
        padding-top: 7px;
        .f-main-nav {
            li {
                padding-top: 8px;
            }
            .u-show.search::before {
                top: 3px;
            }
            .search {
                .nav-link {
                    margin-left: 5px;
                    @include css-prefix(
                        (
                            column-gap: 0
                        )
                    );
                }
            }
        }
    }
    .container__login-box, .container__forget-box {
        top: 38px;
    }
    .search-content {
        .search-box {
            margin-bottom: 64px;
            .search-input{
                margin-right: 32px;
            }
        }
        .close-search{
            svg {
                margin-left: 15px;
            }
        }
    }
    .header-mobile{
        .icon--mobile{
            button {
                margin-right: 36px;
            }
        }
    }

    .header-mobile.header {
        .icon--mobile {
            column-gap: 0;
        }
    }

    @media screen and (max-width: 1110px) {
        .search-content {
            .search-box {
                .search-input {
                    margin-bottom: 30px;
                }
            }
            .custom-select {
                margin-bottom: 70px;
            }
        }
    }
}

// reset page

.reset-page {
    max-width: 400px;
    margin: 70px auto;
    .login-box {
        width: 100%;
    }
    h1 {
        font-size: 34px;
        line-height: 44px;
    }
    .card-header {
        margin-bottom: 20px;
    }
    .form-control {
        width: 100%;
    }
    .login-box {
        form {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .close-container::after {
        margin-right: 32px;
        margin-top: 25px;
        height: 25px;
        width: 25px;
        background-size: 25px 25px;
    }
}
