.error-page {
    display: flex;
    margin-top: 105px;
    margin-right: 11.1%;
    h1 {
        font-weight: 700;
        font-size: 34px;
        line-height: 44px;
        color: $orange;
        margin-bottom: 8px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $black;
        margin-bottom: 46px;
    }
    &~ * {
        display: none;
    }

    .g-intro {
        -webkit-grid-template-columns: auto;
        -moz-grid-template-columns: auto;
        grid-template-columns: auto;
    }
}

.homepage.error-page .btn:first-child {
    padding-left: 12.5px;
    padding-right: 19px;
    &::before {
        content: "";
        width: 9px;
        height: 18px;
        margin-right: 11px;
        background: url("../../public/images/icons/icon-arrow-left-white.svg");
    }
}

.homepage.error-page .g-intro__buttons {
    margin-left: 4px;

    .btn {
        height: 41px;
    }

    &.verify-buttons {
        margin-left: 2px;

        .btn {
            padding: 0 35px;
            text-transform: uppercase;

            &::before {
                display: none;
            }
        }
    }
}

.homepage.error-page .btn{
    &+ .btn {
        margin-left: 0;
        padding: 0 41px;
        background: transparent;
        border: 1px solid #0A0A0B;
        &:hover {
            background: $black;
        }
    }
}

@media (min-width: 1025px) {
    .homepage.error-page .g-intro__buttons {
        gap: 26px;

        &.verify-buttons {
            .btn {
                max-width: 159px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .homepage.error-page .g-intro__buttons .btn {
        width: fit-content;
    }
}

@media screen and (max-width: 1024px) {
    .homepage.error-page {
        margin: 96px auto 0;
        padding: 0 32px;
        justify-content: center;
        p {
            margin-bottom: 56px;
        }
        .container {
            padding: 0;
            margin: 0;
            width: 100%;
            max-width: 311px;
        }
        .g-intro__buttons {
            flex-direction: column;
            margin: 0;
        }
        .btn + .btn {
            margin-top: 20px;
        }
        .btn:first-child {
            position: relative;
            padding-left: 21px;
            margin-top: 0;
            
            &::before {
                position: absolute;
                left: 20px;
            }
        }
        .g-intro__buttons .btn {
            width: 100%;
        }
    }
}

@media (max-width: 767.98px) {
    .homepage.error-page .g-intro__buttons {
        &.verify-buttons {
            .btn {
                margin-top: 0;
            }
        }
    }
}
