//section 1: Search actions
a.none-decoration {
    color: $black;
    --d: 0;
    background: linear-gradient($black 0 0) 0 92% /var(--d, 0) 1px no-repeat;
    transition: 0.2s;

    &:hover {
        --d: 100%;
    }
}

.search__action {
    padding-top: 40px;
    padding-bottom: 42px;

    .title {
        margin-bottom: 28px;

        h1 {
            color: $orange;
            letter-spacing: 0.2px;
        }
    }

    .selected-item {
        width: 100%;
        padding: 10px 0 8px;
    }

    .form-search {
        @include display-flex-cs();
        align-items: flex-end;
        column-gap: 30px;

        .form-group {
            margin-right: 30px
        }

        .key-words {
            width: 33%;
            position: relative;

            .icon {
                position: absolute;
                right: 0;
                bottom: 8px;
                z-index: 1;
            }
        }

        input {
            padding: 10px 40px 8px 0;
            height: auto;
            width: 100%;
            position: relative;
            z-index: 2;

            &:focus {
                border-bottom: 1px solid #000;
            }

            &[name="daterange"] {
                cursor: pointer;
            }
        }

        .dropdown-category {
            width: 254px;
        }

        .box__button {
            display: flex;

            .button:first-child {
                margin-right: 0;
            }

            .btn {
                text-transform: uppercase;
                padding: 8px 28px;
            }
        }

        .btn.btn-secondary {
            padding: 8px 28px;
        }
    }
}

.latest-news {
    .custom-select .all-items {
        overflow-y: auto;
    }

    .news-article {
        display: none;
    }
}

// section 2: Featured News Article

.news-article .news-article__info .description .news-article--images__mobile {
    display: none;
}

.news-article {
    padding: 28px 0 35px;
    display: flex;
    column-gap: 60px;
    justify-content: space-between;

    .news-article__info {
        flex-direction: column;
        row-gap: 28px;

        .title {
            color: $orange;
            display: flex;
            column-gap: 17px;
            align-items: center;
            position: relative;

            a {
                position: absolute;
                height: 100%;
                width: 100%;
                background: none;
                top: 0;
                left: 0;
                border: 0;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            .body-copy {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
            }

            * {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }

            > :not(:first-child) {
                margin-top: 26px;
            }
        }

        .read-full {
            margin-top: 32px;
            text-transform: uppercase;

            a {
                padding: 6px 12px;
            }
        }
    }
}

.news-article--images__desktop {
    display: flex;
    flex-direction: column;
    max-width: 540px;

    .image__hint {
        font-style: italic;
        font-size: 14px;
        line-height: 16px;
        color: #939598;
        margin-top: 19px;
    }
}

// section 3: search__result

// section 3.1 filter

.search__result {
    padding-bottom: 90px;
}

.box__filter {
    padding-top: 48px;
    padding-bottom: 12px;
    border-bottom: 1px solid #000000;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .number__count {
        color: #939598;

        p span {
            color: $black;
        }
    }

    .view--type__desktop {
        display: flex;
        column-gap: 20px;
        padding-right: 15px;

        :not(.active) a {
            color: #939598;
            background: linear-gradient(#939598 0 0) 0 92% /var(--d, 0) 1px no-repeat;
        }

        .list-view--type__desktop {
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 1px;
                height: 13px;
                background: #939598;
                position: absolute;
                top: 4.5px;
                right: -11px;
            }
        }

    }

    .box__button {
        .btn {
            text-transform: uppercase;
        }
    }

}

.filter {
    padding-top: 5px;
    position: relative;

    .selected-item {
        padding: 0 0 7px;
        border-bottom: 0;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        column-gap: 16px;
        align-items: center;

        &:after {
            position: relative;
            margin-top: 7px;
        }
    }

    .custom-select {
        width: 314px;

        .all-items {
            border: 1px solid #000;
        }
    }
}

// section 3.2: list result

.link-news-article {
    display: block;
    max-width: 100%;
    //position: absolute;
    //top: 0;
    //left: 0;
    height: 100%;
    width: 100%;
    background: transparent;
}

.news-article__name,
.news-article__info .title {
    position: relative;

    h2 {
        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 47px;
            height: 11px;
            background: url("/images/icons/arrow-latest-news.svg");
            margin-left: 17px;
            transition: all 0.5s;
        }
    }

    &:hover {
        h2 {
            &::after {
                margin-left: 22px;
            }
        }
    }
}

.result__tile-view .item-list .news-article__name h2::after {
    margin-left: 9px;
    margin-bottom: 2px;
}

.searching .result__tile-view .item-list .news-article__name h2::after {
    display: block;
    margin-left: 0;
    margin-top: 15px;
}

.result__tile-view .item-list .news-article__name:hover h2::after {
    margin-left: 14px;
}

.result__list-view {
    padding-top: 26px;
    display: flex;
    flex-direction: column;
    row-gap: 45px;

    .item-list {
        position: relative;
        display: none;
        column-gap: 32px;
        align-items: center;
        height: auto;

        &.showing {
            display: flex;
        }

        .small-image {
            //padding-bottom: 165px;
            position: relative;
            width: 235px;
            min-width: 235px;
            height: 165px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .category__name {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }

        .news-article__name {
            display: inline-block;
            column-gap: 17px;
            align-items: center;
            margin-top: 12px;

            h2 {
                font-weight: 400;
                color: $orange;
                display: inline-block;
            }

            .icon {
                display: inline-block;
                vertical-align: text-top;
            }
        }

        .author__name {
            margin-top: 2px;
        }

        .description {
            margin-top: 17px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            * {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin: 0;
            }

            .ql-editor {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .ql-editor {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        > a {
            position: absolute;
            width: 100%;
            height: 100%;
            background: unset;
        }
    }
}

.result__tile-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 60px;
    padding-top: 32px;

    .item-list {
        position: relative;
        flex-direction: column;
        margin-right: 30px;
        display: none;

        .small-image {
            position: relative;
            height: 225px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .category__name {
            font-size: 18px;
            font-weight: 700;
        }

        .information {
            padding-top: 16px;
        }

        .news-article__name {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            align-items: start;
            row-gap: 15px;

            h2 {
                color: $orange;
                font-weight: 400;
                font-size: 26px;
                line-height: 32px;
            }
        }

        .author__name {
            margin-top: 15px;
        }

        .description {
            margin-top: 17px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;

            * {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin: 0;
            }

            .ql-editor {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }

            .ql-editor {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }
        }

        > a {
            position: absolute;
            width: 100%;
            height: 100%;
            background: unset;
        }
    }
}

.latest-news .result__tile-view .news-article__name h2 {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &::after {
        margin: 0;
    }
}

.list-view--type.active {
    svg {
        * {
            stroke: $black;
            fill: $black;
        }
    }
}

.tile-view--type.active {
    svg {
        * {
            stroke: $black;
            fill: $black;
        }
    }
}

.result__show-more {
    display: flex;
    column-gap: 55px;
    margin-top: 77px;
    position: relative;
    z-index: 99;

    .number__count {
        color: $color-text3;
    }
}


.view--type__mobile {
    display: none;
}

.dropdown--text {
    font-family: 'DM Sans';
    font-style: normal;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    background: transparent;
    border: 0;
    padding: 8px 10px 8px 0;
    @include css-prefix((transition: 0.5s));

    &::after {
        content: '';
        background: url('../../public/images/arrow-base.svg') center / 100% 100% no-repeat;
        width: 21px;
        height: 12px;
        position: absolute;
        top: 50%;
        right: -26px;
        @include css-prefix((transition: 0.5s));
        @include css-prefix((transform: translateY(-50%)));
    }

    &.arrowanim {
        @include css-prefix((transition: 0.5s));
        padding-left: 7px;

        &::after {
            transform: translateY(-50%) rotate(180deg);
            -webkit-transform: translateY(-50%) rotate(180deg);
            -moz-transform: translateY(-50%) rotate(180deg);
        }
    }
}


.dropdown--list {
    border: 1px solid $black;
    position: absolute;
    z-index: 100;
    background: $white;

    .dropdown--list--item {
        position: relative;
        padding: 6px 7px;
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;
        @include css-prefix((user-select: none));

        background: $white;
        transition: unset;

        a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: transparent;
        }

        &:hover {
            background: $black;
            background: $black;
            color: $white;
        }
    }
}

.dropdown--list--item.selected {
    color: $orange;
}

.latest-news .dropdown--box {
    position: relative;
}

.disabled {
    display: none;
}

.search__result .dropdown--text {
    min-width: 275px;
    text-align: left;
}


@media all and (max-width: 1024px) {

    .latest-news .search__action {

        .form-search {
            row-gap: 24px;
            flex-wrap: wrap;
            justify-content: space-between;

            .key-words,
            .dropdown-category {
                width: calc(50% - 30px);
                margin: 0;
            }

            .box__button {
                margin-top: 16px;
                row-gap: 24px;

                .btn {
                    width: 100%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }

            .btn.btn-secondary {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }

    .news-article {
        align-items: center;
    }

    .result__tile-view {
        grid-template-columns: 1fr 1fr;

        .item-list {
            margin-right: 0;
        }

    }
}

@media all and (max-width: 767px) {
    .news-article {
        .g-intro.g-intro-only,
        .news-article__info{
            width: 100%;
        }
        .news-article__info .description > .body-copy {
            display: none;
        }
    }

    .latest-news .news-article.showing {
        display: block;
    }
    .news-article__name h2:after,
    .news-article__info .title h2:after {
        display: block;
        margin: 12px 0 0 0;
    }
    .news-article__name:hover h2::after,
    .news-article__info .title:hover h2::after {
        margin: 12px 0 0 5px;
    }

    .search__action {
        padding-bottom: 48px;
        padding-top: 25px;

        .title {
            margin-bottom: 40px;
        }

        .form-search {
            flex-direction: column;
            row-gap: 24px;

            input {
                width: 100%;
            }

            .key-words,
            .dropdown-category {
                width: 100%;
            }

            .box__button {
                margin-top: 16px;
                flex-direction: column;
                row-gap: 24px;
                width: 100%;

                .btn {
                    width: 100%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }

            .btn.btn-secondary {
                padding-top: 12px;
                padding-bottom: 12px;
            }

            input,
            .key-words {
                width: 100%;
            }

            .form-group-date-range {
                z-index: 1;
            }

            .form-group-type {
                z-index: 2;
            }

            .form-group-location {
                z-index: 3;
            }

            .form-group-keyword {
                z-index: 4;
            }
        }
    }

    .news-article {
        align-items: center;

        .news-article--images__desktop {
            width: 50%;
        }
    }

    .result__tile-view .item-list {
        margin-right: 0;
    }
}

@media all and (max-width: 767px) {
    .search__action .form-search .box__button .button:first-child {
        margin-right: 0;
    }

    .news-article--images__desktop {
        display: none;
    }
    .box__filter {
        .view--type__desktop {
            display: none;
        }
    }

    .news-article .news-article__info .description .news-article--images__mobile {
        display: block;
    }

    .news-article {
        padding-top: 40px;
        padding-bottom: 48px;
        flex-direction: row;
        justify-content: center;

        .news-article__info {
            width: 100%;

            .title {
                flex-direction: column;
                row-gap: 12px;
                align-items: flex-start;

                h2 {
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 44px;
                }
            }

            .read-full {
                a {
                    width: 100%;
                }
            }
        }
    }
    .news-article .news-article__info .description .news-article--images__mobile {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .image__hint {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: $color-focus-input;
            font-style: italic;
        }
    }
    .result__list-view {
        row-gap: 56px;

        .item-list {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 24px;

            .news-article__name {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 12px;
            }

            .author__name {
                margin-top: 12px;
            }

            .description {
                p {
                    -webkit-line-clamp: 5;
                }
            }

            img {
                max-width: 100%;
            }
        }
    }

    .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .dropdown--list {
            top: 43px;
        }

        .selected-item {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-bottom: 0;

            &::after {
                position: absolute;
                margin-top: 0;
            }
        }

        .view--type__mobile {
            display: flex;

            .list-view--type__mobile {
                margin-right: 20px;
            }
        }
    }
    .all-items {
        margin-top: 7px;
    }

    .result__list-view {
        padding-top: 50px;

        .item-list {
            .news-article__name {
                h2 {
                    font-weight: 700;
                }
            }

            .description {
                margin-top: 24px;
            }
        }
    }

    .result__tile-view {
        padding-top: 48px;
        grid-template-columns: 1fr;
        grid-row-gap: 48px;

        .item-list {
            padding-right: 0;

            .small-image {
                display: none;
            }

            .information {
                padding-top: 0;
            }

            .category__name {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
            }

            .news-article__name {
                flex-direction: column;
                row-gap: 12px;
                align-items: flex-start;
            }

            .author__name {
                margin-top: 12px;
            }

            .description {
                margin-top: 24px;
            }
        }
    }
    .search__result {
        padding-bottom: 64px;
    }
    .result__show-more {
        margin-top: 56px;
    }

    .custom-select {
        .all-items {
            margin-top: 0;
        }
    }
    .latest-news .dropdown--text {
        max-width: 65%
    }
    .latest-news + .g-content__question-and-link .question-and-link-row {
        padding-top: 40px;
    }

    .latest-news ~ .g-content__question-and-link {
        background: #ECECEC;
    }
    .latest-news .search__action .form-search .key-words,
    .latest-news .search__action .form-search .dropdown-category {
        width: 100%;
    }

    .latest-news .result__list-view .item-list .small-image {
        //padding-bottom: 68%;
        padding-bottom: 0;
        width: 100%;
    }

    .result__list-view .item-list .description .ql-editor {
        -webkit-line-clamp: 5;
    }

    .latest-news .result__list-view .item-list .small-image img {
        max-height: 100%;
    }
    .latest-news .result__list-view .item-list .small-image {
        padding-bottom: 68%;
        width: 100%;
    }
}

@media all and (min-width: 768px) {
    .latest-news {
        .news-article.showing {
            display: grid;
            grid-template-columns: 51.4% calc(48.6% - 29px);
            -moz-column-gap: 29px;
            column-gap: 29px;
        }

        .search__action {
            .form-search {
                .box__button {
                    column-gap: 20px;
                }
            }
        }
    }

    .box__filter .view--type__desktop {
        min-width: 161px;
    }

    .search-page .box__filter,
    .latest-news .box__filter {
        column-gap: 60px;
    }
}

@media all and (max-width: 450px) {
    .latest-news .dropdown--text {
        min-width: unset;
    }
}

@supports (-webkit-touch-callout: none) {
    .search__action .form-search .box__button {
        column-gap: 0;
    }

    .search__action .form-search .box__button .button:first-of-type {
        margin-right: 24px;
        width: 100%;
    }

    .box__filter .view--type__desktop {
        column-gap: 0;
    }

    .box__filter .view--type__desktop .list-view--type__desktop {
        margin-right: 20px;
    }

    .result__show-more {
        column-gap: 0;
    }

    .result__show-more .button--show-more {
        margin-right: 55px;
    }

    .result__list-view .item-list {
        column-gap: 0;
    }

    .result__list-view .item-list .small-image {
        margin-right: 32px;
    }

    .news-article .news-article__info .description *,
    .result__tile-view .item-list .description *,
    .result__list-view .item-list .description * {
        display: inline;
    }

    @media screen and (min-width: 768px) {
        .latest-news .search__action .form-search .btn.btn-secondary {
            width: max-content;
        }
    }
}
