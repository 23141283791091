.jconfirm .jconfirm-box div.jconfirm-content-pane {
    padding: 0;
}

.jconfirm.jconfirm-light .jconfirm-box {
    display: flex;
    flex-direction: column;
}

.tabbedPanels {
    margin-top: 25px;
}

.panelContainer {
    clear: left;
    background-color: #ECECEC;
}

.color-333 {
    color: $light-black;
}

#cropSatellitesImagePop {
    width: 485px;
    height: 512px;
    background-color: $white;
    padding: 40px;
    border: 1px solid $black;
    box-shadow: none;
    top: unset;
    transform: unset;

    h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: $black;
        text-align: left;
    }

    a.close-modal {
        background-image: url("../../public/images/icons/close-icon-black.svg");
        top: 44px;
        right: 38px;
    }

    .modal-body {
        background: $color-border-load;
        margin: 24px 0 18px;
    }

    .rotate {
        font-size: 0;
        background: none;
        border: none;
        padding: 0;
        position: relative;
        top: 10px;

        &::before {
            content: "";
            background: url("../../public/images/icons/rotate-icon.svg") center/100% no-repeat;
            width: 22px;
            height: 25px;
            display: block;
        }
    }

    .modal-content {
        position: relative;
    }

    .modal-footer {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .save {
        text-transform: uppercase;
        margin-left: 24px;
        min-width: 93px;
    }

}

.untyped {
    .custom-select .selected-item {
        border-bottom: none;
    }
}

// .customer-dashboard-form-body{
//     .blocker::before{
//         content: "";
//         background: #939598;
//         opacity: 0.4;
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         margin: 0;
//     }
// }
.satellite-error {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #e74c39;
    margin-top: 12px;
}

/* reset ul defaults  */
.tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

/* set li properties  */
.tabs li {
    float: left;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-right: 15px; /* spaces out the tabs  */
    text-align: center;
    width: 100%;

    &:last-child {
        margin-right: 0;
    }
}

/* all formatting goes inside 'a' */
.tabs a {
    display: block;
    text-decoration: none;
    font-weight: 400;
    padding: 9px 10px 10px;
    margin-right: auto;
    margin-left: auto;
    background: none;
    margin-bottom: -2px;
    width: 100%;
    border-top: 1px solid #000;
}

.tabs a.active {
    border-top: 1px solid #000;
    background: #ECECEC;
    color: #000;
}

.tabs a.disabled {
    border-top: solid 1px #939598;
    color: #939598;
}

.opener.disabled {
    color: #CCCCCC;
}

.rotate-opener-icon {
    transform: rotate(90deg);
}

.opener {
    svg {
        transition: all 0.5s;
    }
}

.panel {
    h1 {
        color: $orange;
    }

    p {
        margin-bottom: 0px;
    }

    &.welcome {
        display: flex;
        justify-content: space-between;
        padding: 70px 30px 31px 95px;

        .g-intro__content {
            max-width: 285px;
            width: 100%;
            margin-right: 20px;
        }

        .g-intro__image {
            width: 100%;
            margin-top: 7px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            img {
                height: 100%;
                width: auto;
                object-fit: cover;
                display: inline;
                max-width: 635px;
                max-height: 400px;
            }

            .g-intro__caption {
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #939598;
                margin-top: 10px;
                max-width: 635px;
            }
        }
    }

    &.membership {
        padding: 57px 95px 98px 95px; //ppx
        margin-top: 0;

        .button-action {
            .btn-secondary {
                min-width: 206px;
                margin-right: 31px;
                justify-content: center;

                &.arrows-short {
                    &:after {
                        filter: unset;
                    }

                    &:hover::after {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
                    }
                }
            }

            .btn-primary {
                min-width: 255px;
            }

            .min-w-180 {
                min-width: 180px;
            }

            .min-w-0 {
                min-width: 0;
            }

            .arrows-short {
                text-transform: uppercase;

                &:before {
                    display: none;
                }
            }
        }

        .arrows-short:after {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
            position: unset;
        }
    }

    &.event {
        padding: 54px 95px 82px 96px;
    }
}

.customer-dashboard-page {
    margin-top: 24px;

    .article-video {
        max-width: 635px;
    }

    .error:empty {
        display: none;
    }

    .membership-application-form {
        .satellite-item {
            .custom-select,
            .custom-select-state {
                .selected-item.parsley-error {
                    color: #000;
                }
            }
        }
    }

    .page-title-wrapper {
        border-bottom: none;
    }

    .membership-dashboard {
        h2.page-title-wrapper {
            padding-bottom: 17px;
        }

        .note-wrapper {
            margin-top: 53px;
        }
    }

    .incomplete-membership {
        .note-wrapper {
            margin-top: 24px;
        }
    }

    // tooltip
    .tooltip {
        position: relative;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 100%;
        margin-left: 30px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border: .5em solid transparent;
            border-top-color: #000;
            transform: translate(-50%, 0);
        }
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    .page-title-wrapper {
        padding-bottom: 8px;
    }

    .greeting-section {
        margin-bottom: 43px; //ppx
        .page-title-wrapper {
            letter-spacing: 0.2px;
        }

        .line-end {
            grid-area: 2/1;
        }
    }

    input.form-control {
        height: unset;
    }

    .action {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .button-action {
            display: flex;
            flex-shrink: 0;
        }

        .contact-us > a {
            margin-bottom: 0;
        }
    }

    .row-gap-0 {
        row-gap: 0;
    }

    .flex-row {
        flex-direction: row;

        .link-action {
            width: auto;
            margin-top: 19px;
        }
    }

    .secondary-container {
        max-width: 1150px;
        padding: 0 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .g-form {
        .g-intro__shortdesc {
            max-width: 284px;
        }
    }

    .icon-question {
        position: relative;
        padding-left: 24px;
        margin-left: auto;

        &:before {
            content: "";
            background: url("../../public/images/question-icon.png") center/100% 100% no-repeat;
            width: 25px;
            height: 26px;
            display: block;
            position: absolute;
            top: 0;
            left: -3px;
        }
    }

    .step {
        text-align: center;

        .custom-link {
            text-align-last: left;
            width: 75%;

            &:not(:last-child) {
                margin-top: 24px;
                margin-bottom: 9px;
            }
        }

        h3 {
            margin-bottom: 7px;
            font-size: 18px;
            line-height: 24px;
        }

        > .f-body {
            p:not(:first-child) {
                margin-bottom: 8px;
            }
        }
    }

    .g-block-editor {
        margin-top: 67px;
        padding-bottom: 65px;

        &:empty {
            display: none;
        }
    }
}

.link-to-contact {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.link-to-contact.mobile {
    display: none;

    a {
        margin: 0;
    }
}

.greeting-section {
    display: grid;
    grid-template-columns: minmax(0, 633px) minmax(0, 445px);
    column-gap: 31px;
    margin-bottom: 55px;

    .g-intro__shortdesc {
        grid-area: 2/1;
        margin-top: 17px;
        max-width: 605px;

        p {
            margin-bottom: 7.5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.page-title-wrapper {
    grid-area: 1/1;
    padding-bottom: 16px;
    border-bottom: 1px solid #000000;
}

.note-wrapper {
    margin-top: 24px;
}

.panel.welcome {
    .g-intro__title {
        color: #008264;
        margin-bottom: 20px;
    }
}

.contact-us {
    grid-area: 1/2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    > a {
        margin-bottom: 20px;
    }
}

.become-member {
    grid-area: 2/2;
}

.g-intro__action {
    background: #ECECEC;
    box-sizing: border-box;
    padding: 17px 33px 26px 20px;
    min-height: 164px;

    h3 {
        margin-bottom: 12.5px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #E74C39;
    }

    .custom-link {
        margin-top: 19.24px;
    }
}

.continue-app {
    flex-direction: column;
    align-items: flex-start;
}

.link-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
}

.opener {
    display: none;
}

/*
* step to become member
*/
.progressbar {
    counter-reset: step;
    padding: 0;
    margin-top: 40px; //ppx
    transform-style: preserve-3d;
    max-width: 800px;
    display: flex;

    .step-last {
        width: 100%;
        max-width: 124px;
    }
}

.progressbar li::after {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px solid #939598;
    border-radius: 50%;
    color: #939598;
    content: counter(step);
    counter-increment: step;
    font-weight: 700;
    height: 36px;
    width: 36px;
    line-height: 27px;
    text-align: center;
}

.progressbar li::before {
    background: #979797;
    content: '';
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    z-index: -1;
}

// .progressbar li:first-child::before {
//     content: none;
// }

.progressbar li.active::before,
.progressbar li.complete:before {
    background: #009A81;
}

.progressbar li.active:after,
.progressbar li.complete::after {
    background: #fff;;
    border-color: #009A81;
    color: #009A81;
}

.progressbar li.complete.need-update::after {
    border-color: #F1745D;
    color: #F1745D;
}

.progressbar li.active {
    color: #009A81;
    font-weight: 700;
}

.state {
    input ~ .selected-item {
        display: none !important;
    }
}

/*
* Account tab
*/
.panel.account {
    .form-label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13.9978px;
        line-height: 22px;
        text-transform: uppercase;
        color: #000000;
        margin-top: 57px;
    }

    .submit-form {
        > .form-group {
            input {
                padding-top: 0;
                padding-bottom: 13px;
            }
        }
    }

    .account-dashboard {
        .personal-label {
            margin-top: 0;
        }

        box-sizing: border-box;
        padding: 64px 95px 94px;

        .account-personal-label {
            font-style: normal;
            font-weight: 700;
            font-size: 13.9978px;
            line-height: 22px;
            text-transform: uppercase;
            color: $black;
        }

        input,
        input::placeholder {
            color: #939598;
        }

        .custom-select {
            display: flex;
            flex-direction: column-reverse;

            .parsley-error + .selected-item {
                border-color: #e74c39;
            }

            .parsley-error + .selected-item.parsley-success {
                border-color: #000;
            }

            .selected-item {
                color: #939598;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-top: 0;
                padding-bottom: 8px;
                font-size: 14px;
                line-height: 18px;
                position: relative;

                &.parsley-error {
                    border-color: #e74c39;
                }
            }
        }

        .action {
            flex-direction: row;

            button {
                text-transform: uppercase;
                min-width: 160px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .online-trading {
        display: grid;

        input {
            width: 100%;
        }

        .input-box {
            max-width: 445px;
            position: relative;

            input {
                margin-top: 41px; //ppx
            }

            & + .input-box {
                margin-left: 31px;
            }

            &:first-child {
                grid-area: 1/1;
            }

            &:last-child {
                grid-area: 1/2;
            }

            .parsley-errors-list {
                li:not(:first-child) {
                    display: none;
                }
            }
        }
    }

    .postal-address {
        margin-top: 56px;

        input,
        .state,
        .country {
            width: 100%;
            max-width: 445px;
        }

        input {
            padding-top: 0;
            padding-bottom: 8px;
            font-size: 14px;
            line-height: 18px;
        }

        .country {
            margin-top: 15px;
        }

        .state {
            .selected-item {
                padding-top: 0;
                padding-bottom: 8px;
                font-size: 14px;
                line-height: 18px;
                color: #939598;

                &.arrowanim {
                    border-color: #000;
                }
            }
        }

        .label-group {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .address {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
        }

        .apartment,
        .suburb,
        .post-code,
        .state {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
        }
    }

    .name-art {
        width: 100%;

        .form-label {
            margin-top: 70px;
            text-transform: unset;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: block;
        }

        input {
            width: 100%;
            padding-top: 12px;
            max-width: 445px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #939598;
            padding-bottom: 8px;
        }
    }

    .artist-account {
        .contact-method {
            .form-label {
                margin-top: 69px; //artist account ppx
            }
        }
    }

    .contact-method {
        width: 100%;
        max-width: 445px;

        .form-label {
            margin-top: 56px; //ppx account supporter
            text-transform: unset;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }

        .custom-select {
            .selected-item {
                color: #939598;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-top: 0;
                padding-bottom: 10px; //ppx account supporter
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                margin-top: 9px;

                &.arrowanim {
                    border-color: #000;
                }
            }
        }
    }

    .action {
        margin-top: 70px;
    }
}

/*
* Profile tab
*/
.list-filter {
    .association-item,
    .request-association {
        font-weight: 400;
        font-size: 16px;
        align-items: flex-end;
        line-height: 22px;
        margin-right: 59px;
        width: max-content;
    }

    .association-item {
        width: 100%;
        padding-bottom: 8px;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &:not(:last-child) {
            border-bottom: solid .5px $black;
        }
    }
}

.search-members {
    position: relative;

    .list-filter {
        position: absolute;
        top: 39px;
        width: 100%;
        background: #fff;
        border: solid 1px #000;
        min-height: 69px;
        max-width: 919px;
        display: flex;
        align-items: center;
        display: none;
        box-sizing: border-box;
        z-index: 2;
        padding-left: 12px;
        padding-top: 12px;
        padding-right: 22px;

        .name-role {
            margin-bottom: 5px;

            .role {
                margin-left: auto;
            }

            .name {
                min-width: 200px;
            }
        }

        .request-association {
            margin-bottom: 4px;
        }
    }
}

.alert-success {
    margin-top: 24px;
}

.panel.profile {
    padding: 59px 97px 59px 94px;

    input,
    input::placeholder {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14.0043px;
        line-height: 18px;
        color: #939598;
        padding-top: 0;
    }

    .profile-label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13.9978px;
        line-height: 22px;
        margin-bottom: 34px;
        text-transform: uppercase;
    }

    .association-column {
        display: flex;
        align-items: flex-end;

        a {
            font-size: 16px;
            line-height: 22px;
        }

        &:nth-child(2) {
            justify-content: flex-end;
        }

        &:last-child {
            justify-content: flex-end;
        }

        .remove-member {
            font-weight: 400;
            font-size: 0;
            line-height: 22px;
            background: none;

            &::after {
                content: "";
                width: 19px;
                height: 22px;
                background: url("../../public/images/icons/delete-icon.svg") center/100% no-repeat;
                min-width: unset;
                display: block;
            }
        }
    }

    .profile-image {
        margin-bottom: 41px; //ppx dealer
        .item-img {
            display: none;
        }

        .upload-img {
            margin-bottom: 14px !important;
        }
    }

    .remove-member {
        transition: unset;

        &::after {
            content: "";
            width: 19px;
            height: 22px;
            background: url("../../public/images/icons/delete-icon.svg") center/100% no-repeat;
            min-width: unset;
        }

        &:hover {
            font-size: 0;
            background: none;

            &::after {
                display: block;
                margin-left: auto;
            }
        }
    }

    .list-associations {
        margin-bottom: 60px;
        margin-top: 31px;

        &:empty {
            display: none;
        }

        .association-column:last-child {
            min-width: 200px;
            margin-left: auto;
        }

        .association-item {
            align-items: flex-end;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            padding-bottom: 10px;

            &:not(:first-child) {
                padding-top: 12px;
                border-top: 1px solid $black;
            }
        }

        img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
        }

        .delete-request {
            margin-left: 16px;
            white-space: nowrap;
        }
    }

    .business-name-address {
        display: flex;
        justify-content: space-between;

        .aai-cn {
            width: 100%;

            .form-content {
                display: flex;
            }

            .custom-select {
                width: 100%;
                max-width: 159px;
                margin-right: 30px;
            }

            .form-label {
                margin-bottom: 12px;
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #000;
            }
        }

        .business-name-input {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 445px;
        }

        .selected-item {
            width: 100%;
            max-width: 159px;
            padding-bottom: 7px;
            padding-top: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #939598;
            position: relative;
            z-index: 1;
        }
    }

    .about-yourself {
        margin-top: 40px;
        max-width: unset;

        .form-group {
            width: 100%;
        }
    }

    .business-desc {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $black;
    }

    .desc-textarea {
        width: 100%;
        display: block;
        min-height: 120px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14.0043px;
        line-height: 18px;
        color: #939598;
        padding: 10px 13px 8px 12px;

        &::placeholder {
            color: #939598;
        }
    }

    .email-and-phone,
    .website-and-location,
    .address-and-owned {
        display: flex;
        margin-top: 40px;
    }

    .form-box.form-box-email,
    .form-box.form-box-phone,
    .form-box.form-box-website,
    .form-box.form-box-location,
    .form-box.business-address,
    .form-box.indigenous-owned {
        width: 100%;
        max-width: 444px;
    }

    .form-box {
        input {
            width: 100%;
            padding-right: 10px;
            text-overflow: ellipsis;
        }

        &-email {
            margin-right: 31px;

            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 10px;
            }
        }

        &-website {
            margin-right: 31px;

            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 10px;
            }
        }

        &-location {
            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 10px;
            }
        }

        &-phone {
            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 10px;
            }
        }
    }

    .business-address {
        .label-group {
            margin-bottom: 10px;
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }

        .form-group + .form-group {
            margin-top: 14px;
        }

        .business-address {
            margin-top: 14px;
        }

        .country {
            margin-top: 14px;
        }
    }

    .indigenous-owned {
        margin-left: 30px;

        .label-group {
            margin-bottom: 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: block;
        }

        .form-content {
            display: flex;

            .form-check + .form-check {
                margin-left: 30px;
            }
        }

        .radio-label:hover {
            background-color: #000;
            color: #fff;
        }
    }

    .upload-img {
        cursor: pointer;
    }

    .satellite-item.disabled-form {
        .selected-item,
        &.parsley-error {
            color: #939598 !important;
            pointer-events: none;
            cursor: not-allowed;
        }

        input {
            color: #939598;
            pointer-events: none;

            &::placeholder {
                color: #939598;
            }

            &[placeholder=Email],
            &[placeholder=Phone] {
                max-width: 255px;
                margin-right: 30px;
            }

            &[placeholder=Website] {
                max-width: 350px;
            }

            &[placeholder=Address] {
                max-width: 540px;
                margin-right: 30px;
            }

            &[placeholder=ABN] {
                max-width: 350px;
            }
        }

        .form-box {
            cursor: not-allowed;
        }

        .upload-img {
            pointer-events: none;
        }
    }

    .selected-item {
        padding-top: 0;
        padding-bottom: 7px;
        font-style: normal;
        font-weight: 400;
        font-size: 14.0043px;
        line-height: 18px;
        color: #000;
        height: unset;
    }

    .operation-hours-box {
        margin-top: 42px;

        .label-group {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .list-wrapper {
            display: flex;
            margin-top: 11px;
            margin-bottom: 18px;
            flex-wrap: wrap;
            column-gap: 21px;
        }
    }

    .operation-hours {
        .additional-hour {
            position: relative;
        }

        .remove-operating-hour {
            position: absolute;
            top: 11px;
            right: 0;
            font-size: 0;
            background: none;
            line-height: 1;

            &::after {
                content: "";
                width: 19px;
                height: 22px;
                display: block;
                background: url(/images/delete-icon.svg?92afb5f…) center/100% no-repeat;
            }
        }
    }

    .hour-items {
        display: flex;
        max-width: 444px;
    }

    .hour-item {
        width: 50%;
        max-width: 210px;

        &:last-child {
            margin-left: 25px;
        }

        .selected-item {
            border: solid 1px $black;
            padding: 10px 9px; // ppx dealer profile
            color: $black;

            &.parsley-error {
                border: solid 1px #e74c39;
            }

            &::after {
                right: 9px; // ppx dealer profile
            }

            &.parsley-error {
                border: solid 1px $orange;
            }
        }

        .form-label {
            margin-bottom: 8px;
            margin-top: 12px;
            color: $black;
        }
    }

    .add-operation {
        .btn {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            min-height: unset;
            padding: 0;
            margin-right: 8px;
        }
    }

    .satellite-item {
        position: relative;

        .box-loading {
            position: absolute;
        }

        & + .satellite-item {
            margin-top: 48px;
        }

        a.remove_field {
            top: 6px;
        }

        a.edit_field {
            font-size: 0;
            background: none;
            position: absolute;
            line-height: 1;
            top: 4px;
            right: 35px;

            &::after {
                content: "";
                width: 21px;
                height: 26px;
                display: block;
                background: url(/images/edit-icon.svg) center/100% no-repeat;
            }
        }

        input {
            padding-top: 0;
            padding-bottom: 6.5px;
            max-width: unset;
            color: #000;

            &::placeholder {
                color: #000;
            }
        }

        .business-name {
            input {
                max-width: 448px;;
            }

            input + input {
                margin-top: 18px;
            }
        }
    }

    .satellite-action {
        .btn {
            position: relative;

            &:disabled:hover {
                background-color: #000;
            }
        }

        .box-loading {
            width: 100%;
            background-color: #000;
        }

        .load {
            height: 28px;
            width: 28px;
            background-color: #000;

            &::after {
                height: 24px;
                width: 24px;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                background-color: #000;
            }
        }
    }

    .maximum-notice {
        margin-top: 24px;
    }

    .list-all-artwork {
        margin-top: 40px;

        .checkbox-label::before {
            margin-right: 8px;
        }

        .label-group {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin-bottom: 22px; //ppx dealer profile
            display: block;
        }

        .list-wrapper {
            display: flex;
            flex-wrap: wrap;
            row-gap: 18px;
            column-gap: 73px;

            .form-check {
                width: 50%;
                max-width: 400px;
            }

            .form-check-label {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
                white-space: nowrap;
                padding-right: 20px;
            }
        }
    }

    .social-links {
        margin-top: 56px;
        margin-bottom: 67px;

        .list-social {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .form-group {
            width: 100%;
            max-width: 444px;
        }

        .form-groups {
            display: flex;
            flex-wrap: wrap;
            margin-top: 22px;
            row-gap: 22px;
            column-gap: 31px;

            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 12px;
            }
        }
    }

    .artwork-label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13.9978px;
        line-height: 22px;
        text-transform: uppercase;
        color: $black;
        margin-bottom: 43px;
    }

    .upload-artwork-wrapper {
        .label-upload {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .mb-1 {
            // margin-bottom: -11px; //dealer
        }

        .upload-box {
            margin-bottom: 48px;

            .note {
                font-size: 15.7803px;
                line-height: 21px;
                margin-top: 17px; //dealer profile ppx
                margin-left: 4px; //dealer profile ppx
            }
        }

        .artwork-item {
            margin-bottom: 70px;

            .image {
                padding: 0;
                flex-basis: unset;
                width: 61px;
                max-height: 40px;
                margin-right: 18px;
            }

            img {
                object-fit: cover;
                height: 100%;
            }
        }

    }

    .name-role {
        display: table;
        margin-left: 15px;
        width: 100%;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            display: table-cell;
            width: 300px;
            vertical-align: bottom;
            padding-right: 20px;
        }
    }

    .major-column {
        width: 100%;
    }

    // profile tab (member after approved)
    .artist-profile {
        .membership-application-form {
            .parsley-errors-list {
                display: unset;
            }
        }

        .title-status {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #939598;
        }

        input {
            padding-top: 0;
            padding-bottom: 8px;
            color: #939598;
        }

        .profile-label.personal-label {
            margin-bottom: 38px;
        }

        .profile-image {
            margin-bottom: 40px;
        }

        .form-label {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .about-yourself {
            margin-bottom: 40px;

            .form-label {
                margin-bottom: 16px;
            }

            textarea {
                width: 100%;
                color: #939598;
                border-color: #000;
                font-size: 14.0043px;
                line-height: 18px;
            }

            .icon-question {
                display: none;
            }
        }

        .languages {
            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 12px;
            }

            .form-group {
                margin-bottom: 24px;
            }

            .add-another {
                margin-bottom: 40px;

                button {
                    border-radius: 50%;
                }
            }
        }

        .profile-label.business-label {
            margin-bottom: 40px;
        }

        .b-name-abn,
        .b-email-phone,
        .b-website-location {
            display: flex;
            margin-bottom: 40px;

            .form-box {
                margin-bottom: 0;
            }
        }

        .business_name,
        .abn {
            width: 100%;
            max-width: 444px;

            .form-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                margin-bottom: 12px;
            }
        }

        .business_name {
            margin-right: 31px;
        }

        .social-links {
            margin-bottom: 70px;

            .label-group {
                margin-bottom: 0;
            }

            input {
                padding-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .artwork-label {
            margin-bottom: 41px;
        }

        .associations {
            h3 {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .membership-application-form {
            padding: 0;
            margin: 0;

            .selected-item {
                height: unset;
            }

            > .action {
                margin-top: 56px;
            }
        }

        .search-members {
            display: flex;
            margin-bottom: 31px;

            .association-search {
                cursor: text;
                width: 100%;
                max-width: unset;
                padding-right: 30px;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                padding-bottom: 1px;

                &::after {
                    content: "";
                    background: url(/images/icon-search.svg?f9edefb…) center/100% 100% no-repeat;
                    width: 21px;
                    height: 12px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    transition: 0.5s;
                    -webkit-transition: 0.5s;
                    -moz-transition: 0.5s;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                }

                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: $black;
                }
            }

            .association-search-auto {
                min-width: 132px;
            }
        }

        .associations {
            h3 {
                margin-top: 30px;
                text-transform: uppercase;
                margin-bottom: 43.53px;
            }
        }
    }
}

.artist-profile {
    .profile-image {
        .cabinet {
            display: flex;
            align-items: center;

            figure {
                min-width: 171px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                border: 1px solid #000;
                overflow: hidden;
                position: relative;
                display: inline-block;
                margin-right: 32px;

                img {
                    object-fit: contain;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }

    .business-address {
        input {
            padding-bottom: 7px;
        }

        .label-group {
            margin-bottom: 13px;
        }

        .form-group + .form-group {
            margin-top: 16px;
        }

        .selected-item {
            color: #939598;
        }
    }

    .social-links {
        margin-top: 58px;
    }

    .upload-image-wrapper {
        .read-more {
            font-size: 15.7803px;
            line-height: 21px;

            a {
                font-size: 15.7803px;
                line-height: 21px;
            }
        }

        .upload-label {
            margin-top: 37px;
            margin-bottom: 16px;
        }

        .upload-img {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #675F5F;
            margin-bottom: 16px;
            display: inline-block;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .list-all-artwork {
        .label-group {
            margin-bottom: 23px;
        }
    }

    .social-links {
        .form-groups {
            row-gap: 23px;
        }
    }

    .membership-application-form {
        a.remove_field {
            right: 6px; //ppx artist profile
        }

        .button-action {
            .btn-primary {
                margin-bottom: 0;
            }
        }
    }
}

/*
* membership tab (success)
*/
.member-approved {
    .action {
        margin-top: 43px;

        .btn {
            margin-top: 0;
        }
    }

    .link-action {
        margin-top: 15px;
    }
}

.customer-dashboard-page {
    .membership-approved {

        .member {
            display: flex;
            margin-top: 56px;
        }

        .member-info {
            display: flex;
            width: 100%;
        }

        .form-label {
            display: block;
            margin-bottom: 41px;
            font-size: 13.9978px;
            line-height: 22px;
            font-weight: 700;
        }

        .note-wrapper {
            margin-top: 0;
            max-width: 345px;
        }

        .member-since {
            margin-right: 30px;
            width: 100%;
            max-width: 255px;

            input {
                width: 100%;
                color: $color-focus-input;
                padding-top: 0;
                padding-bottom: 13px;

                &:disabled {
                    color: $color-note2;
                }
            }
        }

        .director-role {
            margin-right: 33px;
            width: 100%;
            max-width: 255px;

            input {
                width: 100%;
                color: $color-focus-input;
                padding-top: 0;
                padding-bottom: 13px;

                &:disabled {
                    color: $color-note2;
                }
            }
        }

        .action {
            margin-top: 60px;
        }

        .link-action {
            justify-content: end;
            margin-top: 0;
        }
    }
}

.confirm-update {
    .submit-form {
        margin-top: 56px;

        > p:not(:last-child),
        .desc p:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    .action {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
    }

    .button-action {
        column-gap: 30px;

        .btn {
            min-width: 254px;
            width: unset;
            justify-content: flex-start;

            &:first-child {
                padding-left: 39px;
            }

            &:last-child {
                margin-right: 0;
                padding-left: 39px;
            }
        }

        .btn-secondary.arrows::after {
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            left: unset;
            right: 3px;
        }

        &.width-auto .btn {
            width: 100%;
        }

        .arrows-right {
            right: 3px;
        }
    }

    .link-action {
        margin-top: 0;
        align-items: flex-end;
    }
}

.row-gap-0,
.action.row-gap-0 {
    row-gap: 0;
}

.panel.membership .action {
    .width-auto {
        .btn {
            min-width: auto;
        }
    }
}

.action {
    gap: 16px;

    .width-min-fixed {
        .arrows-short {
            &:before {
                display: none;
            }
        }
    }

    .width-auto {
        width: 100%;

        + .link-action {
            flex-shrink: 0;
        }

        .max-w-180 {
            max-width: 180px;
        }

        .arrows-short {
            &.arrows:before {
                display: none;
            }
        }
    }

    .button-action {
        &.width-auto .btn {
            width: 100%;
        }
    }
}

.customer-dashboard-page {
    /*
    * membership tab (update infomation)
    */
    .member-update-info {
        .member {
            display: flex;
            margin-top: 56px;
        }

        .member-info {
            display: flex;
            width: 100%;

            input {
                padding-top: 0;
                padding-bottom: 13px;
                color: #939598;
            }
        }

        .form-label {
            display: block;
            margin-bottom: 41px;
            font-style: normal;
            font-weight: 700;
            font-size: 13.9978px;
            line-height: 22px;
            color: $black;
        }

        .note-wrapper {
            margin-top: 0;
            max-width: 345px;

            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #E74C39;
                margin-bottom: 40px;
            }
        }

        .member-since {
            margin-right: 30px;
            width: 100%;
            max-width: 255px;

            input {
                width: 100%;
            }
        }

        .director-role {
            margin-right: 33px;
            width: 100%;
            max-width: 255px;

            input {
                width: 100%;
            }
        }

        .link-action {
            justify-content: end;
            margin-top: 0;
            align-items: flex-end;
        }

        .action {
            margin-top: 33px;
            flex-direction: row;
            justify-content: space-between;
        }

        .button-action {
            a {
                padding-left: 40px;
            }
        }

        .page-title-wrapper {
            padding-bottom: 20px;
        }

    }

    .member-updated {
        .member {
            display: flex;
            margin-top: 56px;
        }

        .member-info {
            display: flex;
            width: 100%;

            input {
                padding-top: 0;
                padding-bottom: 13px;
                color: #939598;
            }
        }

        .form-label {
            display: block;
            margin-bottom: 41px;
            font-style: normal;
            font-weight: 700;
            font-size: 13.9978px;
            line-height: 22px;
            color: $black;
        }

        .note-wrapper {
            margin-top: 0;
            max-width: 345px;

            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #009A81;
                margin-bottom: 40px;
            }
        }

        .member-since {
            margin-right: 30px;
            width: 100%;
            max-width: 255px;

            input {
                width: 100%;
            }
        }

        .director-role {
            margin-right: 33px;
            width: 100%;
            max-width: 255px;

            input {
                width: 100%;
            }
        }

        .link-action {
            margin-top: 0;
        }

        .action {
            margin-top: 53px; //artist info updated
        }
    }

    /*
    * membership tab (declined)
    */
    .btn-secondary {
        background-color: rgba($color: #000000, $alpha: 0.0001);
    }

    .member-declined {
        .action {
            flex-direction: row;
            margin-top: 44px;

            .button-action {
                a {
                    padding-left: 30px;
                    justify-content: start;
                }
            }
        }

        .link-action {
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: 0;
        }

        .page-title-wrapper {
            font-size: 34px;
            line-height: 44px;
            padding-bottom: 16px;
        }

        .note-wrapper {
            margin-top: 32px;
        }
    }

    /*
    * membership tab (suspended)
    */
    .members {
        .member-suspended,
        .member-upgrade {
            .page-title-wrapper {
                font-size: 26px;
                line-height: 32px;
                padding-bottom: 16px;
            }

            .note-wrapper {
                margin-top: 32px;
                max-width: unset;
            }

            .link-action {
                justify-content: end;
                margin-top: 0;
                align-items: flex-end;
            }

            .action {
                margin-top: 60px;
            }
        }

    }
}

/*
* membership tab (start)
*/
.member-start {
    .note-wrapper {
        margin-top: 53px;
    }

    .action {
        flex-direction: row;
        margin-top: 43px;
    }

    .link-action {
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 0;
    }

    .page-title-wrapper {
        padding-bottom: 17px;
    }
}

/*
* Membership tab expired
*/
.customer-dashboard-page {
    .member-expired {
        .note-wrapper {
            margin-top: 32px;
        }

        .action {
            flex-direction: row;
        }
    }
}

/*
* artist resource tab
*/
.panel.resources {
    padding: 56px 95px 98px;
}

.resources-dashboard {
    .page-title-wrapper {
        padding-bottom: 17px;
        border-bottom: none;
    }

    .resources-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #E74C39;
        max-width: 240px;
        margin-bottom: 24px;
    }

    .list-resources {
        display: grid;
        grid-template-columns: minmax(0, 445px) minmax(0, 445px);
        column-gap: 30px;
        row-gap: 41px;
        margin-top: 30px;

        .resources-item {
            .resource-link {
                margin-bottom: 24px;
                padding-right: 46px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .resource-desc {
        margin-bottom: 44px;
        margin-top: 54px;
    }

    .qr-code {
        margin-top: 40px;
    }

    .qr-label {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .qr-desc {
        margin-bottom: 40px;
    }

    .qr-download {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .btn {
            min-width: 160px;
            text-transform: uppercase;
        }
    }

    .action {
        margin-top: 0;
        justify-content: flex-end;

        &.mobile {
            display: none;
        }

        .link-action {
            margin-top: 0;
        }
    }
}

/*
* dealer
*/
//incomplete
.incomplete-membership {
    .page-title-wrapper {
        padding-bottom: 20px;
    }

    .continue-app {
        margin-top: 43px;
    }
}

//in review
.reviewing-membership {
    .page-title-wrapper {
        padding-bottom: 19px;
    }

    .pendding {
        margin-top: 0;
    }
}

.customer-dashboard-page {
    // cancelation
    .cancel-membership {
        .page-title-wrapper {
            padding-bottom: 20px;
        }

        .note-wrapper {
            margin-top: 30px;
        }

        .action {
            flex-direction: row;
            margin-top: 64px;
        }

        .link-action {
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: 0;
        }
    }
}

// renewal-dealer
.renewal-membership {
    .page-title-wrapper {
        padding-bottom: 20px;
    }

    .renewal-row {
        display: grid;
        margin-top: 55px;
        grid-template-columns: minmax(auto, 541px) minmax(auto, 345px);
        column-gap: 31px;
        grid-template-areas:
                "left top-right"
                "left bottom-right"
                "extra-bottom extra-bottom";

        .note-wrapper {
            grid-area: top-right;
            margin-top: 0;
            width: 100%;
            max-width: 345px;
            display: flex;
            flex-direction: column;

            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 40px;

                &.green {
                    color: #009A81;
                }
            }

            .due-date {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $black;
            }
        }
    }

    .member {
        grid-area: left;
        width: 100%;
        max-width: 541px;
        display: grid;
        row-gap: 59px;
        column-gap: 31px;
        grid-template-areas:
            'left right'
            'whole whole';

        label, input {
            display: block;
            width: 100%;
        }

        .form-label {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: $black;
            text-transform: uppercase;
            margin-bottom: 41px;
        }

        input {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #939598;
            padding-top: 0;
            padding-bottom: 13px;
        }
    }

    .member-since {
        grid-area: left;
        width: 100%;
        max-width: 255px;
    }

    .member-renewal-date {
        grid-area: right;
        width: 100%;
        max-width: 255px;
    }

    .director-role {
        grid-area: whole;
        width: 100%;
    }

    .action {
        grid-area: bottom-right;
        flex-direction: row;
        // margin-top: auto; for button 'renew membership' dealer renewal
        &.action.has-button {
            grid-area: extra-bottom;
        }

        .link-action {
            justify-content: flex-end;
            margin-top: 0;
            align-items: flex-end;
        }
    }
}

//membership-tab

.panel.membership {
    .cancel-app.btn {
        text-transform: uppercase;
    }

    .link-action {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .note-wrapper {
        font-size: 16px;
        line-height: 22px;
        display: inline-block;
    }

    .need-more-info {
        .link-action {
            justify-content: space-between;
        }
    }
}

// profile-tab
.panel.profile {
    .language-group + .language-group {
        margin-top: 16px;
    }

    .language-group {
        position: relative;

        a.remove_field {
            // margin-top: 12px;
            // display: block;
            position: absolute;
            right: 0;
            font-size: 0;
            background: none;
            line-height: 1;

            &::after {
                content: "";
                width: 19px;
                height: 22px;
                display: block;
                background: url(/images/delete-icon.svg?92afb5f…) center/100% no-repeat;
            }
        }
    }

    .hide-from-public {
        > label {
            margin-bottom: 24px;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            display: block;
        }

        .form-check {
            margin-top: 0;
            margin-right: 30px;

            .radio-label:hover {
                background-color: #000;
                color: #fff;
            }
        }

        .form-content {
            display: flex;
        }
    }

    .search-input {
        margin-right: 28px;
        width: 100%;
        max-width: 759px;
        position: relative;
        display: flex;

        img {
            position: absolute;
            top: 10px;
            right: -5px;
        }
    }

    .dealer-profile,
    .supporter-profile {

        .business-name-address {
            .business-name {
                width: 100%;
                max-width: 446px;
                margin-right: 30px;

                .form-label {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #000;
                    margin-bottom: 10px;
                }

                input {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .business-name {
            max-width: 539px;

            input {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .satellite-address {
            input {
                padding-right: 3px;
            }
        }

        .postcode-state {
            display: flex;
            width: 100%;
            max-width: 146px;
            margin-right: 31px;

            .selected-item {
                color: $black;
                height: unset;
                padding-bottom: 5px;
                border-bottom: none;
            }

            .custom-select {
                border-bottom: solid 1px #000;
            }
        }

        .upload-artwork-wrapper {
            .upload-box {
                margin-bottom: 43px; //dealer profile ppx
            }
        }
    }
}

.dealer-profile,
.supporter-profile {
    .profile-image {
        .cabinet {
            display: flex;
            align-items: center;

            figure {
                min-width: 171px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                border: 1px solid #000;
                overflow: hidden;
                position: relative;
                display: inline-block;
                margin-right: 32px;

                img {
                    object-fit: contain;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }

    .satellite-item:not(.disabled-form) {
        .custom-select .selected-item {
            color: #000;
        }
    }

    .country-group {
        width: 100%;

        .custom-select {
            select {
                width: 100%;
            }

            .selected-item {
                color: #939598;
            }
        }
    }

    .business-label {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .upload-label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $black;
        margin-bottom: 17px;
    }

    .upload-img {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #675F5F;
        text-decoration: underline;
        display: block;
        margin-bottom: 15px;
    }

    .read-more {
        font-style: normal;
        font-weight: 400;
        font-size: 15.7803px;
        line-height: 21px;
        padding-left: 27px;

        &.icon-question::before {
            left: 0;
        }
    }

    .operation-hours {
        .list-wrapper {
            .checkbox-label {
                padding-left: 1px; //ppx
                color: #000;

                &::before {
                    margin-right: 6px; //ppx
                }
            }
        }
    }

    .upload-artwork.membership-application-form {
        padding: 0;
        margin: 0;

        a.remove_field {
            right: 6px;
        }

        .form-group {
            .form-control {
                margin: 0;
                padding-top: 0;
                padding-bottom: 7px;
                color: $black;
            }
        }
    }

    .satellites-wrapper.membership-application-form {
        padding: 0;
        margin: 0;
    }

    .upload-artwork-wrapper {
        .mb-1 {
            margin-top: 42px;
        }

        .media-description {
            flex-direction: column;
            justify-content: unset;
            margin-top: 12px;
            row-gap: 16px;
            display: flex;

            .divider {
                justify-content: space-between;
            }

            .title {
                order: 1;
                // flex-basis: calc(50% - 15px);
                width: 100%;
                max-width: 445px;
                padding-right: 0;
                margin-right: 29px;
                flex-basis: unset;
            }

            .name {
                order: 2;
                max-width: 445px;
                width: 100%;
                // flex-basis: calc(50% - 15px);
            }

            .photo {
                order: 3;
                // flex-basis: calc(100% - 15px);
                width: 100%;
                max-width: 350px;
                margin-right: 30px;
            }

            .copy-right {
                order: 4;
                // flex-basis: calc(100% - 30px);
                width: 100%;
                max-width: 350px;
                margin-right: 30px;
            }

            .year {
                order: 5;
                max-width: 157px;
                width: 100%;
                // flex-basis: calc(100% - 15px);
            }
        }

        .artwork-item {
            margin-bottom: 70px;

            .image {
                min-height: 40px;
            }

            img {
                width: auto;
                height: 100%;
                object-fit: cover;
            }
        }

        .note {
            font-weight: 400;
            font-size: 15.7803px;
            line-height: 21px;
        }
    }

    .satellite-label {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 42px;
    }

    .satellite-logo {
        margin-bottom: 22px;

        .cabinet {
            display: flex;
        }

        img {
            border: solid 1px $black;
            margin-right: 31px;
            border-radius: 50%;
        }
    }

    .add-another-satellite {
        font-size: 14px;

        #add-another-satellite {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            min-height: unset;
            padding: 0;
            margin-right: 10px;
        }
    }

    .satellite-item {
        input::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $black;
        }

        .business-name {
            margin-bottom: 12px;

            input + input {
                margin-top: 12px;
            }
        }

        #add-another-social-link {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            min-height: unset;
            padding: 0;
            margin-right: 10px;
        }

        .add-another-social-link {
            label {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }

        .social-address {
            max-width: 444px;
            margin-bottom: 18px;
            width: calc(50% - 15px);

            input {
                display: block;

                &::placeholder {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }

    .social-group {
        display: flex;
        width: 100%;

        .social-address {
            width: 100%;

            & + .social-address {
                margin-left: 30px;
            }
        }
    }

    .upload-image-wrapper {
        .form-label {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin-top: 5px;
            margin-bottom: 9px;
            display: block;

            &.upload-img {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #675F5F;
                margin: 0;
            }
        }
    }

    .satellite-info {
        display: flex;
        margin-bottom: 13px;

        input {
            display: block;
            width: 100%;

            &[placeholder=Email] {
                margin-right: 23px;
            }

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .satellite-info-2 {
        display: flex;
        margin-bottom: 10px;

        input {
            display: block;
            width: 100%;

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }

            &[placeholder=Website] {
                margin-right: 23px;
            }
        }
    }

    .satellite-address {
        display: flex;
        margin-bottom: 15px;

        input {
            display: block;
            width: 100%;
            padding-bottom: 5px;

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #000;
            }

            &[placeholder=Address] {
                width: calc((209 / 919) * 100%);
                margin-right: 24px;
            }

            &[placeholder="Apt."] {
                width: calc((64 / 919) * 100%);
                margin-right: 24px;
            }

            &[placeholder=Suburb] {
                width: calc((127 / 919) * 100%);
                margin-right: 23px;
            }

            &[placeholder=Postcode] {
                width: calc((70 / 919) * 100%);
                margin-right: 24px;
            }
        }

        .state {
            width: calc((168 / 919) * 100%);
            height: unset;
            display: inline-block;

            .custom-select {
                width: 100%;

                select {
                    width: 100%;
                }

                .selected-item {
                    color: #000;
                }
            }
        }

        .country {
            width: calc((162 / 919) * 100%);
            height: unset;
            margin-right: 24px;
            display: inline-block;

            .custom-select {
                width: 100%;

                select {
                    width: 100%;
                }
            }
        }

    }

    .associations {
        h3 {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            color: $black;
            margin-top: 70px;
            margin-bottom: 42px;
        }
    }

    .search-members {
        display: flex;

        .association-search {
            display: block;
            padding-bottom: 0;
            padding-right: 22px;
            width: 100%;
            color: #000;
            font-size: 18px;
            line-height: 24px;
            cursor: text;

            &::placeholder {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #000;
            }
        }

        .search-input {
            margin-right: 28px;
            width: 100%;
            max-width: 759px;
            position: relative;
            display: flex;

            img {
                position: absolute;
                top: 10px;
                right: 0;
            }
        }

        button {
            min-width: 133px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 13.9978px;
            line-height: 22px;
            padding-left: 12px;
        }
    }

    .keep-profile-hidden {
        .label-group {
            display: block;
            margin-top: 61px;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .yes-no {
            display: flex;
            margin-top: 24px;

            .form-check:first-child {
                margin-right: 30px;
            }
        }
    }

    .action {
        margin-top: 56px;

        button {
            min-width: 160px;
            text-transform: uppercase;
        }
    }

    .membership-application-form {
        .parsley-errors-list {
            display: unset;
        }
    }
}

/*
* Supporter
*/

.panel.profile {
    .supporter-profile {
        .social-links {
            margin-top: 40px;
        }
    }
}

.supporter-profile {
    .membership-application-form {
        .parsley-errors-list {
            display: unset;
        }
    }

    .language-group {
        margin-top: 40px;
        margin-left: 4px;
        max-width: 444px;

        .form-label {
            margin-bottom: 10px;
            color: $black;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .add-another {
        button {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 0;
            margin-top: 16px;
            margin-left: 3px;
            margin-right: 8px;
            min-height: unset;
        }

        .label-add {
            font-weight: 400;
            font-size: 14.0043px;
            line-height: 18px;
        }
    }
}

/*
* question and links section
*/
.customer-dashboard-page ~ .g-content__question-and-link {
    .question-and-link-row {
        padding-top: 86px;
        padding-bottom: 68px;
        border-top: none;

        h3 {
            margin-bottom: 37px;
        }

        .g-content_question-item:nth-child(2) {
            h3 {
                color: #008264;
            }
        }

        .g-content_question-item:nth-child(3) {
            h3 {
                color: $blue;
            }
        }
    }
}

/*
* Member update information
*/
.update-info-question {
    .form-content {
        .form-check {
            margin-top: 0;
        }
    }

    .form-box-valid {
        .title {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 12px;
        }

        .desc {
            font-size: 14px;
            line-height: 18px;
        }

        .parsley-errors-list {
            display: block !important;
        }

        .parsley-required,
        .form-check.big-size.parsley-error + .parsley-errors-list {
            margin-top: 0;
            margin-bottom: 8px;
        }
    }

    .label-group {
        margin-bottom: 24px;
    }

    .action {
        display: flex;
        justify-content: space-between;
    }

    .link-action {
        align-items: flex-end;
        margin-top: 0;
    }

    .button-action {
        .btn {
            min-width: 160px;
        }
    }

    .radio-label:hover {
        background-color: #000;
        color: #fff;
    }
}

.update-info-profile {
    .form-desc {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
    }

    .form-group {
        .form-control {
            padding: 0;
            height: unset;
            font-size: 14.0043px;
            line-height: 18px;
            margin-bottom: 16px;
            padding-bottom: 7px;
            color: #000;
        }

        .custom-select {
            .selected-item {
                padding: 0;
                height: unset;
                font-size: 14.0043px;
                line-height: 18px;
                margin-bottom: 16px;
                padding-bottom: 7px;
                color: #000;
            }
        }
    }

    .contact-method {
        margin-bottom: 60px;
    }

    .action {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button-action {
        .btn {
            min-width: 160px;
        }
    }

    .link-action {
        margin-top: 0;
        align-items: flex-end;
    }
}

/*
* Member canceled
*/
.member-canceled {
    .page-title-wrapper {
        padding-bottom: 16px;
    }

    .note-wrapper {
        margin-top: 30px;
    }

    .link-action {
        justify-content: flex-end;
        margin-top: 0;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .customer-dashboard-page {
        .g-content__question-and-link {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

@media screen and (max-width: 1110px) {
    .customer-dashboard-page {

        .g-block-editor {
            margin-top: 59px;
            // margin-bottom: 48px; fix too large padding
        }

        .member-update-info,
        .member-updated {
            .member {
                flex-direction: column;
                row-gap: 30px;
            }

            .note-wrapper {
                margin-top: 30px;
            }

            .member-since {
                width: 100%;
                max-width: unset;

                input {
                    width: 100%;
                }
            }

            .director-role {
                width: 100%;
                margin-right: 0;
                max-width: unset;

                input {
                    width: 100%;
                }
            }
        }

        .membership-approved {
            .member {
                flex-direction: column;
            }

            .note-wrapper {
                margin-top: 30px;
                max-width: unset;
            }

            .member-since {
                width: 100%;
                max-width: unset;

                input {
                    width: 100%;
                }
            }

            .director-role {
                width: 100%;
                margin-right: 0;
                max-width: unset;

                input {
                    width: 100%;
                }
            }

            .link-action {
                justify-content: flex-start;
            }
        }
    }
    .greeting-section {
        grid-template-columns: auto;
    }


    .panel.profile {
        .artist-profile {
            .business_name,
            .form-box-email,
            .form-box-website,
            .form-box-phone,
            .form-box-location {
                margin-right: 0;
                max-width: unset;

                input {
                    width: 100%;
                    max-width: unset;
                }
            }

            .b-name-abn,
            .b-email-phone,
            .b-website-location {
                margin-bottom: 40px;
                flex-direction: column;
            }

            .b-name-abn {
                .business_name {
                    margin-bottom: 40px;
                }
            }

            .abn {
                max-width: unset;

                input {
                    width: 100%;
                    max-width: unset;
                }
            }

            .upload-artwork-wrapper {
                .upload-box {
                    margin-bottom: 43px;
                }
            }

            .media-description {
                margin-top: 5px;

                .title,
                .year {
                    margin-top: 0;
                }
            }
        }

        .list-all-artwork {
            .list-wrapper {
                .form-check {
                    width: 100%;
                    max-width: unset;
                }
            }
        }

        .membership-application-form .form-group .form-control {
            color: #939598;
        }
    }
    .renewal-membership {
        .renewal-row {
            grid-template-columns: auto;
            grid-template-areas:
            "top-right"
            "left"
            "bottom-right"
            "extra-bottom";

            .note-wrapper {
                max-width: unset;
                margin-bottom: 40px;
            }
        }

        .member {
            max-width: unset;

            &-since {
                max-width: unset;
            }

            &-renewal-date {
                max-width: unset;
            }
        }

        &.renew-due {
            .action {
                .link-action {
                    justify-content: flex-end;
                    margin-top: 0;
                    align-items: flex-end;
                }
            }
        }

        .action {
            margin-top: 40px;

            .link-action {
                justify-content: flex-start;
                align-items: flex-start;

                .icon-question {
                    margin-left: 0;
                }
            }
        }
    }
    .resources-dashboard {
        .list-resources {
            grid-template-columns: auto;

            .resources-item {
                width: unset;
            }
        }

        .resources-title {
            max-width: unset;
        }
    }
}

@media screen and (max-width: 992px) {
    .confirm-update {
        .action {
            flex-direction: column;
            row-gap: 24px;
        }

        .button-action {
            .btn {
                min-width: unset;
                max-width: unset;
                justify-content: center;
                width: 100%;
            }

            .btn-primary.arrows::after,
            .btn-secondary.arrows::after {
                right: 5px;
            }
        }

        .link-action {
            justify-content: flex-start;
        }
    }
    .panel.membership {
        padding: 40px;
    }
    .panel.welcome {
        .g-intro__image {
            img {
                max-width: 100%;
            }
        }
    }
    .email-and-phone {
        flex-direction: column;
    }
    .website-and-location {
        flex-direction: column;
    }
    .panel.resources {
        padding: 20px;
        padding-top: 13px;
        padding-bottom: 48px;
    }
    .panel.profile {
        padding: 20px;
        padding-bottom: 48px;

        .form-box.form-box-phone {
            margin-top: 40px;
        }

        .form-box.form-box-location {
            margin-top: 40px;
        }

        .form-box.form-box-email,
        .form-box.form-box-phone,
        .form-box.form-box-website,
        .form-box.form-box-location,
        .form-box.business-address,
        .form-box.indigenous-owned {
            width: 100%;
            max-width: unset;

            .custom-select,
            .custom-select-state {
                .selected-item {
                    color: #939598;
                }
            }
        }
    }
    .supporter-profile {
        .language-group {
            max-width: unset;
        }
    }
    .dealer-profile,
    .supporter-profile {
        .action {
            margin-top: 48px;
        }
    }
}

.profile-image.parsley-error figure {
    border: 2px solid $orange !important;
}

@media screen and (max-width: 767px) {
    .tabbedPanels {
        margin-top: 0;
    }
    .g-intro__action {
        padding: 24px 22px;
        min-height: unset;
    }
    .greeting-section.mobile {
        margin-bottom: 40px; // reduce space dealer dashboard
    }
    .customer-dashboard-page {
        margin-top: 8px;

        .link-to-contact {
            display: block;
            margin-top: 24px;
            margin-bottom: 40px;

            .icon-question {
                justify-content: flex-start;
            }
        }

        .become-member {
            margin: 0 -20px;
        }

        .g-intro__action {
            h3 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .secondary-container {
            max-width: 100%;
            padding: 0 12px;
        }

        .g-form {
            .g-intro__shortdesc {
                max-width: unset;
            }
        }

        .member-declined {
            .btn {
                margin-top: 0;
            }

            .action {
                flex-direction: column;

                .button-action {
                    a {
                        justify-content: center;
                    }
                }
            }

            .link-action {
                margin-top: 25px;
                justify-content: start;
                align-items: flex-start;
            }
        }

        .member-expired {
            .action {
                flex-direction: column;
            }
        }

        .greeting-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            .g-intro__shortdesc {
                font-size: 18px;
                line-height: 24px;
            }

            .link-to-contact {
                margin-bottom: 44px;
            }
        }

        .steps {
            margin-top: 52px;
        }

        .action.flex-row {
            flex-direction: column;

            .link-action {
                margin-top: 24px;
            }
        }

        .button-action {
            width: 100%;
        }

        .action {
            .btn {
                width: 100%;
            }
        }

        .ml-0 {
            margin-left: 0;
        }
    }

    .confirm-update {
        .submit-form {
            margin-top: 40px;
        }
    }

    .panel {
        h1 {
            font-size: 28px;
            line-height: 38px;
        }
    }
    .panelContainer {
        background-color: unset;
    }
    .tabs {
        display: none;
        flex-direction: column;
        row-gap: 12px;

        li {
            width: 100%;
        }

        a {
            border: none;
            background-color: #ECECEC;

            &.active,
            &.disabled {
                border: none;
            }
        }
    }
    .opener {
        display: flex;
        align-items: center;
        min-height: 78px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 38px;
        color: #0A0A0B;
        background-color: #ECECEC;

        &:not(.opener:first-child) {
            margin-top: 12px;
        }

        svg {
            margin-left: 21px;
            margin-right: 17.45px; //resource ppx
        }

        &.disabled {
            svg {
                path {
                    stroke: #CCCCCC;
                }
            }
        }
    }
    .panel,
    .panel.welcome {
        padding: 20px;
        padding-bottom: 40px;
        background-color: #ECECEC;
    }
    .panel.welcome {
        flex-direction: column;

        img {
            margin-top: 40px;
        }

        .video--iframe {
            img {
                margin-top: 0;
            }
        }

        .article-video {
            margin-left: 0;
        }

        .g-intro__content {
            max-width: unset;
            margin-right: 0;
        }

        .g-intro__image {
            max-width: unset;
            margin-top: 0;
        }
    }
    .panel.membership {
        padding-bottom: 44px;
        padding-right: 20px;
        padding-left: 20px;

        .member-updated {
            .action {
                margin-top: 0; //artist membership mobile
            }

            .note-wrapper {
                margin-top: 0; //artist membership mobile
            }
        }

        .member-approved {
            .button-action {
                flex-direction: column;
                row-gap: 24px;

                .btn-primary {
                    justify-content: center;
                }
            }
        }
    }
    .link-to-contact {
        display: none;

        .contact-us {
            > a {
                margin-bottom: 0;
            }
        }
    }
    .panel.account {

        .account-dashboard {
            padding: 0;

            .submit-form {
                > .form-group:not(:first-of-type) {
                    margin-top: 48px;
                }
            }

            .action {
                margin-top: 47px;
            }
        }

        .artist-account {
            .contact-method {
                .form-label {
                    margin-top: 56px;
                }
            }
        }

        .form-label {
            margin-top: 0;
            margin-bottom: 0;
        }

        .online-trading {
            .input-box {
                max-width: unset;

                input {
                    margin-top: 32px;
                    padding-bottom: 7px;
                }

                .parsley-errors-list {
                    top: 13px;
                }
            }

            .input-box:last-child {
                grid-area: 2/1;
                margin-left: 0;
            }
        }

        .postal-address {
            margin-top: 48px;

            input,
            .state, .country {
                max-width: unset;
            }

            .apartment,
            .suburb,
            .post-code,
            .state {
                margin-top: 15px;
            }
        }

        .name-art {
            max-width: unset;
            margin-top: 56px;

            input {
                max-width: unset;
            }
        }

        .contact-method {
            max-width: unset;
        }

    }
    .panel.membership {

        .member-update-info,
        .member-updated {
            .btn {
                margin-top: 0;
                justify-content: center;
            }

            .action {
                flex-direction: column;
            }

            .link-action {
                margin-top: 25px;
                justify-content: start;
                align-items: flex-start;
            }
        }

        .member-suspended {
            .btn {
                margin-top: 0;
                justify-content: center;
            }

            .action {
                flex-direction: column;
                margin-top: 24px;
            }

            .link-action {
                margin-top: 0;
                justify-content: start;
                align-items: flex-start;
            }
        }

        .member-start {

            .btn {
                margin-top: 0;
                justify-content: center;
            }

            .action {
                flex-direction: column;
            }

            .link-action {
                margin-top: 25px;
                justify-content: start;
                align-items: flex-start;
            }
        }
    }
    .panel.profile {
        padding: 20px;
        padding-bottom: 48px;

        .profile-image {
            margin-bottom: 39px;
        }

        .business-name-address {
            flex-direction: column;
        }

        .artist-profile {
            .upload-image-wrapper {
                .upload-label {
                    margin-top: 3px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 12px;
                }
            }

            .profile-label.personal-label {
                margin-bottom: 40px;

            }

            .icon-question {
                display: none;
            }

            .about-yourself {
                .form-label {
                    margin-bottom: 12px;
                }
            }

            .business-address {
                max-width: unset;
            }

            .social-links {
                max-width: unset;
                margin-bottom: 56px;

                .form-group {
                    max-width: unset;

                    &-facebook,
                    &-tiktok {
                        margin-right: 0;
                    }
                }
            }

            .membership-application-form {
                > .action {
                    margin-top: 48px;
                }
            }
        }

        .address-and-owned {
            flex-direction: column;
        }

        .form-box.indigenous-owned {
            margin-left: 0;
            margin-top: 40px;
        }

        .operation-hours-box {
            margin-top: 40px;

            .list-wrapper {
                margin-top: 20px;
                row-gap: 14px;
            }
        }

        .dealer-profile,
        .supporter-profile {
            .business-name-address {
                .business-name {
                    width: 100%;
                    max-width: unset;
                }

                .business-name-input {
                    max-width: unset;
                }
            }

            .satellite-item {
                .selected-item {
                    margin-top: 0;
                }

                .business-name {
                    max-width: unset;

                    input::placeholder {
                        font-size: 14.0043px;
                        line-height: 18px;
                    }
                }
            }

            .satellite-address {
                input {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 18px;

                    &[placeholder=Postcode] {
                        width: 100%;
                        margin-bottom: 18px;
                    }

                    &[placeholder=ABN] {
                        margin-bottom: 0;
                    }
                }
            }

            .postcode-state {
                max-width: unset;
                margin-right: 0;
                margin-bottom: 18px;
            }
        }

        .social-links {
            .form-group-facebook,
            .form-group-tiktok {
                margin-right: 0;
                max-width: unset;
            }

            .form-group {
                max-width: unset;
            }
        }

        .name-role {
            flex-direction: column-reverse;
            display: flex;

            .role {
                font-size: 14.0043px;
                line-height: 18px;
                color: #939598;
                width: 100%;
            }

            .name {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .list-associations {
            .association-column:last-child {
                min-width: unset;
            }
        }

        .satellite-item.disabled-form {
            input[placeholder=Email],
            input[placeholder=Phone] {
                max-width: unset;
                margin-right: 0;
            }

            input[placeholder=Website] {
                max-width: unset;
            }

            input[placeholder=ABN] {
                max-width: unset;
                margin-top: 18px;
            }

            input[placeholder=Address] {
                margin-right: 0;
                margin-top: 18px;
            }
        }
    }
    .dealer-profile,
    .supporter-profile,
    .artist-profile {
        .profile-image {
            .read-more {
                display: none;
            }

            .cabinet {
                figure {
                    min-width: 62px;
                }
            }
        }

        .upload-label {
            margin-top: 3px;
            margin-bottom: 12px;
        }

        .business-name-address {
            .business-name {
                margin-bottom: 40px;
            }
        }

        .social-group {
            flex-direction: column;

            .social-address + .social-address {
                margin-left: 0;
            }
        }

        .satellite-info {
            flex-direction: column;
            margin-bottom: 0;

            input {
                max-width: unset;

                &[placeholder=Website] {
                    max-width: unset;
                }

                &[placeholder=Email] {
                    max-width: unset;
                    margin-bottom: 18px;
                    margin-right: 0;
                }

                &[placeholder=Phone] {
                    margin-right: 0;
                    margin-bottom: 18px;
                }
            }
        }

        .satellite-info-2 {
            flex-direction: column;
            margin-bottom: 0;

            input {
                max-width: unset;

                &[placeholder=Website] {
                    margin-bottom: 18px;
                }

                &[placeholder=ABN] {
                    margin-bottom: 18px;
                }
            }
        }

        .satellite-address {
            margin-bottom: 18px;
            flex-direction: column;

            .custom-select {
                margin-right: 0;
            }

            .country {
                width: 100%;
                margin-right: 0;
                margin-bottom: 18px;
            }

            .state {
                width: 100%;
            }
        }

        .satellite-item {
            .social-address {
                width: 100%;
            }

            .business-name {
                margin-bottom: 18px;
            }
        }

        .upload-artwork-wrapper {
            .mb-1 {
                margin-bottom: 0;
            }

            .media-description {
                .title,
                .name,
                .year,
                .photo,
                .copy-right {
                    max-width: unset;
                    margin-right: 0;
                }

                .divider {
                    flex-direction: column;
                    row-gap: 16px;
                }
            }
        }

        .country, .state {
            .custom-select {
                display: flex;
                flex-direction: column-reverse;
            }

            .selected-item {
                position: relative;
            }
        }
    }
    .cancel-membership {
        .action {
            flex-direction: column;
        }

        .link-action {
            justify-content: flex-start;
        }
    }
    .panel.membership {
        .cancel-membership {
            .action {
                margin-top: 0;
                flex-direction: column;
                row-gap: 24px;

            }

            .link-action {
                justify-content: flex-start;
            }

            .btn-primary {
                justify-content: center;
            }
        }
    }
    .resources-dashboard {
        .resource-desc {
            margin-bottom: 38px;
        }

        .page-title-wrapper {
            padding-bottom: 22px;
            margin-bottom: 40px;
        }

        .list-resources {
            flex-direction: column;
            row-gap: unset;

            .resources-item {
                margin-top: 24px;
                display: none;
                padding-left: 29px;

                .resource-link {
                    padding-right: 0;
                    margin-bottom: 47px;

                    &:last-child {
                        margin-bottom: 23px;
                    }
                }
            }

            .arrows-long::after {
                display: inline-block;
                margin-left: 0;
                bottom: -25px;
            }

            .arrows-long {
                display: flex;
            }
        }

        .resources-title {
            span {
                width: calc(100% - 28.45px);
            }
        }

        .resources-title.mobile {
            margin-bottom: 0;
            margin-top: 40px;
            display: flex;
            align-items: flex-start;
            min-height: unset;
            max-width: unset;

            &:first-child {
                margin-top: 0;
            }

            svg {
                margin-left: 0;
                margin-top: 3px;
            }
        }

        .qr-togle {
            display: none;
            padding-left: 29px;
        }

        .qr-label {
            min-height: unset;
            color: $orange;
            margin-bottom: 24px;

            svg {
                margin-left: 0;
                margin-top: 3px;
            }
        }

        .qr-desc {
            margin-bottom: 42px;
        }

        .qr-download {
            flex-direction: column;
        }

        .action.desktop {
            display: none;
        }

        .action.mobile {
            display: flex;
        }

        .contact-us {
            margin-top: 72px;
        }
    }
    .renewal-membership {
        .action {
            flex-direction: column;
            row-gap: 24px;
            margin-top: 40px;
        }

        &.renew-due {
            .action {
                .link-action {
                    justify-content: flex-start
                }
            }
        }
    }
    .customer-dashboard-page ~ .g-content__question-and-link {
        .question-and-link-row {
            padding-top: 40px;

            h3 {
                margin-bottom: 24px;
            }
        }
    }
    .update-info-question {
        .action {
            flex-direction: column;
        }

        .link-action {
            margin-top: 24px;
        }
    }

    .membership-application-form {
        .button-action {
            width: 100%;
        }
    }

    .update-info-profile .link-action {
        margin-top: 24px;
    }
}

@media screen and (max-width: 550px) {
    .jconfirm {
        .jconfirm-box {
            .jconfirm-buttons {
                flex-direction: column;
                gap: 20px;
            }
        }
    }

    .jconfirm .jconfirm-box .jconfirm-buttons > button {
        min-width: unset;
    }
    .jconfirm .jconfirm-box .jconfirm-buttons button.btn {
        margin: 0;
    }
    .jc-bs3-container {
        padding: 0 21px;
    }
    .jconfirm.jconfirm-light .jconfirm-box .jconfirm-buttons button.btn-default {
        margin-right: 16px;
    }
    .progressbar {
        margin-top: 40px;

        li {
            &::after {
                height: 19px;
                width: 19px;
                font-size: 10px;
                line-height: 18px;
                border-width: 1.5px;
            }

            &::before {
                height: 1px;
            }
        }

        .step-last {
            max-width: 95px;
        }
    }
    .note-wrapper {
        margin-top: 40px;
    }
    .panel.profile {
        .search-members {
            margin-bottom: 38px;
            flex-direction: column;

            .association-search {
                margin-right: 0;
                margin-bottom: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-bottom: 7px;
                font-size: 16px;
                line-height: 22px;

                &::placeholder {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .association-search-auto {
                width: 100%;
                justify-content: center;
            }

            .list-filter {
                top: 25px;
            }
        }

        .artist-profile {
            .associations {
                h3 {
                    margin-top: 16px;
                    margin-bottom: 16px;
                }
            }

            .search-members {
                margin-bottom: 38px;
                flex-direction: column;

                .association-search {
                    margin-right: 0;
                    margin-bottom: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-bottom: 7px;
                }

                .association-search-auto {
                    width: 100%;
                    justify-content: center;
                }

                .search-input {
                    img {
                        top: 0;
                    }
                }
            }

            .artwork-item {
                margin-bottom: 60px;
            }
        }

        .supporter-profile {
            .search-members {
                .search-input {
                    img {
                        top: 0;
                    }
                }
            }
        }

        .dealer-profile {
            .artwork-item {
                margin-bottom: 60px;
            }
        }
    }
    .renewal-membership {
        .renewal-row {
            margin-top: 40px;
        }

        .member {
            row-gap: 24px;
            grid-template-areas:
                "left"
                "right"
                "whole";

            .form-label {
                margin-bottom: 16px;
            }
        }
    }
    .supporter-profile,
    .dealer-profile {
        .action {
            button {
                width: 100%;
            }
        }

        .search-members {
            .search-input {
                img {
                    top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .panel.membership {
        .incomplete-membership {
            .link-action {
                align-items: unset;
                row-gap: 24px;
            }
        }

        .need-more-infor {
            .link-action {
                align-items: flex-start;
            }

            .cancel-app {
                margin-bottom: 24px;
            }
        }
    }
    .greeting-section {
        .contact-us {
            margin-top: 0;
        }
    }
    .link-action {
        flex-direction: column;
    }
    .cancel-app {
        margin-bottom: 56px;
    }
    .contact-us {
        margin-top: 0;
        justify-content: flex-start;
    }
    .panel.welcome {
        .g-intro__shortdesc {
            margin-top: 24px;
        }
    }
    .panel.membership {
        padding-top: 13px;
    }
    .membership-dashboard {
        > .action {
            .btn.arrows-short {
                margin-top: 0;
                justify-content: center;
            }
        }

        .button-action {
            width: 100%;
        }
    }
    .membership-approved {
        .contact-us {
            margin-top: 0;
        }

        .member-info {
            flex-direction: column;
        }

        .form-label {
            margin-bottom: 32px;
        }

        .director-role {
            margin-top: 48px;
        }
    }
    .member-update-info,
    .member-updated {
        .contact-us {
            margin-top: 0;
        }

        .member-info {
            flex-direction: column;
        }

        .form-label {
            margin-bottom: 32px;
        }

        .director-role {
            margin-top: 48px;
        }
    }
    .member-declined {
        .contact-us {
            margin-top: 0;
        }

        .member-info {
            flex-direction: column;
        }

        .form-label {
            margin-bottom: 32px;
        }

        .director-role {
            margin-top: 48px;
        }
    }
    .member-suspended {

        .contact-us {
            margin-top: 0;
        }

        .member-info {
            flex-direction: column;
        }

        .form-label {
            margin-bottom: 32px;
        }

        .director-role {
            margin-top: 48px;
        }

    }
    .member-start {
        .contact-us {
            margin-top: 0;
        }

        .member-info {
            flex-direction: column;
        }

        .form-label {
            margin-bottom: 32px;
        }

        .director-role {
            margin-top: 48px;
        }
    }
    .list-filter {
        .association-item {
            flex-direction: column;
            align-items: flex-start;
        }

        .major-column {
            margin-bottom: 10px;
        }
    }
    .panel.profile {
        .artist-profile {
            .list-wrapper {
                flex-direction: column;

                .form-check {
                    width: 100%;
                    max-width: unset;

                    &-label {
                        white-space: unset;
                        padding-right: 0;
                    }
                }
            }
        }

        .business-name-address {
            .aai-cn {
                .custom-select {
                    max-width: 66px;
                    margin-right: 16px;
                }
            }

            .selected-item {
                max-width: 66px;
            }
        }

        .desc-textarea {
            min-height: 170px;
        }

        .social-links {
            margin-top: 40px;
        }

        .name-role {
            flex-direction: column-reverse;

            .role {
                font-size: 14.0043px;
                line-height: 18px;
                color: #939598;
                width: 100%;
            }

            .name {
                width: 100%;
            }
        }

        .association-column {
            .approve-request {
                font-size: 0;
                background: none;

                &::after {
                    content: "";
                    width: 25px;
                    height: 25px;
                    background: url("../../public/images/approve_icon.svg") center/100% no-repeat;
                    min-width: unset;
                    display: block;
                }
            }

            .delete-request {
                font-size: 0;
                background: none;
                margin-left: 10px;

                &::after {
                    content: "";
                    width: 25px;
                    height: 25px;
                    background: url("../../public/images/remove_icon.svg") center/100% no-repeat;
                    min-width: unset;
                    display: block;
                }
            }
        }
    }
    .dealer-profile,
    .supporter-profile {
        .upload-image-wrapper {
            .form-label {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .satellite-info {
            flex-direction: column;
        }
    }
    .cancel-membership {
        .action {
            .btn.arrows-short {
                margin-top: 40px;
            }
        }

        .link-action {
            margin-top: 0;
        }
    }
    // pike
    .customer-dashboard-page {
        .greeting-section {
            margin-bottom: 40px;

            .page-title-wrapper {
                padding-bottom: 12px;
            }

            .g-intro__shortdesc {
                margin-top: 24px;

                p {
                    margin-bottom: 0.5px;
                }
            }
        }

        .icon-question {
            margin-left: unset;
        }

        .membership-dashboard {
            h2.page-title-wrapper {
                padding-bottom: 22px;
            }

            .note-wrapper {
                margin-top: 40px;
            }
        }

        .renewal-membership {
            .note-wrapper {
                margin-top: 0;
            }
        }

        .g-intro__action {
            min-height: 196px;

            h3 {
                margin-bottom: 14px;
            }
        }

        .member-suspended {
            .page-title-wrapper {
                font-size: 28px;
                line-height: 38px;
            }
        }

        .membership-approved {
            .member {
                margin-top: 24px;
            }

            .form-label {
                margin-bottom: 16px;
            }

            .action {
                margin-top: 24px;
            }

            .director-role {
                margin-top: 24px;
            }
        }
    }
    .renewal-membership {
        &.renew-due {
            .action {
                .link-action {
                    align-items: flex-start;
                }
            }
        }
    }
}
