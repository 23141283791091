.supporter-page {
    .g-intro {
        .g-intro__content {
            .g-intro__title {
                color: #008065;
            }
        }
        .g-intro__buttons .btn{
            display: block;
        }
    }
    .section.g-content__question-and-link {
        background-color: #ececec;
        margin-bottom: 20px;
        .question-and-link-row {
            border-top: none;
        }
    }
    .video-section {
        .article-headings {
            max-width: 341px;
        }
        .flex-box {
            border-bottom: none;
            .title {
                color: $blue;
            }
        }
        .article-video .video--iframe {
            aspect-ratio: 16/9;
        }
    }
    
    .g-member {
        margin-bottom: 20px;
        .g-member__title {
            color: #009a81;
        }
    }
    
    .section-7 {
        .title.desktop {
            color: #004b87;
            margin-bottom: 20px;
            width: 78%;
        }
        .f-body {
            p:not(:last-child) {
                // margin-bottom: 8px;
                strong {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
    .g-frequently-question {
        background-color: #008065;
        margin-bottom: 49px;
        .frequently-question-title,
        .faq-title {
            color: white;
        }
        .faq-content .faq-item {
            .faq-title {
                padding-bottom: 48px;
            }
            .faq-button .btn {
                background-color: black;
                color: white;
                &:hover {
                    background-color: #333;
                }
            }
        }
    }
    .o-section-block.image-Text .image-caption {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        font-style: italic;
        color: #939598;
    }
    .section.resources {
        .list-resources .resources-item .title-wrapper {
            color: #008065;
        }
    }
}
@media only screen and (max-width: 1180px) {
    .supporter-page {
        .g-articles.video-section {
            margin-bottom: 0;
        }

        .section-7 .title.desktop {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 768px) {
    .supporter-page {
        .g-intro {
            margin-bottom: 37px;
        }
        .section-3,
        .section-4,
        .section-7 {
            .o-section-block-inner {
                display: flex;
            }
        }
        .section-3 {
            margin-bottom: 49px;
            .title.desktop {
                font-size: 42px;
                line-height: 51px;
                letter-spacing: 0.2px;
                padding-top: 14px;
                margin-bottom: 30px;
            }
        }
        .section-4 {
            padding-bottom: 10px;
            margin-bottom: 30px;
            .o-section-block-inner {
                display: flex;
            }
            .g-section__image--mobile .g-section__image {
                display: none;
            }
            .g-section__description {
                width: 47.225%;
                margin-right: 60px;
                .title {
                    padding-top: 12px;
                    padding-bottom: 30px;
                }
                .f-body {
                    margin-bottom: 30px;
                    // p:not(:last-child) {
                    //     margin-bottom: 8px;
                    // }
                }
            }
            .g-section__image-right {
                width: 50%;
            }
        }
        .video-section {

            .video--caption {
                width: 82%;
            }
        }
        .section.g-content__question-and-link {
            margin-bottom: 77px;
            .question-and-link-row {
                padding-bottom: 74px;
            }
            .question-link {
                position: relative;
                .f-question {
                    padding-bottom: 52px;
                    margin-bottom: 0;
                }
                .f-link {
                    position: absolute;
                    bottom: 0;
                }
            }
        }
        .section-7 {
            margin-bottom: 58px;
            .f-body {
                margin-bottom: 33px;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .supporter-page {
        .g-intro .g-intro__buttons {
            margin-top: 32px;
        }
        .o-section-block.image-Text {
            .title.mobile {
                margin-bottom: 24px;
            }
            .buttons--container {
                margin-top: 32px;
            }
            .image-caption {
                margin-bottom: 24px;
            }
        }

        .section-3,
        .section-4,
        .section-7 {
            margin-bottom: 60px;
        }
        .section.g-content__question-and-link {
            margin-bottom: 60px;
        }
        .section-7 .title.mobile {
            color: #004b87;
        }
    }
}

