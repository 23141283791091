.g-footer {
    font-size: 14px;
    background-color: $color-border-load;
    color: $black;

    a {
        font-size: 14px;
        background-color: transparent !important;
        line-height: 18px;
    }

    .g-footer__inner {
        .footer-top {
            display: flex;
            max-width: 1174px;
            margin-left: calc((100vw - 1110px) / 2);
            margin-bottom: 45px;
        }

        .footer-social {
            padding-top: 24px;

            .g-footer__link {
                a {
                    display: inline-block;
                    background: transparent;

                    &::before {
                        background-color: transparent;
                        @include css-prefix(
                            (
                                transition: 0.1s,
                            )
                        );
                    }

                    &::after {
                        background-color: transparent;
                    }
                }
            }

            a:first-child {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 16px;

                &:before {
                    content: "\f39e";
                    font-family: "Font Awesome 5 Brands";
                    font-size: 21px;
                    color:#fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #000;
                }

                &:hover {
                    &::before {
                        opacity: 0.8;
                    }
                }
            }

            a:nth-child(2) {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 16px;

                &::before {
                    content: "\f16d";
                    font-family: "Font Awesome 5 Brands";
                    font-size: 21px;
                    color:#fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #000;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    &::before {
                        opacity: 0.8;
                    }
                }
            }

            a:nth-child(3) {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 0;

                &::before {
                    content: "\f0e1";
                    font-family: "Font Awesome 5 Brands";
                    font-size: 21px;
                    color:#fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #000;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    &::before {
                       opacity: 0.8;
                    }
                }
            }
        }

        .g-footer_inner-column:first-child {
            width: 40.5%;

            .footer-social {
                display: none;
            }

            p {
                margin-bottom: 0;
            }

            .logo-block {
                display: flex;
                margin-top: 35px;

                a {
                    width: 110px;
                    background: transparent;

                    img {
                        margin-left: -5px;
                    }

                    &::before {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }

                .footer-certificate {
                    width: 70px;
                    margin-left: 62px;

                    img {
                        margin-top: -7px;
                        margin-left: -5px;
                    }
                }
            }

            .first-col-text {
                margin-top: 37px;
                padding-right: 224px;
                line-height: 18px;

                .f-body {
                    p:nth-child(1) {
                        margin-top: 8px;
                    }

                    p:nth-child(2) {
                        margin-top: 8px;
                    }

                    p:nth-child(4) {
                        margin-top: 8px;
                    }
                }
            }
        }

        .g-footer_inner-column:nth-child(2) {
            padding-right: 15px;
            width: 16.1%;
            margin-top: 60px;

            .first-col-text {
                p {
                    margin-bottom: 16px;
                }

                p.f-body {
                    margin-bottom: 0;
                }

                p:nth-child(1) {
                    margin-bottom: 51px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        .g-footer_inner-column:nth-child(3) {
            padding-right: 15px;
            width: 21.8%;
            margin-top: 59px;

            .first-col-text {
                p {
                    margin-bottom: 16px;
                }

                p.f-body {
                    margin-bottom: 0;
                }

                p:nth-child(1) {
                    margin-bottom: 51px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        .g-footer_inner-column:nth-child(4) {
            padding-right: 15px;
            margin-top: 59px;

            .first-col-text {
                p {
                    margin-bottom: 16px;
                }

                p.f-body {
                    margin-bottom: 0;
                }

                p:nth-child(1) {
                    margin-bottom: 51px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
    .footer-bot {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        min-height: 100px;
        color: $light-black;

        .f-body {
            width: 100%;
            line-height: 18px;
        }
        p {
            text-align: center;
            padding: 0 32px;
        }

        a {
            color: $light-black;
            margin-left: 6px;
            position: relative;
            background: linear-gradient(#000 0 0) 0 92% / var(--d, 0) 1px
                no-repeat;
            --d: 0;
            transition: 0.2s;

            &::before {
                content: "";
                position: absolute;
                height: 11px;
                width: 1px;
                background-color: $light-black;
                top: 4px;
                left: -5px;
                margin-left: 0;
            }

            &::after {
                display: none;
            }

            &:first-child {
                &::before {
                    display: none;
                }
            }

            &:hover {
                --d: 100%;
                &::before {
                    width: 1px;
                }
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .g-footer {
        .g-footer__inner {
            .footer-top {
                display: flex;
                max-width: 992px;
                margin: 0 auto;
                margin-bottom: 45px;
            }

            .g-footer_inner-column:first-child {
                width: 30%;
                line-height: 18px;

                .first-col-text {
                    padding-right: 29%;
                }
            }

            .g-footer_inner-column:nth-child(2) {
                width: 20.1%;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .g-footer {
        .g-footer__inner {
            .footer-top {
                display: flex;
                flex-wrap: wrap;
                max-width: 100%;
                padding: 0 30px;
            }

            .g-footer_inner-column:first-child,
            .g-footer_inner-column:nth-child(2),
            .g-footer_inner-column:nth-child(3),
            .g-footer_inner-column:nth-child(4) {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .g-footer {
        font-size: 14px;
        background-color: $color-border-load;
        color: $black;

        a {
            font-size: 14px;
            background-color: transparent !important;
        }

        .g-footer__inner {
            .footer-top {
                max-width: 100%;
                padding: 0 32px;
                margin-left: 0;
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
        }

        .g-footer__inner {
            .g-footer_inner-column:first-child {
                width: 100%;
            }

            .g-footer_inner-column:nth-child(2) {
                width: 100%;
            }

            .g-footer_inner-column:nth-child(3) {
                width: 100%;
            }

            .g-footer_inner-column:nth-child(4) {
                width: 100%;
            }
        }

        .g-footer__inner {
            .footer-social {
                display: none;
            }
        }

        .g-footer__inner {
            .g-footer_inner-column:first-child {
                .footer-social {
                    display: block;
                    border-bottom: 1px solid $black;
                    padding-bottom: 20px;
                }
            }
        }

        .g-footer__inner {
            .g-footer_inner-column:first-child {
                .first-col-text {
                    padding-right: 0;
                }

                .logo-block a img {
                    margin-top: 7px;
                }

                .logo-block .footer-certificate img {
                    margin-top: 0px;
                    margin-left: 0px;
                }

                .first-col-text {
                    margin-top: 32px;
                }

                .footer-social {
                    padding-top: 32px;
                    padding-bottom: 28px;
                }
            }

            .g-footer_inner-column:nth-child(2) {
                margin-top: 34px;

                .first-col-text p {
                    padding-left: 48px;
                    margin-bottom: 28px;

                    a {
                        font-size: 16px;
                    }
                }

                .first-col-text {
                    p {
                        display: none;
                    }

                    p.appear {
                        display: block;
                    }

                    p:nth-child(1) {
                        padding-left: 29px;
                        margin-bottom: 27px;
                        position: relative;
                        display: block;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 0px;
                            width: 20px;
                            height: 11px;
                            background-image: url("../../public/images/icons/arrow_down.svg");
                            transform: rotate(-90deg);
                            @include css-prefix(
                                (
                                    transition: 0.2s,
                                )
                            );
                        }

                        &.rotate {
                            &::before {
                                transform: rotate(0);

                                @include css-prefix(
                                    (
                                        transition: 0.2s,
                                    )
                                );
                            }
                        }
                    }
                }
            }

            .g-footer_inner-column:nth-child(3) {
                margin-top: 14px;

                .first-col-text p {
                    padding-left: 48px;
                    margin-bottom: 27px;

                    a {
                        font-size: 16px;
                    }
                }

                .first-col-text {
                    p {
                        display: none;
                    }

                    p.appear {
                        display: block;
                    }

                    p:nth-child(1) {
                        padding-left: 29px;
                        margin-bottom: 27px;
                        position: relative;
                        display: block;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 0px;
                            width: 20px;
                            height: 11px;
                            background-image: url("../../public/images/icons/arrow_down.svg");
                            transform: rotate(-90deg);
                            @include css-prefix(
                                (
                                    transition: 0.2s,
                                )
                            );
                        }

                        &.rotate {
                            &::before {
                                transform: rotate(0);
                                @include css-prefix(
                                    (
                                        transition: 0.2s,
                                    )
                                );
                            }
                        }
                    }
                }
            }

            .g-footer_inner-column:nth-child(4) {
                margin-top: 16px;

                .first-col-text p {
                    padding-left: 48px;
                    margin-bottom: 27px;

                    a {
                        font-size: 16px;
                    }
                }

                .first-col-text {
                    p {
                        display: none;
                    }

                    p.appear {
                        display: block;
                    }

                    p:nth-child(1) {
                        padding-left: 30px;
                        margin-bottom: 27px;
                        position: relative;
                        display: block;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 0px;
                            width: 20px;
                            height: 11px;
                            background-image: url("../../public/images/icons/arrow_down.svg");
                            transform: rotate(-90deg);
                            @include css-prefix(
                                (
                                    transition: 0.2s,
                                )
                            );
                        }

                        &.rotate {
                            &::before {
                                transform: rotate(0);
                                @include css-prefix(
                                    (
                                        transition: 0.2s,
                                    )
                                );
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 637px) {
    .g-footer {
        .footer-bot {
            display: block;
            padding-top: 40px;
            min-height: 125px;

            p {
                line-height: 18px;
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 335px) {
    .g-footer {
        .footer-bot {
            padding-top: 34px;
        }
    }
}
