
/* Datetime */
.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 10px 40%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }
.ui-timepicker-div .ui_tpicker_unit_hide{ display: none; }

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input { background: none; color: inherit; border: none; outline: none; border-bottom: solid 1px #555; width: 95%; }
.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input:focus { border-bottom-color: #aaa; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 40% 10px 10px; }

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine { padding-right: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time,
.ui-timepicker-div.ui-timepicker-oneLine dt { display: none; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label { display: block; padding-top: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine dl { text-align: right; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd,
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div { display:inline-block; margin:0; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before { content:':'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before { content:'.'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before{ display: none; }
#ui-datepicker-div { z-index: 2 !important;}

.ui-widget.ui-widget-content {
    border: 1px solid $black;
    background: $white;
    border-radius: 0;
    width: 255px;
    margin-top: -1px;
    padding: 0;
    //display: block !important;
    .ui-datepicker-header {
        border-radius: 0;
        background: transparent;
        border: 0;
        padding: 0;
        .ui-datepicker-title {
            margin: 12px 0 0 12px;
            font-family: "DM Sans", sans-serif;
            font-size: 16px;
            line-height: 22px;
            color: #373F41;
            text-align: left;
        }
    }

    .ui-datepicker-prev {
        background: transparent;
        height: 20px;
        width: 11px;
        top: 12px;
        left: unset;
        right: 47px;
        &:hover {
            border: 0;
            font-weight: normal;
        }
        .ui-icon {
            background-image: url("../../public/images/icons/icon-datepicker-prev.svg");
            height: auto;
            width: auto;
            background-position: unset;
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
    .ui-datepicker-next {
        background: transparent;
        height: 20px;
        width: 11px;
        top: 12px;
        left: unset;
        right: 12px;
        &:hover {
            border: 0;
            font-weight: normal;
        }
        .ui-icon {
            background-image: url("../../public/images/icons/icon-datepicker-next.svg");
            height: auto;
            width: auto;
            background-position: unset;
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
}

// table
.ui-datepicker {
    .ui-datepicker-calendar,
    .ui-state-disabled {
        margin-bottom: 19px;
        thead {
            th {
                font-family: "DM Sans", sans-serif;
                padding: 21px 0 6px 0;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #373F41;
            }
        }
        td {
            padding: 6px 9px;
            line-height: 18px;
            a,
            span {
                width: 100%;
                text-align: center;
                background: transparent;
                border: 0;
                font-family: "DM Sans", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #373F41;
                padding: 0;
                &.ui-state-active{
                    color: $orange;
                }
            }
        }

    }
}

.ui_tpicker_time_label {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $black;
}

.ui-timepicker-div {
    margin-bottom: 15px;
    dl {
        display: flex;

        .ui_tpicker_time_label {
            padding: 0 0 0 12px;
        }

        .ui_tpicker_time {
            width: 76px;
            margin: 0 0 0 15px;
            font-family: "DM Sans", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #000000;

        }
    }
}

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input {
    width: 100%;
    border-bottom-color: $black;
    text-align: center;
    padding: 0 0 2px 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
