.o-step__become-member {
    .step-number {
        border: 2px solid $orange;
        width: 40px;
        height: 40px;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        position: absolute;
        top: -23px;
        left: 50%;
        color: $orange;
        background: $white;
        @include display-flex-cs();
        @include css-prefix(
            (
                align-items: center,
                justify-content: center,
                border-radius: 50%,
                transform: translateX(-50%),
            )
        );

        span {
            display: none;
        }
    }

    .step {
        position: relative;
        padding: 17px 15px 16px;
        border: 2px solid $orange;
        background-color: $white;
        z-index: 2;
        @include css-prefix(
                        (
                                border-radius: 8px,
                        )
        );
    }

    .step .f-body {
        text-align: center;
    }

    .step-1 .f-body {
        margin-bottom: 24px;
    }

    .step strong {
        font-size: 18px;
        line-height: 24px;
        display: inline-block;
        margin-top: 10px;
    }

    .step p {
        margin-bottom: 8px;
    }

    .o-section-block-inner {
        display: inline-block;
        width: 100%;
        padding: 30px 0;
        margin: 0;
    }

    .line {
        align-items: center;
        display: flex;
        justify-content: center;
        height: auto;

        p {
            background-color: $orange;
            height: 2px;
            width: 32px;
        }

        &:last-child {
            display: none !important;
        }
    }
}

.dealer-page {
    margin-bottom: 32px;

    .g-intro {
        .g-intro__content {
            .g-intro__shortdesc {
                .f-body {
                    max-width: 530px;
                }
            }
        }
    }

    .video-section {
        .flex-box {
            border-bottom: unset;
            padding-bottom: 0;

            .title {
                color: $blue;
                padding-top: 12px;
            }
        }
    }

    .btn {
        padding: 6px 28px;
    }

    .g-artist-list {
        background-color: $light-blue;
        color: $white;

        .artist-list-title {
            padding-top: 51px;
            margin-bottom: 48px;
        }

        .g-artist-list__item {
            .title,
            .detail {
                max-width: unset;
            }
        }

        .btn {
            background-color: $black;
            color: white;

            &:hover {
                background: #333;
            }
        }

        .g-artist-list__slider {
            padding-bottom: 39px;
        }

        .owl-nav .nav-btn:before {
            color: white;
        }
    }

    //text-image-right section
    .section-3 {
        margin-bottom: 84px;

        h2.title {
            color: #004b87;
            padding-top: 25px;
            padding-bottom: 20px;
            max-width: 410px;
        }

        .f-body {
            margin-bottom: 25px;

            strong {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 27px;
            }
        }

        .btn-primary {
            padding: 6px 28px;
        }

        .image-caption {
            margin-top: 15px;
        }
    }

    .section-5 .btn-secondary {
        background-color: #fff;

        &:hover {
            background-color: #000;
        }
    }

    .g-member {
        margin-bottom: 0;

        .g-member__title {
            color: #146a9f;
        }

        .item .artist-desc {
            -webkit-line-clamp: 3;
        }
    }

    .o-step__become-member {
        margin-bottom: 66px;
    }

    .g-frequently-question {
        .faq-title {
            color: $blue;
            height: unset;
        }
    }

    .section.resources .line-end {
        display: block;
    }

    .o-grid-block.section-7 .o-grid-title {
        max-width: 100%;
    }
}

// Desktop
@media only screen and (max-width: 1199px) and (min-width: 768px) {
    .artist-dealer-supporter {
        .o-step__become-member {
            .step .custom-link {
                margin: 8px auto;
            }
        }
    }
    .dealer-page {
        .g-frequently-question {
            .btn {
                padding: 6px 26px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .artist-dealer-supporter {
        .o-step__become-member {
            .step .custom-link {
                width: 100px;
            }
        }
    }
    .dealer-page {
        .g-intro .g-intro__content .g-intro__shortdesc {
            margin-bottom: 33px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .dealer-page {
        .g-intro {
            margin-bottom: 114px;
        }

        .video-section {
            .video-section-button {
                display: flex;
                margin-top: 28px;
            }

            .video--caption {
                width: 80%;
            }
        }

        .o-grid-block.section-5 {
            margin-bottom: 48px;
        }

    }

    .o-step__become-member {
        max-width: 1150px;
        padding: 0 20px;
        margin: auto;

        .o-section-block-inner {
            padding-top: 49px;
        }

        .steps {
            @include display-flex-cs();
            margin: 59px 0 0;
            position: relative;
        }

        .step {
            width: 25%;
            background-color: $white;
        }
    }
}

// Mobile
@media only screen and (max-width: 767px) {
    .o-step__become-member {
        max-width: 100%;
        padding: 0 32px;

        .custom-link {
            margin-left: 0;
        }

        .steps {
            margin-top: 51px;
            position: relative;
        }

        .step {
            text-align: center;
            background: $white;
            margin-bottom: 0;
        }

        .line {
            p {
                height: 30px;
                width: 2px;
                margin-bottom: 20px;
            }
        }
    }

    .dealer-page {
        .section-3 {
            h2.title {
                font-size: 34px;
                line-height: 44px;
                max-width: 100%;
            }

            .o-section-block-inner {
                display: block;
            }

            .g-section__image-right {
                display: none;
            }

            .g-section__image--mobile {
                display: block;
            }

            .title.desktop {
                display: none;
            }

            .title.mobile {
                display: block;
            }
        }

        .o-step__become-member {
            margin-bottom: 0;

            .o-section-block-inner {
                width: 100%;
            }
        }

        .section-7 {
            .o-grid-item {
                li {
                    margin-bottom: 0;
                }

                &:first-child {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

//style for animation only
html:not(.no-js) .dealer-page .o-step__become-member .line[data-aos=fade-right] {
    transform: translate3d(-36px, 0, 0);
}

html:not(.no-js) .dealer-page .o-step__become-member .line[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
}

