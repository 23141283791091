
.about-us-page {
    + .g-content__question-and-link {
        background: #fff;
    }
}

.iartc-strategic {
    background-color: $light-orange;

    &-inner {
        padding-top: 73px;
        padding-bottom: 68px;

        h2 {
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 56px;
        }

        .row-iartc-strategic {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 70px;

            .col-iartc-strategic {
                .a-outer {
                    margin-bottom: 22px;
                }

                a.custom-link {
                    width: fit-content;
                }
            }
        }
    }
}

.g-governance {
    .g-governance-inner {
        max-width: 1150px;
        padding: 39px 20px 18px;
        margin: 0 auto;

        h2 {
            font-size: 42px;
            line-height: 51px;
            letter-spacing: 0.2px;
            color: $black;
            margin-bottom: 54px;
        }

        .subtitle {
            font-size: 16px;
            line-height: 22px;
            color: $black;
            margin-bottom: 55px;
            max-width: 920px;
        }

        > p {
            margin-bottom: 17px;
        }

        > p b {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }

        .member-list {
            margin-bottom: 82px;

            h3.title {
                font-weight: 400;
                font-size: 34px;
                line-height: 44px;
                margin-bottom: 65px;
            }

            .member-list-row {
                .member-single-inner {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 33px;

                    .member-single-left {
                        width: 190px;
                        height: 190px;
                        margin-right: 67px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .member-single-right {
                        flex: 1;
                        position: relative;
                        padding-bottom: 28px;

                        .name {
                            font-family: $font-family-sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 34px;
                            line-height: 44px;
                            color: #e74c39;
                            margin-top: 30px;
                        }

                        .title {
                            font-family: $font-family-sans-serif;
                            font-style: italic;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: #373f41;
                            margin-top: 2px;
                        }

                        .description {
                            font-family: $font-family-sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: #373f41;
                            margin-top: 17px;
                            max-width: 755px;
                        }

                        .ql-editor.dolessmore {
                            overflow: hidden;
                        }

                        .lm-control {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 20px;

                            a {
                                font-weight: 700;
                                background: transparent;
                            }
                        }

                        .dlmexpand .lm-control {
                            bottom: 1em;
                        }

                        a {
                            color: $color-text-light-black;
                        }

                        .show-full-text {
                            overflow: auto;
                            -webkit-line-clamp: unset;
                        }

                        .member-link {
                            font-weight: 700;
                            background: transparent;
                            margin-top: 8px;
                            display: none;
                        }

                        .show {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.o-grid-block.grid2-title .o-grid-block-inner {
    padding-bottom: 57px;

    .o-grid-item {
        h2 {
            font-size: 34px;
            line-height: 44px;
        }
    }
}

.g-content__question-and-link {
    &-inner {
        .question-and-link-row {
            display: flex;
            flex-wrap: wrap;
            padding-top: 109px;
            padding-bottom: 112px;

            .g-content_question-item {
                width: 33.33%;
                padding-right: 20px;
                padding-left: 11px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 44px;
                    margin-bottom: 28px;
                }

                a {
                    font-size: 16px;
                    line-height: 22px;
                    width: fit-content;
                    margin-top: auto;
                    color: $black;

                    .arrows-long:after {
                        bottom: 2px;
                        margin-left: 12px;
                    }
                }

                &:first-child {
                    padding-left: 0;

                    h3 {
                        color: $orange;
                    }
                }

                &:nth-child(2) {
                    h3 {
                        color: $blue;
                    }
                }

                &:nth-child(3) {
                    padding-left: 22px;
                    padding-right: 0;

                    h3 {
                        color: $green;
                    }
                }
            }
        }
    }
}

.g-footer__acknowledge {
    &-inner {
        .g-footer__acknowledge-row {
            padding-top: 66px;
            padding-bottom: 65px;

            p {
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                max-width: 845px;
                margin: 0 auto;

                strong {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 700;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .iartc-strategic-inner {
        .row-iartc-strategic {
            .col-iartc-strategic {
                .a-outer {
                    margin-right: 64px;
                }
            }
        }
    }

    .g-content__question-and-link {
        .question-link {
            .f-link {
                margin-right: 64px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {

    .iartc-strategic {
        padding-left: 32px;
        padding-right: 32px;
        background-color: $light-orange;

        &-inner {
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 0;
            padding-right: 0;

            .row-iartc-strategic {
                width: calc(100% - 75px);
                grid-template-columns: 1fr 1fr;
                column-gap: 40px;

                .col-iartc-strategic {
                    .a-outer {
                        margin-bottom: 30px;
                    }

                    a.custom-link {
                        width: fit-content;
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .g-governance {
        padding-left: 32px;
        padding-right: 32px;

        .container {
            padding: 0;
        }

        .g-governance-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .g-content__question-and-link {
        padding-left: 32px;
        padding-right: 32px;

        .container {
            padding: 0;
        }

        .g-content__question-and-link-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .iartc-strategic {
        background-color: $light-orange;

        &-inner {
            padding-top: 40px;
            padding-bottom: 33px;
            padding-left: 0;
            padding-right: 0;

            h2 {
                font-size: 28px;
                line-height: 38px;
                margin-bottom: 48px;
            }

            .row-iartc-strategic {
                width: 100%;
                grid-template-columns: 100%;

                .col-iartc-strategic {
                    .a-outer {
                        margin-bottom: 44px;
                    }

                    a.custom-link {
                        width: fit-content;

                        .arrows-long {
                            &::after {
                                bottom: -26px;
                                left: -13px;
                            }
                        }
                    }

                    &:nth-child(2) {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .g-governance {
        .g-governance-inner {
            max-width: 1150px;
            padding: 41px 0 48px;
            margin: 0 auto;

            h2 {
                font-size: 34px;
                line-height: 44px;
                margin-bottom: 24px;
            }

            .subtitle {
                margin-bottom: 48px;

                p {
                    &:last-child {
                        margin-bottom: 3px;
                    }
                }

                p strong {
                    padding-right: 15px;
                    display: block;
                }
            }

            .member-list {
                margin-bottom: 82px;

                &:last-child {
                    margin-bottom: 0;

                    .member-list-row
                    .member-single:last-child
                    .member-single-inner {
                        margin-bottom: 0;
                    }
                }

                h3.title {
                    font-size: 28px;
                    line-height: 38px;
                    font-weight: 700;
                    margin-bottom: 47px;
                }

                .member-list-row {
                    .member-single-inner {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 55px;

                        .member-single-left {
                            width: 100%;
                            height: 140px;
                            margin: 0;

                            img {
                                width: 140px;
                                height: 140px;
                                object-fit: cover;
                                border-radius: 50%;
                                display: block;
                                margin: 0 auto;
                            }
                        }

                        .member-single-right {
                            flex: 1;
                            width: 100%;

                            .name {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 700;
                                margin-top: 23px;
                                text-align: center;
                            }

                            .title {
                                font-size: 16px;
                                text-align: center;
                                margin-top: 13px;
                            }

                            .member-link {
                                font-weight: 700;
                                background: transparent;
                                margin-top: 8px;
                                display: block;
                            }

                            .show-full-text {
                                overflow: auto;
                                -webkit-line-clamp: unset;
                            }

                            .member-link {
                                font-weight: 700;
                                background: transparent;
                                margin-top: 8px;
                                display: none;
                            }

                            .show {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .g-content__question-and-link {
        &-inner {
            padding: 0 23px;

            .question-and-link-row {
                display: flex;
                flex-wrap: wrap;
                padding-top: 40px;
                padding-bottom: 67px;

                .g-content_question-item {
                    width: 100%;
                    padding-right: 20px;
                    padding-left: 0;
                    display: flex;
                    flex-direction: column;

                    h3 {
                        font-size: 28px;
                        line-height: 38px;
                        margin-bottom: 23px;
                    }

                    a {
                        font-size: 16px;
                        line-height: 22px;
                        width: fit-content;
                        margin-top: auto;
                        color: $black;

                        .arrows-long:after {
                            margin-left: 0;
                            bottom: -21px;
                            left: 0;
                        }
                    }

                    &:first-child {
                        padding-left: 0;
                        margin-bottom: 61px;

                        h3 {
                            color: $orange;
                        }
                    }

                    &:nth-child(2) {
                        margin-bottom: 62px;

                        h3 {
                            color: $blue;
                            padding-right: 23px;
                        }
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                        padding-right: 32px;

                        h3 {
                            color: $green;
                        }
                    }
                }
            }
        }
    }
    .o-grid-block.grid2-title .o-grid-block-inner {
        .o-grid-item h2 {
            font-size: 28px;
            line-height: 38px;
        }
    }
    .g-content_question-item {
        .arrows-long:after {
            left: -2px;
        }
    }
    .g-footer__acknowledge {
        &-inner {
            padding-left: 32px;
            padding-right: 32px;

            .g-footer__acknowledge-row {
                padding-top: 48px;
                padding-bottom: 41px;

                p:not(:last-child) {
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    max-width: 845px;
                    margin: 0 auto;
                    margin-bottom: 8px;

                    strong {
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {

    .iartc-strategic {
        &-inner {
            max-width: 100%;
        }
    }

    .g-content__question-and-link {
        .g-content_question-item:first-child {
            h3 {
                margin-bottom: 22px;
            }
        }

        &-inner {
            max-width: 100%;
            padding: 0 32px;

            .question-and-link-row {
                .g-content_question-item {
                    &:nth-child(3) {
                        padding-right: 14px;
                    }
                }
            }
        }
    }

    .g-footer__acknowledge {
        &-inner {
            max-width: 100%;
        }
    }
}
