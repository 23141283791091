.membership-application-form {
    .list-all-artwork {
        .label-group {
            margin-bottom: 0;
        }
    }

    .certify {
        ul {
            list-style: decimal;
            margin-top: 27px;
            padding-left: 27px;
        }

        li {
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul ul {
            list-style: lower-alpha;
            margin-top: 0;
        }

        ul ul li {
            margin-bottom: 2px;
        }

        .parsley-errors-list {
            margin-top: 25px;
            padding-left: 0;
            list-style: none;
        }
    }
    .is-engage-business-selected{
        .form-group.items .item{
            margin-top: 15px;
        }
    }
}

.dealer {
    .label-group {
        margin-bottom: 8px;
        display: inline-block;
    }

    .social-links {
        .label-group {
            margin-bottom: 0;
        }
    }

    .note {
        margin: 5px 0 8px;
    }

    textarea {
        margin-top: 16px;
    }

    // Form 2
    .list-wrapper {
        margin-top: 2px;

        li {
            margin-right: 0;
        }
    }

    .form-check {
        margin-top: 17px;
    }

    .languages {
        .online-trading {
            position: relative;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .online-trading a.remove_field {
            top: -10px;
        }
    }

    // Form 4
    .upload-image-wrapper{
        .read-more{
            display: none;
        }
    }
    .membership-form-step4 {
        .entity-name .form-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .entity-name .selected-item {
            padding: 3px 20px 7px 0;
        }

        .entity-name .form-label {
            margin-bottom: 8px;
        }

        .multisite-memberships {
            margin: 40px 0 0;
        }

        .multisite-membership {
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .multisite-membership .label-group {
            padding-right: 25px;
        }
    }

    // Form 5

    .upload-box > .note{
        display: none;
    }
    .media-description{
        > .title{
            order: 1;
        }
        > .name{
            order: 2;
        }
        > .photo{
            order: 3;
        }
        > .copy-right{
            order: 4;
        }
        > .year{
            order: 5;
        }
    }
    // Form 6
    .membership-form-step6 {
        .form-box {
            margin-top: 0;
        }

        .directors {
            margin-bottom: 40px;
        }
    }

    // Form 7
    .membership-application-form .when-deal-Iac-selected .form-check.big-size .checkbox-label{
        font-size: 14px;
        line-height: 18px;
    }

    // Form 8
    .membership-form-step8 {
        .form-label {
            margin-bottom: 0;
        }
    }
}

// From 7
.upload-artwork-wrapper.file {
    .note {
        margin: 17px 0 0;
    }
}

.when-deal-Iac-selected {
    .upload-artwork-wrapper {
        margin-top: 40px;
    }
}

// Desktop
@media only screen and (min-width: 1200px) {
    .dealer {
        // Form 5
        .upload-artwork-wrapper .artwork-item .form-group {
            &.title {
                padding-right: 0;
            }

            &.title,
            &.name {
                flex-basis: calc(50% - 15px);
            }

            &.photo,
            &.copy-right {
                flex-basis: 380px;
                padding-right: 30px;
            }

            &.year {
                flex-basis: 156px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .dealer {

        // Form 2
        .membership-form-step2 {
            .form-wrapper > .form-group {
                max-width: 445px;
            }
        }

        .list-wrapper {
            display: flex;
            flex-wrap: wrap;

            li {
                flex-basis: 50%;

                &:nth-child(2n) {
                    padding-left: 15px;
                }
            }
        }

        .list-all-how_trade {
            .list-wrapper {
                display: inline-block;
            }

            li {
                &:nth-child(2n) {
                    padding-left: 0;
                }
            }
        }

        .languages {
            .online-trading {
                display: flex;
                justify-content: space-between;
            }

            .form-control {
                flex-basis: calc(50% - 15px);
            }
        }

        // Form 4
        .membership-form-step4 {
            .business-name .form-control {
                margin-top: 0;
            }

            .entity-name .custom-select {
                flex-basis: 159px;
            }

            .entity-name .form-control {
                flex-basis: calc(100% - 189px);
                padding: 3px 20px 7px 0;
            }

            .multisite-membership,
            .entity-name .form-content {
                max-width: 445px;
            }
            .business-operate {
                margin-bottom: 0;
            }
        }

        // Form 7
        .year-begin-trading {
            .custom-select {
                max-width: 255px;
            }
        }

        .form-group.items .item {
            max-width: 540px;
        }

        .form-group.items .item .form-control {
            max-width: 100%;
        }

        .how-demonstrate {
            .form-label {
                margin-bottom: 8px;
            }

            .form-control {
                margin-top: 8px;
            }
        }

        .membership-form-step7 .button-action .btn-primary {
            max-width: 160px;
        }

        // Form 8
        .membership-form-step8,
        .membership-form-step9 {
            .button-action .btn-primary {
                max-width: 261px;
            }
        }
    }
}

// Mobile
@media only screen and (max-width: 767px) {
    .membership-application-form {
        .year-begin-trading {
            .custom-select {
                margin-top: 12px;
            }
        }
    }
    .dealer {
        .list-wrapper {
            margin-top: 9px;
        }

        // Form 2
        .membership-form-step2 {
            .list-all-how_trade {
                margin-bottom: 23px;
            }
        }

        .languages .add-another {
            font-size: 14px;
            line-height: 18px;

            button {
                margin-right: 8px;
            }
        }

        .languages .online-trading {
            margin-bottom: 30px;
        }

        .online-trading a.remove_field {
            top: -18px;
        }

        .form-check.form-group {
            display: block;
        }

        // Form 4
        .membership-form-step4 {
            .entity-name .custom-select {
                flex-basis: 66px;
            }

            .entity-name .form-control {
                flex-basis: calc(100% - 84px);
            }
        }

    }
}
