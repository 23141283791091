.mb-9 {
    margin-bottom: 9px;
}

.font-400 {
    font-weight: 400;
}

.font-700 {
    font-weight: 700;
}

.no-longer-member {
    max-width: 635px;
    width: 100%;
    padding: 96px 32px;
    //margin-top: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
        margin-bottom: 60px;
        h1 {
            display: none;
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            color: $orange;
            margin-bottom: 37px;
        }
        p {
            display: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }
    .button--back {
        margin-bottom: 73px;
        opacity: 0;
        .btn {
            text-transform: uppercase;
            padding-right: 21px;
            padding-left: 30px;
            position: relative;
            min-height: 48px;
            &::before {
                content: "";
                width: 9px;
                height: 18px;
                position: absolute;
                left: 13px;
                background: url("../../public/images/icons/icon-arrow-left-white.svg");
            }
        }
    }
    .image {
        width: 160px;
        opacity: 0;
        margin-bottom: 16px;
    }
    .description {
        opacity: 0;
        h5 {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 768px) {
    .no-longer-member {
        transform: unset;
        top: 0;
        left: 0;
        padding: 96px 32px;
        max-width: 100%;
        //height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
            margin-bottom: 24px;
            h1 {
                margin-bottom: 24px;
                font-size: 22px;
            }
        }
        .button--back {
            margin-bottom: 56px;
            .btn {
                width: 100%;
                &::before {
                    left: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .no-longer-member .title h1 {
        font-size: 19px;
    }
}
