form.event-form {
    margin-bottom: 58px;
    position: relative;
}
.loading-circle{
    margin-left: auto;
    margin-right: auto;
}
.delete-msg{
    margin-bottom: 20px;
}
#event-start,
#event-end {
    background: url("/images/arrow-base.svg") no-repeat right 0 bottom 6px;
}

.Approved {
    color: $light-green;
}

.Pending {
    color: $light-blue;
}

.Declined {
    color: $orange;
}

.event-form .alert {
    margin-bottom: 24px;
}

.event-form {
    input.form-control:disabled:focus {
        border-bottom: 1px solid #000;
    }

    input::placeholder {
        color: #939598;
    }
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #939598;
    }
    input::-moz-placeholder { /* Firefox 19+ */
        color: #939598;
    }
    input:-ms-input-placeholder { /* IE 10+ */
        color: #939598;
    }
    input:-moz-placeholder { /* Firefox 18- */
        color: #939598;
    }
    h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 50px;
        text-transform: uppercase;
    }
    textarea {
        resize: none;
    }
    input,
    textarea,
    .selected-item {
        color: #939598;
    }
    input:disabled,
    textarea:disabled,
    .selected-item:disabled {
        color: #939598;
        cursor: text;
    }
    .event--name{
        margin-bottom: 19px;
        .event--name__input {
            width: 445px;
            padding-top: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            height: auto;
        }
    }

    .event--description{
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        .event--description__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 14px;
            color: #939598;
        }
        .event--description__input {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            padding: 10px 14px 17px 12px;
            width: 100%;
            height: 61px;
        }
    }

    .event--location {
        display: flex;
        column-gap: 30px;
        > .form-group {
            width: 30%;
            max-width: 255px;
        }
        .form-group:nth-child(3n) {
            width: 40%;
            max-width: 350px;
        }
        .event--location__state, .country-group {
            .selected-item {
                padding-top: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                width: 100%;
                position: relative;
            }
        }
        .selected-item::after {
            top: unset;
            bottom: 0;
        }
    }

    .container--select {
        width: 30%;
        max-width: 255px;
    }

    .event--datetime {
        column-gap: 30px;
        display: flex;
        > .form-group {
            width: 30%;
            max-width: 255px;
        }
        .form-group:nth-child(3n) {
            width: 40%;
            max-width: 350px;
        }
    }

    .form-box {
        margin-bottom: 18px;

        &+ .form-group {
            margin-top: 40px;
        }
    }

    .form-group__input{
        padding-top: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        height: auto;
        width: 100%;
    }
    .submit-form {
        width: 160px;
        padding: 8px 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .form-action {
        display: flex;
        justify-content: space-between;
    }

    .form-action__event {
        display: flex;
        align-items: center;
        button{
            margin: 0;
            border: 0;
            padding: 0;
        }
        svg {
            display: inline-block;
            margin-left: 18px;
        }
    }
    .form-action {
        h3 {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }
    textarea.form-control {
        border-radius: 4px;
    }

    .custom-select .all-items {
        z-index: 1;
    }

    .custom-select .selected-item {
        z-index: 1;
    }

    .parsley-error + .selected-item {
        border-bottom-color: $orange;
        &.arrowanim {
            border-bottom-color: $black;
        }
    }
}

.show-more--container {
    display: flex;
    .show-more {
        margin-right: 32px;
    }
    p {
        color: #C4C4C4;
    }
}

.your-event {
    margin-bottom: 41px;
    .item-list-event:not(:last-child) {
        margin-bottom: 42px;
    }
}

@media (max-width: 1199.98px) {
    .event-form {
        .event--location,
        .event--datetime {
            column-gap: unset;
            align-items: flex-end;

            > .form-group,
            .container--select {
                max-width: calc((100% - 60px)/3);
                width: 100%;
                margin-right: 30px;
            }

            .form-group:nth-child(3n) {
                max-width: calc((100% - 60px)/3);
                width: 100%;
                margin-right: 0;
            }
        }
    }
}

.event-form .form-action__event a {
    background: unset;
}

@media screen and (max-width: 768px) {
    .event-form .event--location .event--location__state.custom-select {
        width: 100%;
    }
    .event-form .container--select {
        width: 21.5%;
    }
    form.event-form {
        margin-bottom: 56px;
    }
    .panel.event {
        padding: 20px;
    }
    .event-form {
        .event--name {
            .event--name__input{
                width: 100%;
            }
        }
    }
    .event-form {
        h2 {
            margin-bottom: 24px;
        }

        .event--description {
            .event--description__input {
                padding: 8px 11px 6px;
                height: 73px;
            }
        }

        .submit-form {
            width: 160px;
            padding: 8px 0;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
        }
    }
    .event-form{
        input {
            padding-bottom: 0;
        }
        .submit-form {
            width: 100%;
        }
        .event--name {
            margin-bottom: 12px;
        }
        .event--description {
            margin-bottom: 15px;
            .event--description__label {
                margin-bottom: 7px;
            }
        }
        .event--location {
            .event--location__state {
                .selected-item {
                    padding-bottom: 5px;
                }
            }
            .form-group:last-child {
                width: 100%;
                max-width: 100%;
            }
        }
        .form-group__input {
            padding-bottom: 3px;
        }
        .form-group__input{
            padding-top: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            height: auto;
            width: 100%
        }
        .form-action {
            display: flex;
            justify-content: space-between;
        }
    }
    .your-event {
        h2 {
            margin-bottom: 40px;
        }
    }
    .event--name{
        margin-bottom: 19px;
        .event--name__input {
            width: 445px;
            padding-top: 0;
        }
    }

    .event--description{
        margin-bottom: 30px;
        .event--description__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 14px;
        }
        .event--description__input {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            padding: 10px 14px 17px 12px;
            width: 100%;
        }
    }

    .event--location {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
        margin-bottom: 18px;
        .event--location__state {
            .selected-item {
                padding-top: 0;
                font-weight: 400;
                font-size: 14.0043px;
                line-height: 18px;
            }
        }
    }

    //.event--datetime {
    //    display: grid;
    //    grid-template-columns: 1fr 1fr 1fr;
    //    column-gap: 30px;
    //    margin-bottom: 40px;
    //}

    .form-action__event {
        display: flex;
        align-items: baseline;
        button{
            margin: 0;
            border: 0;
            padding: 0;
        }
        svg {
            display: inline-block;
            margin-left: 18px;
        }
    }

    #event-state-errors {
        width: calc(100% + 100px);
        position: relative;
        right: 100px;
        text-align: right;
    }
}

.your-event {
    .item-list-event:not(:last-child) {
        margin-bottom: 42px;
    }
}

.item-list-event {
    display: none;
}

@media (max-width: 767.98px) {
    .event-form {
        .event--location,
        .event--datetime {
            flex-wrap: wrap;
            margin-bottom: 0;
            
            > .form-group,
            .container--select,
            .form-group:nth-child(3n) {
                max-width: 100%;
                width: 100%;
                margin: 0 0 18px;
            }
        }
    }
}
