.g-intro {
    display: grid;
    grid-template-columns: 48.6% calc(51.4% - 29px);
    column-gap: 29px;
    padding-top: 40px;
    padding-bottom: 48px;

    &.g-intro-only {
        display: inline-block;
        width: 100%;
    }

    .mobile {
        display: none !important;
    }

    .owl-carousel .owl-item {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease;
    }

    .owl-nav .nav-btn {
        padding: 5px 10px;
        background-color: #ececec;
        opacity: 0.5;
        display: flex;
    }

    .owl-carousel .owl-item.active {
        opacity: 1;
        visibility: visible;
    }

    .g-intro__caption {
        margin-top: 10px;
        color: #939598;
        font-style: italic;
    }

    .g-intro-image__slider .image--frame {
        height: 375px;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .image--frame img {
        object-fit: cover;
        width: 100%;
    }

    .g-intro__content {
        .g-intro__title {
            margin-bottom: 22px;
            display: none;
        }

        .g-intro__title.aos-init {
            display: block;
        }

        .g-intro__shortdesc {
            margin-bottom: 32px;
        }
    }

    .g-intro__buttons .btn {
        padding: 6px 28px;

        &.download {
            padding-right: 36px;
        }
    }

    .g-intro__title {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 51px;
        letter-spacing: .2px;
    }
}

.o-section-block-inner {
    margin-top: 30px;
    margin-bottom: 30px;
}

.flex-box {
    display: flex;
}

.error {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
}

.owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% + 82px);
    top: 18%;
    transform: translate(-41px);
    z-index: 2;

    .disabled {
        display: block;
    }

    [class*=owl-] {
        &:hover {
            background: unset;
            color: unset;
        }

        margin: 0;
    }

    button.disabled {
        svg {
            opacity: 0;
        }
    }

    .owl-prev {
        svg {
            transform: rotate(180deg);
            transition: all .5s;
        }

        &:hover {
            svg {
                transform: rotate(180deg) translateX(5px);
            }
        }
    }

    .owl-next {
        svg {
            transition: all .5s;
        }

        &:hover {
            svg {
                transform: translateX(5px);
            }
        }
    }
}

.homepage {
    overflow: hidden;

    .mobile {
        display: none;
    }

    .owl-theme {
        .owl-nav [class*=owl-]:hover {
            background: none;
        }
    }

    .btn-special.arrows:after {
        margin: 5px auto 3px;
    }

    .g-layout {
        max-width: 1229px;
        margin: 0 auto;
        padding: 0 45px;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .buttons {
        display: flex;
        flex-direction: row;

        .btn+.btn {
            margin-left: 20px;
        }
    }

    .grid-box {
        display: grid;
    }

    .g-art-code__box {
        max-width: 253px;
        width: 100%;
    }

    .g-layout {
        max-width: 1229px;
        margin: 0 auto;
        padding: 0 45px;
        overflow: hidden;
    }

    /*
    * section intro
    */
    .g-intro__image--mobile {
        display: none;
        margin-bottom: 24px;
    }

    .g-intro {
        .grid-box {
            grid-template-columns: fit-content(540px) fit-content(540px);
            grid-column-gap: 29px;
            grid-row-gap: 10px;
        }
    }

    .g-intro__caption {
        font-family: 'DM Sans';
        margin-top: 10px;
        font-style: italic;
        font-weight: 400;
        font-size: 13.801px;
        line-height: 16px;
        color: #939598;
    }

    .g-intro__content {
        display: flex;
        flex-direction: column;
    }

    .g-intro__title {
        letter-spacing: -0.8px;
        color: $orange;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .g-intro__shortdesc {
        margin-top: 0;

        p {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $black;

            &:empty,
            .softbreak {
                display: none;
            }

        }
    }

    /*
    * section member
    */
    .form-inputs {
        align-items: flex-end;
    }

    .input--container.mobile {
        display: none;
    }

    .input--container {
        display: flex;
        margin-right: 32px;
        position: relative;
        width: 100%;

        &::after {
            content: '';
            background: url('../../public/images/icon-search.svg') center / 100% 100% no-repeat;
            width: 21px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 0;
            width: 20px;
            height: 20px;
            @include css-prefix((transition: 0.5s));
            @include css-prefix((transform: translateY(-50%)));
        }
    }

    .custom-select .selected-item {
        height: 100%;
    }

    .g-member {
        .form-group {

            @supports not (row-gap: 26.5px) {
                >*+* {
                    margin-top: 26.5px;
                }
            }

            .btn {
                text-transform: uppercase;
                padding: 6px 38px;
            }
        }

        .form-control {
            width: 100%;
            position: relative;
            padding-right: 30px;
        }

        .custom-select {
            width: 100%;
        }

        &__title {
            color: $orange;
        }

        &__link-bottom {
            margin: 50px 64px 50px 0;
        }

    }

    .g-member__item {
        &--details {
            text-align: center;
        }
    }

    .artist-name {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $black;
    }

    .artist-desc {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $black;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }

    /**
    * fair and ethical trade
    **/
    .o-section-block {
        .o-section-block-inner {
            display: flex;
            justify-content: space-between;

            >div+div {
                margin-left: 30px;
            }
        }
    }

    .buttons--container {
        margin-top: 24px;
        display: flex;

        .f-buttons+.f-buttons {
            margin-left: 20px;
        }
    }

    // fair and ethical trade
    .g-section__image--mobile {
        display: none;
    }

    .g-section__image-right {
        width: 50%;
        max-width: 540px;
    }

    .title {
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .image-caption {
        margin-top: 10px;
        font-family: 'DM Sans';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-focus-input;
    }

    .image-Text-right {
        padding-bottom: 173px;

        .o-section-block-inner {
            padding-top: 73px;

            .g-section__description {
                max-width: 501px;
                width: 50%;
            }
        }

        .f-body {
            h2 {
                margin-bottom: 24px;
            }

            p {
                font-weight: 400;
                margin-bottom: 24px;
            }
        }

        .buttons--container {
            margin-top: 24px;
            text-transform: uppercase;
        }

    }

    .image-Text-left {
        .g-section__image-left,
        .g-section__description {
            max-width: 540px;
            width: 100%;
        }

        .image-caption {
            max-width: 435px;
        }
    }

    .g-section__image-left {
        position: relative;
    }

    .featured-artist {
        position: absolute;
        top: 48px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $orange;
        max-width: 110px;
    }

    .g-section__image--rounded-frame {
        margin-top: 48px;
    }

    .featured-artist-language {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }

    .image-Text-left {
        .title {
            margin-bottom: 24px;
        }
    }

    /*
    * section faqs
    */

    ~.g-content__question-and-link {
        .g-content_question-item {
            padding: 0;
            max-width: 349px;
            margin-right: 31px;
            justify-content: space-between;

            &:nth-child(3) {
                padding: 0;
                margin-right: 0;
            }

        }
    }

    .item-img {
        max-height: 132px;
        margin-bottom: 33px;

        img {
            width: auto;
            margin: auto;
            height: 132px;
        }
    }

    .g-question-and-link {
        background-color: $color-border-load;
    }

    .faqs-title {
        .title {
            text-align: center;
            margin-bottom: 0;
        }
    }

    .content-faqs {
        // overflow:hidden;
        padding-bottom: 36px;
        width: 100%;
    }

    /*
    * section art code
    */
    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .g-art-code {
        .container {
            >p {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
            }
        }

        h2 {
            margin-bottom: 17px;
        }

        .g-layout {
            border-bottom: 1px solid #A6A2A2;
            padding-bottom: 47px;
        }

        .flex-box {
            max-width: 1108px;
        }


        .g-art-code__box {
            min-width: 253px;
        }

        .line {
            max-width: 1108px;
        }
    }

    /*
    * section articles
    */
    .article-arrows {
        padding-top: 16px;
        margin-top: 16px;

        &::after {
            margin: 0;
        }
    }

    .g-articles {
        padding-top: 0;
        padding-bottom: 56px;
    }

    .article-headings {
        .title {
            margin-bottom: 24px;
        }

        .subtitle {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18.0056px;
            line-height: 22px;
        }
    }
}

/*
*   section search members
*/

.g-member {
    padding-top: 39px;
    padding-bottom: 60px;

    .form-group {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;

        .buttons {
            display: flex;
            justify-content: right;
        }

        .btn {
            text-transform: uppercase;
            padding: 6px 38px;
        }
    }

    .g-member__title {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-top: 51px;
        margin-bottom: 37px;
        text-transform: capitalize;
    }

    .g-member__item--image {
        border-radius: 50%;
        height: 190px;
        margin: auto;
        overflow: hidden;
        width: 190px;
        margin-bottom: 40px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .g-member__tool-bar {
        margin-top: 30px;
    }

    .form-inputs {
        display: flex;
        width: 100%;
        align-items: flex-end;

        .form-control,
        .custom-select {
            width: 100%;
        }
    }

    .g-member__link-bottom {
        text-align: right;
        margin: 50px 64px 50px 0;
    }

    .input--container {
        display: flex;
        margin-right: 32px;
        position: relative;
        width: 100%;
    }

    .g-member__item--details {
        text-align: center;

        .artist-name {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: $black;
        }

        .artist-desc {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $black;
        }
    }

}


/*
*   section questions and link
*/
.homepage~.g-content__question-and-link {
    .question-and-link-row {
        padding-top: 91px;
        padding-bottom: 90px;
        border-top: none;
    }

    .g-content__question-and-link-inner h3 {
        margin-bottom: 25px;
    }
}

/*
* section question
*/
.g-question {
    background-color: $color-border-load;

    .container {
        display: flex;
        align-items: center;
        padding-top: 52px;
        padding-bottom: 60px;
        flex-wrap: wrap;
    }

    h2 {
        max-width: 222px;
        margin-right: 60px;
    }

    .g-question__buttons {
        display: flex;

        .btn-special {
            display: inline-block;
            box-sizing: border-box;
            padding: 0 10px;
            letter-spacing: -0.5px;
            padding-top: 10px;
            background-color: #fff;

            &:hover {
                background: #000;
                border-color: #000;
                background-size: 0;
                color: #fff;
            }

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }

    .line-end {
        width: 100%;
        margin-top: 50px;
    }
}

/*
* section newsletter
*/
.g-newsletter {
    background-color: $light-green;
    box-sizing: border-box;
    padding-top: 56px;
    padding-bottom: 29px;

    .flex-box {
        justify-content: space-between;
        height: 100%;
    }

    .g-newsletter__left {
        max-width: 451px;
        display: flex;
        flex-direction: column;
    }

    h2 {
        max-width: 451px;
        margin-bottom: 55px;
    }

    .form-group {
        max-width: 353px;

        .form-control {
            width: 100%;
        }
    }

    .buttons {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        justify-content: right;
    }

    .newsletter-caption {
        font-family: 'DM Sans';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        margin-top: auto;
    }

    .g-newsletter__right {
        max-width: 542px;
        width: 100%;
    }

    .newsletter__rounded-frame {
        position: relative;
        padding-bottom: 50%;
        overflow: hidden;
        border-radius: 0 0 9999px 9999px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

/*
* Acknowledgement section
*/
.homepage~.g-footer__acknowledge .g-footer__acknowledge-row p {
    max-width: 785px;
}

/*
* section grid-article
*/

.grid-article {

    .article-template.grid-box {
        @include display-flex-cs();
        gap: 30px;
        margin-top: 41px;
        flex-wrap: wrap;
        @supports not (row-gap: 12px) {
            &>*+* {
                margin-top: 12px;
            }
        }

        h3 {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 16px;
        }
    }

    .article-template__blue,
    .article-template__orange {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 22px;
        width: 100%;

        .article-template__links {
            background: none;
            display: block;
            width: 100%;
            padding-left: 10px;

            svg {
                transition: all 0.5s;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            &:hover svg {
                transform: translateX(5px);
            }
        }
    }

    .article-template__blue {
        .article-template__links {
            color: #FEFFFE;
        }

        .article-template--frame {
            margin-top: auto;
        }

        .article-template--image {
            position: relative;
            padding-bottom: 50%;
            overflow: hidden;
            border-radius: 9999px 9999px 0 0;
        }

        .article-template__links  {
            .f-body.ql-editor, 
            p {
                max-width: 440px;
                margin-bottom: 0;
                cursor: pointer;
            }
        }
    }

    .article-template__orange {
        .article-template--frame {
            margin-bottom: auto;
        }

        .article-template--image {
            position: relative;
            padding-bottom: 50%;
            overflow: hidden;
            border-radius: 0 0 9999px 9999px;
        }

        .article-template__links  {
            .f-body.ql-editor, 
            p {
                cursor: pointer;
                max-width: 425px;
                margin-bottom: 0;
            }            
        }
    }

    .article-template__content {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .article-template--image {
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

    .grid-box-actions-bottom {
        float: unset;
        text-align: right;
        margin-top: 25px;
        margin-bottom: 60px;
        overflow: hidden;

        a.arrows {
            margin-right: 70px;
        }
    }

    .article-template__blue {
        background-color: $blue;
    }

    .article-template__orange {
        background-color: $orange;
    }

    .article-template-item {
        width: 100%;
    }

    .caption-1,
    .caption-2 {
        font-family: "DM Sans";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #939598;
        margin-top: 12px;
    }
}

/*
* section g-question-and-link
*/

.g-question-and-link {
    padding-top: 48px;

    .o-section-block-inner {
        display: flex;
        justify-content: space-between;
    }

    .content-faqs {
        padding-bottom: 36px;
        width: 100%;
    }

    .g-question-and-link__slider {
        margin-top: 70px;

        .owl-dots {
            display: flex;
            justify-content: center;
            margin-top: 24px;

            &.disabled {
                margin-top: 0;
                display: flex;
                justify-content: center;
            }

            span {
                margin: 0 5px;
            }
        }

        .owl-nav {
            top: 11%;
            margin-top: 5px;

            [class*="owl-"] {
                &:hover {
                    background: transparent;
                }
            }
        }

        .owl-stage-outer {
            -webkit-transform: unset;
            padding-bottom: 24px;
        }

        .owl-stage {
            display: flex;

            .owl-item {
                display: flex;
                justify-content: center;
            }
        }
    }

    .g-question-and-link__item {
        display: flex;
        flex-direction: column;
        max-width: 333px;

        .ql-editor {
            text-align: unset;
        }

        .title {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 0;

            .f-body {
                max-width: 70%;
                margin: auto;
                text-align: center;
            }
        }

        .detail {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;

            p {
                display: inline;
                margin-bottom: 0;
            }
        }

        .item-button {
            display: flex;
            justify-content: center;
            margin-top: auto;
            padding-top: 29px;

            .btn {
                text-transform: uppercase;
                display: flex;
                align-items: center;
                min-width: 180px;
                justify-content: center;
            }

            .btn-secondary {
                background-color: transparent;

                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        .item-img {
            margin-bottom: 32px;
            max-height: 132px;
        }

        img {
            width: auto;
            height: 100%;
            margin: auto;
        }
    }
}

/*
* section image text
*/

.image-Text {
    .o-section-block-inner {
        @include display-flex-cs();
        justify-content: space-between;
        margin: 0;
        padding: 55px 0;
    }

    .g-section__image-left,
    .g-section__image-right,
    .g-section__description {
        width: 100%;
        max-width: 50%;
    }

    .g-section__image-left {
        position: relative;
    }

    .g-section__image--rounded-frame {
        position: relative;
        overflow: hidden;
        border-radius: 9999px 9999px 0 0;
        padding-bottom: 50%;
        margin-top: 44px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .buttons--container {
        @include display-flex-cs();
        margin-top: 24px;
        text-transform: uppercase;
    }

    .f-body.ql-editor {
        margin-bottom: 24px;
    }

    .featured-artist {
        position: absolute;
        top: 44px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $orange;
        max-width: 110px;
    }

    .custom-link {
        display: inline-block;
        margin-top: 13px;
    }

    &.image-Text-right {
        .g-section__image--mobile {
            display: none;
        }

        .o-section-block-inner {
            padding-top: 77px;
        }

        .title {
            padding-top: 35px;
            padding-bottom: 22px;
        }

        .g-section__description .f-body {
            strong {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .image-caption {
            margin-top: 12px;
        }
    }

    &.image-Text-left {
        .text-content-right {
            margin-top: 22px;
        }
    }

    .ql-editor.image-caption {
        margin-top: 10px;
        margin-bottom: 24px;
    }
}

/*
* section the Indigenous Art Code
*/

.g-art-code {
    padding-top: 47px;

    .g-art-code--row {
        display: flex;
        column-gap: unset;
        justify-content: space-between;
        margin-top: 32px;
    }

    .g-art-code__box {
        display: flex;
        position: relative;
        align-items: center;
        border: solid 2px $orange;
        border-radius: 8px;
        background-color: $white;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        p {
            padding: 13px 18px;
        }
    }

    .line {
        height: 2px;
        background-color: $orange;
        max-width: 32px;
        width: 100%;
        z-index: -1;
        align-self: center;

        &:last-child {
            display: none;
        }
    }

    .links-wrapper {
        .links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 62px;
            margin-top: 32px;

            .buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 30px;

                .btn {
                    text-transform: uppercase;
                }
            }

            .apply-link {
                margin-right: 58px;
            }
        }
    }
}

.owl-carousel {
    .owl-nav.disabled,
    .owl-dots.disabled {
        display:  none !important;
    }
}

.o-body-text-block {
    .ql-editor {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .line-end {
        margin-top: 30px;
    }
}

@media (min-width: 768px) {
    .image-Text {
        .o-section-block-inner {
            gap: 30px;
        }

        .buttons--container {
            .f-buttons:not(:first-child) {
                margin-left: 24px;
            }
        }
    }

    .grid-article {
        .first-article,
        .second-article {
            max-width: 100%;
            min-height: 500px;
        }

        .article-template-item {
            max-width: calc(50% - 15px);
        }
    }
}

@media (min-width: 1181px) {
    .g-art-code {
        &.g-art-code-1-items {
            .g-art-code__box {
                max-width: 50%;
            }
        }

        &.g-art-code-2-items {
            .g-art-code__box {
                max-width: calc(50% - 16px);
            }
        }

        &.g-art-code-3-items {
            .g-art-code__box {
                max-width: calc((100% - 64px)/3);
            }
        }

        &.g-art-code-4-items {
            .g-art-code__box {
                max-width: calc((100% - 96px)/4);
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .g-member__slider,
    .owl-carousel {
        width: 90%;
        margin: auto;
    }

    .owl-nav {
        width: calc(100% + 60px);
        transform: translateX(-30px);
    }

    .g-question-and-link__slider {
        width: 90%;
        margin: 70px auto 0;
    }
}

@media screen and (max-width: 1180px) {
    .g-newsletter {
        height: unset;
    }

    .g-question {
        h2 {
            max-width: unset;
            margin-right: 0;
            margin-bottom: 32px;
        }

        .container {
            flex-direction: column;
        }
    }

    .g-member {
        .form-group {
            display: block;

            .buttons {
                margin-top: 26.5px;
                margin-left: unset;
                justify-content: flex-start;
            }
        }
    }

    .g-art-code {
        .g-art-code--row {
            flex-direction: column;
            align-items: center;
            row-gap: unset;
        }

        .links {
            align-items: flex-start
        }

        .g-art-code__box {
            width: 100%;
            justify-content: center;
        }

        .line {
            height: 31px;
            width: 2px;
        }

        &__box {
            min-height: 150px;
        }

        .flex-box {
            flex-direction: column;
            align-items: center;

            .line {
                height: 31px;
                width: 2px;
            }

            &.links {
                align-items: flex-start;

                .buttons {
                    width: 100%;
                    justify-content: center;

                    .btn {
                        width: 100%;
                    }
                }

                .apply-link {
                    margin-top: 48px;
                }
            }
        }

        &__box+.g-art-code__box {
            margin-top: 30px;
        }

        .g-art-code__box {
            max-width: 357px;
        }
    }

}

@media screen and (min-width: 768px) {
    .g-intro .g-intro__buttons {
        display: flex;
        gap: 20px;
    }

    .g-member {
        .form-inputs {
            max-width: 100%;

            .input--container {
                max-width: calc(60% - 16px);
                margin-right: 16px;
            }

            .custom-select {
                max-width: calc(40% - 16px);
                margin-left: 16px;
            }
        }

        .buttons {
            max-width: 165px;
            width: 100%;
        }
    }

    .o-body-text-block {
        .container {
            padding: 0 20px;
        }
    }
}

@media (min-width: 1181px) {
    .homepage {
        .g-member {
            .form-inputs {
                max-width: calc(100% - 165px);
            }

            .buttons {
                a {
                    margin-left: 32px;
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .homepage {
        ~.g-content__question-and-link {
            .question-and-link-row {
                flex-direction: column;
            }

            .g-content_question-item {
                // padding: 0;
                width: 100%;
                max-width: unset;
                margin-right: 31px;
                margin-bottom: 41px;

                &:nth-child(3) {
                    padding: 0;
                    margin-right: 0;
                    margin-bottom: 0;
                }

            }
        }
    }

    .g-newsletter {
        padding-top: 30px;
    }
}

@media screen and (max-width: 920px) {
    .homepage {
        .buttons--container {
            flex-direction: column;
            margin-top: 32px;

            .f-buttons+.f-buttons {
                margin-left: 0;
                margin-top: 24px;
            }

            .btn {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 870px) {
    .g-question {
        .g-question__buttons {
            flex-direction: column;

            .btn-special:not(:last-child) {
                margin-right: 0;
                margin-bottom: 32px;
            }
        }        
    }
}

@media screen and (max-width: 809px) {
    .homepage {
        .g-intro__buttons {
            flex-direction: column;

            .btn {
                &+.btn {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }

            .btn {
                width: 100%;
            }
        }
    }

    .g-member {

        .form-inputs {
            width: 100%;
        }

        .form-control {
            width: 100%;
            padding-right: 40px;
            text-overflow: ellipsis;
        }
    }

}

@media screen and (max-width: 767px) {
    .g-intro {
        display: block;

        .mobile.aos-init {
            display: block !important;
        }

        .desktop {
            display: none !important;
        }

        .slider-wrapper {
            width: 100%;
            max-width: 100%;
        }

        .flex-box>.slider-wrapper {
            display: none;
        }

        .g-intro__content,
        .g-intro-content {
            width: 100%;
            padding-left: 0;
        }

        .g-intro-image__slider .image--frame {
            height: 240px;
        }

        .g-intro__image {
            margin-bottom: 22px;
        }

        .g-intro__title.mobile {
            margin-bottom: 22px;
        }

        .g-intro-image__slider {
            margin-bottom: 10px;

            .g-intro__image {
                margin-bottom: 0;
            }
        }

        .g-intro__content .g-intro__title {
            display: none !important;
        }

        &.g-intro-only .g-intro__image {
            margin-bottom: 0;
        }

        .g-intro__buttons {
            display: inline-block;
            width: 100%;
        }

        .g-intro__buttons .btn {
            width: 100%;
            margin-top: 24px;
            min-height: 48px;
            line-height: 34px;
        }
    }

    .o-section-block-inner {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .homepage {
        .g-intro__image--mobile {
            display: block;
        }

        .g-intro {
            .grid-box {
                grid-template-columns: 100%;
            }
        }

        .g-intro__shortdesc {
            max-width: unset;
        }

        .g-intro__content {
            grid-area: 1/1;
            max-width: unset;
            margin-left: 0;
        }

        .g-section__image--mobile {
            display: block;
        }

        .g-section__image-right.desktop {
            display: none;
        }

        .image-Text-right {
            padding-bottom: 50px;

            .g-section__image-right {
                display: none;
            }

            .g-section__image--mobile {
                display: block;
                margin-bottom: 22px;
            }

            .o-section-block-inner {
                .g-section__description {
                    max-width: unset;
                    width: 100%;
                }
            }

        }

        .o-section-block {
            .o-section-block-inner {
                flex-direction: column;

                >div+div {
                    margin-left: 0;
                }
            }
        }

        .buttons--container {
            .f-buttons {
                width: 100%;
            }
        }

        .article-headings {
            .subtitle {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .video--iframe {
            margin-bottom: 12px;
        }
    }

    .g-newsletter {
        padding-top: 25px;
        padding-bottom: 48px;

        h2 {
            max-width: unset;
            margin-bottom: 56px;
        }

        .g-newsletter__right {
            max-width: unset;
        }

        .g-newsletter__left {
            max-width: unset;
        }

        .flex-box {
            flex-direction: column-reverse;
        }

        .form-group {
            max-width: unset;
        }

        .buttons {
            margin-bottom: 24px;
            justify-content: left;

            .btn {
                display: block;
                width: 100%;
            }
        }
    }

    .g-newsletter__right {
        margin-bottom: 40px;
    }

    .g-question {
        .container {
            padding-top: 40px;
            padding-bottom: 48px;
        }

        .g-question__buttons {
            .btn-special {
                margin-right: 0;
            }
        }
    }

    .g-member {
        .g-member__item--image {
            width: 140px;
            height: 140px;
            margin-bottom: 24px;
        }

        .form-group {
            .buttons {
                margin-top: 40px;

                .btn {
                    min-height: 48px;
                    line-height: 34px;
                }
            }
        }

        .g-member__link-bottom {
            text-align: left;
        }

        .form-inputs {
            flex-direction: column;

            .custom-select {
                margin-top: 16px;
            }

            .input--container {
                margin-right: 0;
            }
        }

        .buttons,
        .buttons a {
            width: 100%;
        }
    }

    .grid-article {
        .article-template.grid-box {
            grid-template-columns: 100%;
        }

        .article-template__blue {
            .article-arrows {
                margin-bottom: 20px;
                margin-top: 7px;
            }
        }

        .article-template__orange {
            .article-arrows {
                margin-top: 7px;
            }
        }

        .article-template__orange, 
        .article-template__blue {
            max-width: unset;
        }

        .article-template-item {
            max-width: 100%;
        }
    }

    .g-question-and-link {
        .o-section-block-inner {
            flex-direction: column;
        }
    }

    .image-Text {
        .o-section-block-inner {
            flex-wrap: wrap;
        }

        .g-section__image-left {
            margin-bottom: 22px;
        }

        .g-section__image-left,
        .g-section__description,
        .g-section__image-right,
        &.image-Text-right .g-section__description {
            max-width: 100%;
        }

        .image-caption {
            font-family: "DM Sans";
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }

        .buttons--container {
            margin-top: 32px;
            flex-wrap: wrap;

            .f-buttons {
                width: 100%;
                min-height: 48px;
                line-height: 34px;

                &:not(:first-child) {
                    margin-top: 24px;
                }
            }

        }

        .featured-artist {
            top: 0;
        }

        .title.mobile {
            display: block;
        }

        .title.desktop {
            display: none;
        }

        .g-section__image--rounded-frame,
        .g-section__image {
            img {
                width: 100%;
            }
        }

        .g-section__image--rounded-frame {
            margin-top: 0;
        }

        &.image-Text-right {
            .g-section__image--mobile {
                display: block;
            }

            .g-section__image-right {
                display: none;
            }
        }

        &.image-Text-left {
            .title.mobile {
                display: none !important;
            }
    
            .title.desktop {
                display: block;
            }

            .g-section__image-left {
                margin-bottom: 22px;
            }

            .g-section__image-left,
            .g-section__description {
                max-width: unset;
                width: 100%;
            }

            .g-section__image-left {
                margin-bottom: 22px;
            }

            .image-caption {
                max-width: unset;
            }
        }
    }

    .g-art-code {
        .links {
            margin-left: auto;
            margin-right: auto;
            flex-direction: column;

            .btn {
                min-height: 48px;
                line-height: 34px;
            }
        }

        .flex-box.links {
            max-width: 375px;

            .apply-link {
                margin-top: 48px;
            }
        }
    }

    .homepage {
        .featured-artist-language {
            margin-bottom: 8px;
        }

        .desktop {
            display: block;
        }

        .g-intro {
            margin-top: 43px;
            margin-bottom: 48px;
        }

        .g-intro__caption {
            margin-top: 12px;
            font-size: 12px;
            line-height: 14px;
        }

        .g-intro__title {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 24px;
        }

        .input--container.desktop {
            display: none;
        }

        .input--container.mobile {
            display: flex;
            width: 100%;
        }

        .image-Text-left {
            .buttons--container {
                margin-top: 32px;
            }
        }

        .grid-box {
            display: block;
        }

        .image-caption {
            font-family: 'DM Sans';
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        .o-section-block {
            .image-caption {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .homepage~.g-content__question-and-link {
        .g-content_question-item:not(:last-child) {
            margin-bottom: 60px;
        }
    }

    .o-body-text-block {
        .container {
            padding: 0 32px;
        }
    }
}

@media screen and (max-width: 480px) {
    .homepage {
        .g-intro {
            margin-top: 13px;
        }

        .input--container {
            margin-right: 0;
        }

        .g-member {
            .g-member__tool-bar {
                margin-top: 32px;
            }

            .form-group {
                .buttons {
                    margin-top: 40px;
                }
            }

            .form-inputs {
                .custom-select {
                    margin-top: 24px;
                }
            }

            .custom-select {
                width: 100%;
            }
        }

        .buttons {
            flex-direction: column;
            width: 100%;

            .btn {
                width: 100%;
                justify-content: center;

                &+.btn {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }
        }

        .g-art-code {
            padding-top: 40px;

            .links {
                padding-bottom: 83px;

                .arrows-long::after {
                    bottom: -15px;
                    left: 0;
                    margin-left: 0;
                }
            }
        }

        .image-Text-right {
            padding-bottom: 0;

            .o-section-block-inner {
                padding-top: 40px;
            }
        }

        .image-Text-left {
            .g-section__image-left {
                margin-bottom: 32px;
            }
        }

        .video-section {
            .btn {
                width: 100%;
            }
        }

        .caption-blue,
        .caption-orange {
            margin-left: 17px;
            font-family: 'DM Sans';
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: $color-focus-input;
        }

        .g-articles {
            a.arrows {
                float: left;
                margin-top: 32px;
                margin-left: 17px;

            }

            .container {
                >.arrows-long::after {
                    margin-left: 0;
                    left: -2px;
                    bottom: -21px;
                }
            }

            &.grid-article {
                .container {
                    padding: 0 15px;
                }
            }

        }

        .grid-article {
            padding-bottom: 109px;
        }

        .article-template {
            h3 {
                font-size: 28px;
                line-height: 38px;
            }
        }

        .article-template__blue,
        .article-template__orange {
            padding: 12px 17px 18px 18px;
            min-height: unset;

            .article-template__links {
                padding-left: 0;
            }
        }

        .article-template__blue {
            h3 {
                margin-bottom: 4px;
            }
        }

        .article-template__orange {
            h3 {
                margin-top: 18px;
                margin-bottom: 4px;
            }
        }

        .item-img {
            margin-bottom: 24px;
        }
    }

    .g-newsletter {
        .buttons {
            .btn {
                width: 100%;
            }
        }
    }

    .homepage~.g-content__question-and-link {
        .question-and-link-row {
            padding-top: 50px;
            padding-bottom: 68px;
        }
    }

    .g-question-and-link__slider {
        margin: 32px auto 0;

        .owl-nav.disabled {
            display: none;

            &+.owl-dots {
                margin-top: 40px;
            }
        }

        .owl-dots {
            margin-top: 40px;
        }
    }

    .g-question-and-link {
        padding-top: 40px;

        .g-question-and-link__item  {
            .item-img {
                margin-bottom: 24px;
            }
        }        
    }

    .g-articles{
        .article-video{
            margin-top: 32px;
        }
    }

    .image-Text {
        .g-section__image--rounded-frame {
            margin-top: 38px;
        }
    }
}

@media screen and (max-width: 375px) {
    .homepage~.g-footer__acknowledge {
        .g-footer__acknowledge-row {
            padding-top: 43px;
            padding-bottom: 48px;
        }
    }

    .homepage~.g-content__question-and-link {
        .g-content__question-and-link-inner {
            h3 {
                margin-bottom: 24px;
            }
        }

    }

    .homepage {
        input.form-control {
            font-size: 16px;
            line-height: 22px;
        }

        .custom-select .selected-item {
            font-size: 16px;
            line-height: 22px;
        }

        .g-member__item--details {
            max-width: 255px;
            margin: auto;
        }

        .g-member__slider {
            padding-bottom: 63px;
        }

        .g-member__title {
            margin: 48px 0;
        }

        .g-art-code {
            h2 {
                margin-bottom: 24px;
            }
        }
    }

    .article-template__blue,
    .article-template__orange {
        min-height: 358px;
    }

    .owl-nav {
        width: 100%;
        transform: unset;
    }
}
