.customer-dashboard-page {
    .show-more-members--container {
        display: flex;

        p {
            color: #C4C4C4;
        }
    }

    .applications {
        .page-title-wrapper {
            padding-bottom: 31px;
        }

        .note-wrapper {
            margin-top: 24px;
        }

        .continue-app {
            margin-top: 43px;
        }

        .membership-dashboard {
            padding: 24px 32px 23px 30px;
            margin-top: 41px;
        }
    }

    .members {
        .membership-dashboard {
            margin-top: 42px;
            padding: 24px 32px 31px;

            & + .membership-dashboard {
                margin-top: 56px;
            }
        }

        .note-wrapper {
            margin-top: 0;
            max-width: 320px;
        }

        .form-control {
            padding-top: 0;
            padding-bottom: 13px;
            color: #939598;
            width: 100%;
        }

        .action {
            flex-direction: row;
            justify-content: unset;
            margin-top: 50px;
        }

        .button-action {
            & + .button-action {
                margin-left: 27px;
            }

            .btn {
                min-width: 240px;
                justify-content: center;

                &.btn-primary {
                    padding-left: 15px;

                    &::after {
                        position: absolute;
                        right: 15px;
                    }
                }

                &.btn-secondary {
                    padding-left: 0;

                    &::after {
                        bottom: unset;
                        width: 7px;
                        height: 18px;
                        margin-left: 18px;
                        position: absolute;
                        right: 19px;
                        transform: unset;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(0%) contrast(104%);
                    }

                    &:hover::after {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
                        transform: translateX(5px);
                    }
                }
            }
        }

        .page-title-wrapper {
            padding-bottom: 31px;
        }
    }

    .artist-management {
        .member-declined {
            .page-title-wrapper {
                padding-bottom: 31px;
            }

            .note-wrapper {
                margin-top: 40px;
            }
        }

        .membership-dashboard {
            .link-action {
                margin-top: 0;
            }
        }

        .button-action + .link-action {
            margin-top: 24px;
        }

        .link-action {
            .contact-us > a {
                margin-bottom: 0;
            }
        }
    }
}

.panel.artists {
    padding: 72px 91px 96px 100px;
    position: relative;
}

.artists-start {
    .action {
        flex-direction: row;
        margin-top: 43px;
    }

    .link-action {
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 0;
    }

    .button-action {
        .btn {
            min-width: 254px;
            width: unset;
            justify-content: flex-start;
            text-transform: uppercase;
            padding-left: 26px;
        }

        .arrows-short {
            text-transform: uppercase;

            &::after {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
                bottom: unset;
                width: 7px;
                height: 18px;
                display: block;
                position: unset;
                margin-left: 18px;
            }
        }

        .btn-secondary.arrows::after {
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            right: 15px;
            left: unset;
        }
    }
}

.artist-management {
    margin-top: 70px;
}

.profile-management {
    margin-top: 57px;

    .selected-item.parsley-error {
        border-bottom: solid 1px $orange;
    }

    .membership-application-form {
        padding: 0;
        margin-top: 56px;
        margin-bottom: 0;

        .language-group {
            input {
                padding: 0;
                font-size: 14.0043px;
                line-height: 18px;
                color: #939598;
                padding-bottom: 7px;
            }
        }

        .add-another {
            margin-top: 24px;
        }

        .form-group {
            .form-control {
                font-size: 14.0043px;
                line-height: 18px;
                color: #939598;
                padding: 0;
                padding-right: 20px;
                padding-bottom: 8px;
            }

            textarea.form-control {
                padding: 10px;
            }
        }

        .selected-item {
            padding-top: 0;
            padding-bottom: 7px;
            font-style: normal;
            font-weight: 400;
            font-size: 14.0043px;
            line-height: 18px;
            color: #939598;
            height: unset;
        }

        .social-links {
            .label-group {
                margin-bottom: 0;
            }
        }
    }

    .page-title-wrapper {
        padding-bottom: 18px;
    }

    .profile-label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13.9978px;
        line-height: 22px;
        text-transform: uppercase;
        margin-bottom: 40px;
        color: #000000;
    }

    input.item-img {
        display: none;
    }

    .upload-label {
        margin-top: 35px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000;
    }

    .upload-img {
        display: block;
        color: #675F5F;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
        text-decoration: underline;
        cursor: pointer;
    }

    .read-more, .read-more a {
        font-size: 15.7803px;
        line-height: 21px;
        margin-top: 16px;
    }

    .b-name-abn, .b-email-phone, .b-website-location {
        display: flex;
        justify-content: space-between;
    }

    .business_name, .abn, .form-box-email, .form-box-phone, .form-box-website, .form-box-location {
        max-width: 445px;
        width: 100%;
    }

    .business_name, .form-box-email, .form-box-website {
        margin-right: 30px;
    }

    .business-address {
        .form-group + .form-group {
            margin-top: 15px;
        }
    }

    .list-all-artwork {
        margin-top: 40px;

        .label-group {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #000;
            margin-bottom: 24px;
            display: block;
        }

        .list-wrapper {
            display: flex;
            flex-wrap: wrap;
            row-gap: 18px;
            column-gap: 73px;

            .form-check {
                width: 50%;
                max-width: 400px;
            }

            .form-check-label {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
                white-space: nowrap;
                padding-right: 20px;
            }
        }
    }

    .country-group {
        margin-top: 16px;
    }

    .list-all-artwork {
        margin-bottom: 56px;
    }

    .social-links {
        margin-bottom: 0;

        .form-groups {
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;
            row-gap: 24px;
            -moz-column-gap: 31px;
            column-gap: 31px;
        }

        .form-group {
            width: 100%;
            max-width: 444px;
        }
    }

    .artwork-label {
        margin-top: 70px;
        margin-bottom: 42px;
    }

    .upload-artwork-wrapper {
        .artwork-item {
            .image {
                padding: 0;
                flex-basis: unset;
                width: 61px;
                max-height: 40px;
                margin-right: 18px;
            }

            img {
                object-fit: cover;
                height: 100%;
            }
        }

        .title-status {
            display: none;
        }
    }

    .associations {
        h3 {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            color: #000;
            margin-top: 70px;
            margin-bottom: 42px;
        }
    }

    .search-members {
        display: flex;
        position: relative;

        .search-input {
            margin-right: 28px;
            width: 100%;
            max-width: 759px;
            position: relative;
            display: flex;

            img {
                position: absolute;
                top: 10px;
                right: 0;
            }
        }

        button {
            min-width: 133px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 13.9978px;
            line-height: 22px;
            padding-left: 12px;
        }

        .association-search {
            display: block;
            padding-bottom: 0;
            padding-right: 22px;
            width: 100%;
            color: #000;
            font-size: 18px;
            line-height: 24px;
            cursor: text;
            max-width: unset;
        }
    }

    .list-associations {
        margin-bottom: 60px;
        margin-top: 31px;

        .association-item {
            align-items: flex-end;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            padding-bottom: 10px;

            &:not(:first-child) {
                padding-top: 12px;
                border-top: 1px solid #000;
            }
        }

        img {
            border-radius: 50%;
        }

        .association-column:last-child {
            min-width: 200px;
            margin-left: auto;
        }

        .delete-request {
            margin-left: 16px;
            white-space: nowrap;
        }
    }

    .association-column:last-child {
        justify-content: flex-end;
    }

    .association-column {
        display: flex;
        align-items: flex-end;

        a {
            font-size: 16px;
            line-height: 22px;
        }

        &:nth-child(2) {
            justify-content: flex-end;
        }

        &:last-child {
            justify-content: flex-end;
        }

        .remove-member {
            font-weight: 400;
            font-size: 0;
            line-height: 22px;
            background: none;

            &::after {
                content: "";
                width: 19px;
                height: 22px;
                background: url("../../public/images/icons/delete-icon.svg") center/100% no-repeat;
                min-width: unset;
                display: block;
            }
        }
    }

    .major-column {
        width: 100%;
    }

    .name-role {
        display: table;
        margin-left: 15px;
        width: 100%;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            display: table-cell;
            width: 300px;
            vertical-align: bottom;
            padding-right: 20px;
        }
    }
}

.tab-content-profile {
    margin-top: 70px;

    .profile-image {
        .cabinet {
            display: flex;
            align-items: center;

            figure {
                min-width: 171px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                border: 1px solid #000;
                overflow: hidden;
                position: relative;
                display: inline-block;
                margin-right: 32px;

                img {
                    object-fit: contain;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }
}

.tab-links {
    display: flex;
    border-bottom: solid 1px #000;

    .tab-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        background: none;
        padding: 11.5px 18px;
        display: flex;
        justify-content: center;
        min-width: 152px;
        margin-bottom: -1px;
        border-bottom: solid 1px #000;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ECECEC;
        transition: unset;
    }

    .tab-link-detail {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        background: none;
        padding: 11.5px 18px;
        display: flex;
        justify-content: center;
        min-width: 152px;
        margin-bottom: -1px;
        border-bottom: solid 1px #000;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ECECEC;
        transition: unset;
    }

    .current-tab {
        border-top: solid 1px #000;
        border-right: solid 1px #000;
        border-left: solid 1px #000;
        border-bottom: 1px solid #ECECEC;
        background: #ECECEC;

        &:first-child {
            border-left: none;
        }
    }
}

.artist-back-arrow {
    position: absolute;
    top: 42px;

    .artist-back {
        display: inline-block;
        margin-left: 65px;
        position: relative;

        &:hover {
            span::before {
                transform: translateX(-5px);
            }
        }
    }

    span {
        &::before {
            content: "";
            background: url(/images/back.png);
            min-width: 46px;
            height: 12px;
            bottom: 5px;
            left: -65px;
            position: absolute;
            transition: 0.5s;
        }
    }
}

.tab-title {
    font-weight: 700;
    font-size: 13.9978px;
    line-height: 22px;
    margin-top: 70px;
    text-transform: uppercase;
}

.show-more-members {
    margin-right: 32px;
}

.alert-manage {
    margin-top: 20px;
}

.tab-content {
    .tab-title {
        font-weight: 700;
        font-size: 13.9978px;
        line-height: 22px;
        margin-top: 70px;
        text-transform: uppercase;
    }

    .membership-dashboard {
        border: solid 1px #000;
        box-sizing: border-box;
        display: none;

        .page-title-wrapper {
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
        }
    }

    .members,
    .applications {
        .membership-dashboard {
            display: none;
        }
    }

    .continue-app {
        .button-action {
            .btn {
                padding-left: 35px;
            }

            .arrows-short::after {
                margin-left: 28px;
            }
        }
    }

    .button-action {
        .btn {
            min-width: 254px;
            width: unset;
            justify-content: flex-start;
            text-transform: uppercase;
            padding-left: 26px;
        }

        .min-w-0 {
            min-width: 0;
        }

        .arrows-short {
            text-transform: uppercase;

            &::after {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(104%);
                bottom: unset;
                width: 7px;
                height: 18px;
                display: block;
                position: unset;
                margin-left: 18px;
            }
        }

        .btn-secondary.arrows::after {
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            right: 15px;
            left: unset;
        }
    }

    .member {
        display: flex;
        margin-top: 40px;
    }

    .member-info {
        display: flex;
        width: 100%;
        margin-left: 4px;

        .form-label {
            font-weight: 700;
            font-size: 13.9978px;
            line-height: 22px;
            margin-bottom: 41px;
            display: block;
        }
    }

    .member-since {
        margin-right: 29px;
        width: 100%;
        max-width: 236px;
    }

    .director-role {
        width: 100%;
        max-width: 236px;
        margin-right: 30px;
    }
}

/* Style tab links */
.tab-link-artist {
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 17px;
    width: 25%;
}

.tab-link-artist:hover {
    background-color: #777;
}

/* Style the tab content (and add height:100% for full page content) */
.tab-resource-artist {
    display: none;
    margin-top: 70px;
}

@media screen and (max-width: 1110px) {
    .profile-management {
        .b-name-abn, .b-email-phone, .b-website-location {
            margin-bottom: 40px;
            flex-direction: column;
            row-gap: 40px;

            .form-box {
                margin-bottom: 0;
                margin-right: 0;
                max-width: unset;
            }
        }

        .form-box-email, .form-box-phone, .form-box-website, .form-box-location, .business-address {
            width: 100%;
            max-width: unset;

            input {
                max-width: unset;
            }
        }

        .membership-application-form {
            .form-group {
                .form-control {
                    width: 100%;
                    max-width: unset;
                }
            }

            .business-address {
                .form-group {
                    input {
                        max-width: 445px;
                    }
                }
            }
        }

        .list-all-artwork {
            .list-wrapper {
                .form-check {
                    width: 100%;
                    max-width: unset;
                }
            }
        }
    }
    .tab-content {
        .member {
            flex-direction: column;
            row-gap: 30px;

            .note-wrapper {
                max-width: unset;
            }
        }

        .member-since {
            max-width: unset;
        }

        .director-role {
            max-width: unset;
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 992px) {
    .panel.artists {
        padding: 20px;
        padding-bottom: 48px;
    }

    .artist-back-arrow {
        top: 10px;
    }
}

@media screen and (max-width: 767px) {
    .artists-start {
        .action {
            flex-direction: column;
            row-gap: 24px;
            margin-top: 40px;

            .link-action {
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
    }
    .profile-management {
        .read-more {
            display: none;
        }

        .upload-label {
            margin-top: 3px;
            margin-bottom: 12px;
        }

        .membership-application-form {
            .business-address {
                .form-group {
                    input {
                        max-width: unset;
                    }
                }
            }
        }

        .social-links {
            .form-group {
                max-width: unset;
            }
        }

        .name-role {
            flex-direction: column-reverse;
            display: flex;

            .name {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .role {
                font-size: 14.0043px;
                line-height: 18px;
                color: #939598;
                width: 100%;
            }
        }
    }
    .artist-management {

        .members {
            .action {
                flex-direction: column;
                row-gap: 24px;
            }

            .btn {
                width: 100%;
            }

            .button-action + .button-action {
                margin-left: 0;
            }

        }
    }
    .tab-resource-artist {
        .page-title-wrapper {
            margin-bottom: 0;
        }
    }
    .tab-content-profile {
        .profile-image {
            .read-more {
                display: none;
            }

            .cabinet {
                figure {
                    min-width: 62px;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .profile-management {
        .search-members {
            margin-bottom: 38px;
            flex-direction: column;

            .association-search {
                margin-right: 0;
                margin-bottom: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-bottom: 7px;
                font-size: 16px;
                line-height: 22px;
            }

            .search-input {
                img {
                    top: 5px;
                }
            }
        }
    }
    .members {
        .member-info {
            flex-direction: column;
            row-gap: 24px;
        }
    }
}

@media screen and (max-width: 480px) {
    .profile-management {
        .association-column {
            .approve-request {
                font-size: 0;
                background: none;

                &::after {
                    content: "";
                    width: 25px;
                    height: 25px;
                    background: url("../../public/images/approve_icon.svg") center/100% no-repeat;
                    min-width: unset;
                    display: block;
                }
            }

            .delete-request {
                font-size: 0;
                background: none;
                margin-left: 10px;

                &::after {
                    content: "";
                    width: 25px;
                    height: 25px;
                    background: url("../../public/images/remove_icon.svg") center/100% no-repeat;
                    min-width: unset;
                    display: block;
                }
            }
        }

        .list-associations {
            .association-column:last-child {
                min-width: unset;
            }
        }
    }
    .tab-content {
        .member-info {
            .form-label {
                margin-bottom: 24px;
            }
        }
    }
    .panel.artists {
        .tab-contents {
            .membership-dashboard {
                padding: 20px;
            }
        }
    }
    .artists-start {
        .button-action {
            .btn {
                width: 100%;
            }

            .arrows-short::after {
                position: absolute;
                right: 15px;
            }
        }
    }
    .artist-management {
        .cancel-app {
            margin-bottom: 0;
        }

    }
}
