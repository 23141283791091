.body {
    overflow: unset;
}

.blocker {
    background-color: unset;
    padding-bottom: 44px;
    z-index: 101;
}

.blocker::before {
    vertical-align: bottom;
}

.modal {
    width: 100%;
    max-width: 728px;
    text-align: center;
    box-sizing: border-box;
    padding: 47px 66px 59px;
    color: #fff;
    background-color: #000;
    border-radius: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.popup-news-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal a.close-modal {
    top: 15px;
    right: 14px;
    background-image: url("../../public/images/close-popup-button.svg");
    width: 15px;
    height: 14px;
}

.g-content__popup-title {
    margin-bottom: 24px;
    font-weight: 400;
    height: 36px;
}

.g-content__popup-description {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    min-height: 90px;
}

@media screen and (max-width: 560px) {
    .modal {
        text-align: left;
        padding: 67px 56px 48px;
    }
    .g-content__popup-title {
        height: unset;
    }
    .blocker {
        padding: 0;
    }
    .g-content__popup-title {
        font-weight: 700;
    }
}

@media screen and (max-width: 480px) {
    .modal a.close-modal {
        width: 24px;
        height: 24px;
        top: 24px;
        right: 24px;
    }
    .g-content__popup-title {
        margin-bottom: 33px;
    }
}
