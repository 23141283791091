.privacy-policy-page,
.terms-conditions-page {
    .g-intro__title {
        color: $orange;
        letter-spacing: 0.2px;
        padding-top: 42px;
        padding-bottom: 54px;
    }
    .g-intro__shortdesc {
        max-width: 920px;
    }
    .f-body {
        margin-bottom: 40px;
        h3,
        .contact-info {
            font-size: 18px;
            line-height: 24px;
            // padding-top: 38px;
        }
        ul {
            li {
                margin-bottom: 1px;
            }
            &.personal-info1 {
                padding-bottom: 27px;
            }
            ul{
                list-style-type: circle;
            }
        }
        // p {
        //     padding-top: 8px;
        // }
    }

    .g-intro {
        -webkit-grid-template-columns: auto;
        -moz-grid-template-columns: auto;
        grid-template-columns: auto;
    }

    .ql-editor {
        h3 {
            margin-top: 30px;
            margin-bottom: 8px;
        }

        ul {
            padding-left: 0;
        }
    }
    &+ .g-footer__acknowledge {
        .g-footer__acknowledge-row {
            padding-top: 65px;
            padding-bottom: 64px;
        }
    }
    
}
@media screen and (max-width: 767px) {
    .privacy-policy-page,
    .terms-conditions-page
    {
        .g-intro__title {
            padding-bottom: 40px;
            padding-top: 25px;
        }
        .f-body {
            margin-bottom: 48px;
        }
        &+ .g-footer__acknowledge {
            .g-footer__acknowledge-row {
                padding-top: 47px;
                padding-bottom: 49px;
            }
        }
    }
}
html:not(.no-js) [data-aos=fade-right] {
    transform: translate3d(-20px,0,0);
}
html:not(.no-js) [data-aos=fade-left] {
    transform: translate3d(20px,0,0);
}
html:not(.no-js) [data-aos=fade-up] {
    transform: translate3d(0,20px,0);
}
html:not(.no-js) [data-aos=fade-down] {
    transform: translate3d(0,-20px,0);
}
.line-end{
    height:1px;
    color: #000;
    background-color: #000;
    width: 100%;
    display: flex;
}
