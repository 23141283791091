.artist-dealer-supporter {
    .g-intro {

        .owl-nav .owl-prev:hover .prev-slide,
        .owl-nav .owl-next:hover .next-slide {
            transform: initial;
        }
    }

    .line-end {
        display: none;
    }

    .owl-nav button.disabled {
        .nav-btn {
            opacity: 0;
        }
    }

    .buttons--container .btn,
    .g-intro__buttons .btn {
        padding: 9px 28px;
    }

    sub {
        font-weight: 700;
    }

    .image-caption,
    .g-intro__image .image-caption {
        font-style: italic;
        font-size: 14px;
        line-height: 16px;
        color: #939598;
    }

    .owl-theme .owl-dots .owl-dot {
        span {
            background-color: #c4c4c4;
        }

        &.active {
            span {
                background-color: #939598;
            }
        }
    }

    .owl-nav {
        display: flex;
        justify-content: space-between;
        position: absolute;

        .nav-btn {
            transition: 0.5s;
        }

        .prev-slide:before {
            font-family: "Font Awesome 5 Pro";
            content: "\f053";
            font-size: 20px;
            font-weight: 300;
            color: $black;
        }

        .next-slide:before {
            font-family: "Font Awesome 5 Pro";
            content: "\f054";
            font-size: 20px;
            font-weight: 300;
            color: $black;
        }

        svg {
            transition: 0.5s;
        }

        .owl-next {
            &:hover {
                background-color: transparent;

                .next-slide,
                svg {
                    transform: translateX(5px);
                }
            }
        }

        .owl-prev {
            &:hover {
                background-color: transparent;

                .prev-slide {
                    transform: translateX(-5px);
                }

                svg {
                    transform: rotate(180deg) translateX(5px);
                }
            }
        }
    }

    .image-caption {
        margin-top: 10px;
    }

    // intro
    .g-intro {
        margin-bottom: 22px;

        .grid-box {
            display: flex;
        }

        .g-intro__title {
            color: $orange;
            margin-right: -10px;
            margin-bottom: 24px;
            padding-top: 15px;
        }

        .g-intro__content {
            .g-intro__image--mobile {
                display: none;
            }

            .g-intro__buttons {
                .btn {
                    display: block;
                }
            }
        }
    }

    //question-by-artist
    .g-question-by-artist {
        background-color: #ececec;

        .o-section-block-inner {
            display: block;
        }

        .content-faqs {
            .question-title {
                padding-top: 10px;

                .title {
                    font-size: 34px;
                    font-weight: 400;
                    line-height: 44px;
                    margin-bottom: 35px;
                }
            }

            .f-body {
                font-size: 16px;
                line-height: 22px;
                font-style: italic;
                margin-bottom: 35px;
                text-align: center;
            }

            .arrows-long.arrows {
                margin-right: 58px;
                display: inline-block;
            }
        }

        .owl-stage-outer {
            padding-top: 4px;
        }

        .owl-nav {
            left: -93px;
            right: -93px;
            top: 0;
            margin-top: 0;
            transform: none;
            width: initial;
        }

        .owl-dots {
            margin-bottom: 22px;

            .owl-dot span {
                margin: 5.2px;
            }
        }
    }

    // video-section
    .video-section {

        .flex-box {
            padding-bottom: 30px;

            .title {
                color: #008065;
            }

            .subtitle {
                p:first-child strong {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .mobile-title {
                display: none;
            }
        }

        .play {
            height: 77px;
            left: 50%;
            margin-left: -38px;
            margin-top: -38px;
            position: absolute;
            top: 50%;
            width: 77px;
            background: url(/images/play-button.svg) no-repeat center;

            &:hover {
                background: url(/images/play-button-hover.svg) no-repeat center;
            }
        }

        .video--caption {
            width: 70%;
        }

        .youtube .thumb {
            position: unset;
        }

        .video--iframe {
            aspect-ratio: 16/9;
            position: relative;
            overflow: hidden;

            .m-media {
                height: 100%;
            }

            .m-media__img {
                height: 100%;

                img {
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    //resource-link section
    .resources {
        padding-bottom: 20px;

        h2 {
            padding-top: 22px;
            padding-bottom: 32px;
        }

        .toggle-button {
            font-size: 16px;
            line-height: 22px;
            font-weight: 700;
        }

        .list-resources {
            display: flex;

            .resources-item {
                width: 33.333%;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                .title-wrapper {
                    display: block;
                    max-width: 80%;
                    margin-bottom: 26px;
                    color: $orange;
                }

                .resource-link {
                    margin-bottom: 24px;
                }
            }
        }

        .arrows {
            position: unset;
        }

        .resource-link {
            position: relative;
        }
    }

    &+.g-content__question-and-link {
        background-color: #ececec;

        .g-content__question-and-link-inner .question-and-link-row {
            border-top: none;

            .g-content_question-item h3 {
                margin-bottom: 71px;
            }
        }
    }
}

//style faqs section for reuse
.g-frequently-question {
    .frequently-question-title {
        text-align: center;
        left: 0.2px;
        margin-bottom: 58px;
        padding-top: 47px;
    }

    .faq-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 50px;
        padding-bottom: 38px;

        .faq-item {
            margin-bottom: 40px;
            max-width: 254px;
            padding-bottom: 40px;
            position: relative;
            width: 25%;

            &:not(:first-child) {
                margin-left: 31px;
            }

            &:nth-child(4n + 1) {
                margin-left: 0;
            }

            .faq-button {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
            }

            .faq-title {
                display: block;
                height: unset;
                padding-bottom: 24px;
            }
        }
    }
}

// section g-question-by-artist
.g-question-by-artist {
    .content-faqs {
        text-align: center;
        padding-top: 18px;
        padding-bottom: 25px;

        .question-title {
            padding-top: 10px;

            .title {
                font-size: 34px;
                font-weight: 400;
                line-height: 44px;
                margin-bottom: 35px;
            }
        }

        .f-body {
            font-size: 16px;
            line-height: 22px;
            font-style: italic;
            margin-bottom: 35px;
            text-align: center;
        }

        .arrows-long.arrows {
            margin-right: 58px;
            display: inline-block;
            margin-bottom: 22px;
        }
    }

    .owl-stage-outer {
        padding-top: 4px;
    }

    .owl-nav {
        [class*="owl-"]:hover {
            background: transparent;
        }
    }

    .owl-dots {
        margin-bottom: 22px;

        .owl-dot span {
            margin: 5.2px;
        }
    }
}

//text-image section for artist-dealer-suppoter, about-us
.artist-dealer-supporter,
.about-us-page {
    .image-Text .o-section-block-inner {
        .g-section__description .f-body-wrapper .f-body {
            margin-bottom: 40px;
        }
    }

    .o-section-block {

        .buttons--container {
            display: flex;
        }

        .featured-artist {
            color: $orange;
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            max-width: 110px;
            position: absolute;
        }

        .featured-artist-language {
            font-style: italic;
            margin-bottom: 15px;

            &+.title.desktop {
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 6px;
            }
        }

        li::marker {
            font-size: 12px;
        }
    }
}

.artist-dealer-supporter,
.public-profile-wrapper {

    //Browse artist member
    .g-member {
        background-color: #ececec;
        position: relative;

        h2 {
            padding-top: 40px;
        }

        .form-control {
            border-bottom: none;
        }

        .owl-prev {
            transition: 0.5s;

            svg {
                transform: rotate(180deg);
            }

            // &:hover {
            //     transform: translateX(-5px);
            // }
        }

        .owl-next {
            transition: 0.5s;
            // &:hover {
            //     transform: translateX(5px);
            // }
        }

        .form-group {
            .form-inputs {

                .input--container {
                    border-bottom: 1px solid #000;
                    width: 100%;

                    &:after {
                        content: "\f002";
                        font-size: 13px;
                        font-family: "Font Awesome 5 Pro";
                    }

                    input {
                        width: 95%;
                        border-bottom: none;
                    }
                }

                .custom-select {
                    width: 100%;
                    margin-left: 32px;

                    .selected-item:before {
                        background-color: transparent;
                    }
                }

                .input--container input {
                    padding-bottom: 0;
                }
            }

            .buttons {
                display: flex;
                justify-content: right;

                .btn-primary {
                    padding: 6px 38px;
                }
            }
        }

        .g-member__title {
            color: $orange;
        }

        .item {
            .g-member__item--image {

                img {
                    margin: auto;
                    background-color: #fff;
                }
            }

            .g-member__item--details {
                text-align: center;
            }

            .artist-desc {
                max-width: 240px;
                margin: auto;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 4;
                text-overflow: ellipsis;
            }
        }

        .g-member__slider {
            margin: 40px 0;

            &:not(.aos-animate) {
                .owl-nav {
                    top: 40%;
                }
            }
        }

        .owl-nav {
            top: 23%;
            width: calc(100% + 113px);
            left: -56.5px;
            right: -56.5px;
            margin-top: 0;
            transform: none;
        }

        .artist-name a {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

// section g-artist-list 

.g-artist-list {
    text-align: center;

    .artist-list-title {
        padding-top: 51px;
        margin-bottom: 48px;
    }

    .g-artist-list__slider {
        margin: auto;
        padding-bottom: 60px;
        max-width: 1083px;

        .owl-stage-outer {
            padding-bottom: 39px;
        }

        .owl-dots {
            justify-content: center;

            span {
                margin: 5px;
            }
        }

        .owl-stage {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .owl-item {
                padding-bottom: 71px;

                .btn {
                    position: absolute;
                    bottom: 0;
                    // left: 50%;
                    // transform: translateX(-50%);
                    left: 0;
                    right: 0;
                }
            }
        }

        .owl-nav {
            .prev-slide:before {
                font-family: "Font Awesome 5 Pro";
                content: "\f053";
                font-size: 20px;
                font-weight: 300;
                color: $black;
            }

            .next-slide:before {
                font-family: "Font Awesome 5 Pro";
                content: "\f054";
                font-size: 20px;
                font-weight: 300;
                color: #000;
            }
        }

        .owl-theme .owl-dots .owl-dot span {
            margin: 5px;
        }
    }

    .g-artist-list__item {
        .title {
            margin-bottom: 25px;
            max-width: 210px;
            margin: auto;

            sub {
                display: block;
            }
        }

        .detail {
            font-style: italic;
        }

        .btn {
            margin: auto;
            padding: 9px 28px;
        }
    }
}

//style for artist-page only
.artist-page {
    .g-intro {
        margin-bottom: 66px;
    }

    .g-intro .g-intro__content .g-intro__shortdesc {
        margin-bottom: 54px;
    }

    .g-member+.image-Text-left {
        // margin-bottom: 80px;

        h2.title {
            margin-bottom: 6px;
        }

        .image-caption {
            margin-top: 23px;
            letter-spacing: 0px;
            max-width: 80%;
        }

        .f-body {
            margin-bottom: 42px;
        }

        .g-section__image-left {
            padding-top: 30px;
        }
    }

    //text-image-right section
    .section-6 {
        margin-bottom: 76px;

        .o-section-block-inner {
            display: flex;
        }

        .g-section__image--mobile {
            display: none;
        }

        .g-section__image-right {
            width: 50%;
        }

        .g-section__description {
            width: 47.225%;
            margin-right: 60px;
        }

        h2.title {
            color: #004b87;
            padding-top: 35px;
            padding-bottom: 22px;
        }

        .g-section__description .f-body-wrapper .f-body {
            margin-bottom: 54px;

            strong {
                font-size: 18px;
                line-height: 24px;
                display: inline-block;
                // margin-bottom: 26px;
            }
        }

        // .btn-primary {
        //     padding: 9px 13px;
        // }
        .image-caption {
            margin-top: 23px;
        }
    }

    .video-section .line-end {
        display: block;
    }
}

@media (min-width: 768px) {

    .artist-dealer-supporter,
    .public-profile-wrapper {
        .form-group {
            .form-inputs {
                .input--container {
                    max-width: calc(60% - 16px);
                    margin-right: 16px;
                }

                .custom-select {
                    max-width: calc(40% - 16px);
                    margin-left: 16px;
                }
            }
        }
    }
}

@media (min-width: 1181px) {

    .artist-dealer-supporter,
    .public-profile-wrapper {
        .form-group {
            .buttons {
                max-width: 165px;
                width: 100%;
                margin: 0;

                a {
                    margin: 0 0 0 32px;
                }
            }

            .form-inputs {
                max-width: calc(100% - 165px);
            }
        }
    }
}

// reponsive question-link section
@media only screen and (max-width: 1300px) {
    .artist-dealer-supporter {
        .g-intro {
            .grid-box .g-intro__content .g-intro__title {
                margin-right: 0px;
            }
        }

        .g-artist-list {
            .g-artist-list__slider {
                width: 90%;
            }
        }

        .g-question-by-artist {
            .g-question-by-artist__slider {
                width: 90%;
                margin: auto;
            }

            .owl-nav {
                left: -60px;
                right: -60px;
            }
        }

        .resources {
            .resource-link {
                padding-right: 45px;
            }
        }
    }

    .artist-dealer-supporter,
    .public-profile-wrapper {
        .g-member {
            .g-member__slider {
                width: 90%;
                margin-right: auto;
                margin-left: auto;

                &>p {
                    margin-left: -5.5%;
                }
            }
        }
    }
}

//responsive form-group in browse-member section
@media only screen and (max-width: 1180px) {

    .artist-dealer-supporter,
    .public-profile-wrapper {
        .g-member .form-group {
            display: block;

            .form-inputs {
                width: 100%;
            }

            .buttons {
                width: 100%;
                margin-top: 26.5px;
                justify-content: unset;
            }
        }
    }

    .g-frequently-question .faq-content .faq-item {
        width: calc((100% - 93px) / 4);
    }
}

@media only screen and (max-width: 1180px) and (min-width: 921px) {
    .artist-dealer-supporter {
        .video-section .video--iframe {
            height: unset;
            min-height: 300px;
            position: relative;

            .youtube {
                position: unset;
            }

            iframe {
                min-height: 300px;
            }

            img {
                position: absolute !important;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (max-width: 1058px) {
    .artist-dealer-supporter {
        .g-question-by-artist {
            .g-question-by-artist__slider {
                width: 80%;
            }
        }

        .buttons--container .btn,
        .video-section-button .btn,
        .g-artist-list .btn,
        .g-intro__buttons .btn {
            padding: 9px 20px;
        }

        .section-6 {
            margin-bottom: 20px;
        }

        .o-section-block .g-section__description .title {
            padding-top: 0;
        }
    }

    .artist-dealer-supporter,
    .public-profile-wrapper {
        .g-member {
            .g-member__slider {
                width: 90%;
            }

            .owl-nav {
                margin-top: 0;
                width: calc(100% + 90px);
                left: -45px;
                right: -45px;
            }
        }
    }
}

//style video-text section
@media only screen and (max-width: 920px) {
    .artist-dealer-supporter {
        .video-section {
            .flex-box {
                .mobile-title {
                    display: block;
                    order: 0;
                    font-size: 34px;
                    line-height: 44px;
                    margin-bottom: 24px;
                }

                .article-headings {
                    max-width: 100%;
                    order: 2;
                    margin-right: 0;
                    margin-bottom: 40px;

                    .title {
                        display: none !important;
                    }
                }

                .subtitle {
                    padding-right: 0;
                }
            }

            .article-video {
                order: 1;
                margin: 0 auto 24px auto;
            }

            .video-section-button {
                width: 100%;
                display: flex;
            }
        }

        .g-frequently-question .faq-content .faq-item {
            max-width: unset;

            &:nth-child(3n + 1) {
                margin-left: 0;
            }
        }
    }

    .artist-page {
        .video-section {
            .mobile-title {
                color: #008065;
            }
        }
    }

    .dealer-page,
    .supporter-page {
        .video-section {
            .mobile-title {
                color: #004b87;
            }
        }
    }

    .g-frequently-question .faq-content .faq-item {
        width: calc((100% - 62px) / 3);
        max-width: unset;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }

        &:nth-child(4n + 5) {
            margin-left: 31px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .artist-dealer-supporter {
        margin-top: 16px;
        margin-bottom: 36px;

        .image-caption,
        .g-intro__image--mobile .g-intro__caption,
        .video--caption p {
            font-size: 12px;
            line-height: 14px;
            color: #939598;
            font-style: italic;
        }

        .g-intro {
            margin-bottom: 48px;

            .grid-box .g-intro__content {
                width: 100%;

                .g-intro__title {
                    letter-spacing: 0px;
                }

                .g-intro__image--mobile {
                    display: block;
                }

                .g-intro__caption {
                    margin-top: 12px;
                }

                .g-intro__shortdesc {
                    max-width: 100%;
                    margin-top: 24px;
                    margin-bottom: 11px;

                    .f-body {
                        max-width: 100%;
                    }
                }

                .g-intro__buttons {
                    display: block;

                    .btn {
                        padding: 12px 23px;
                    }
                }

                .btn-primary {
                    margin-bottom: 24px;
                }

                .image--frame img {
                    width: 100%;
                }
            }
        }

        .g-question-by-artist {
            margin-bottom: 40px;

            .content-faqs {
                padding-bottom: 68px;

                .question-title {
                    padding-top: 22px;

                    .title {
                        font-size: 28px;
                        line-height: 38px;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                }

                .arrows-long.arrows {
                    margin-right: 0;
                }

                .f-body {
                    margin-bottom: 15px;
                }
            }

            .arrows-long:after {
                bottom: -21px;
                left: 0;
            }
        }

        .video-section {
            margin-bottom: 40px;

            .mobile-title {
                margin-bottom: 24px;
            }

            .video--caption {
                width: 100%;
            }

            .flex-box .article-headings {
                margin-bottom: 7px;

                .subtitle p {
                    margin-bottom: 7px;

                    &:first-child {
                        margin-bottom: 32px;
                    }
                }
            }

            .video-section-button {
                margin-top: 32px;
            }

            .video--iframe {

                // height: 380px;
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .g-artist-list {
            .g-artist-list__item {
                .title {
                    margin-bottom: 25px;

                    .sub {
                        line-height: 22px;
                    }
                }

                .btn {
                    padding: 12px 28px;
                }
            }

            .g-artist-list__slider {
                padding-bottom: 45px;
            }

            .artist-list-title {
                margin-bottom: 38px;
            }

            .owl-stage .owl-item {
                padding-bottom: 80px;
            }

            .g-artist-list__slider .owl-dots {
                margin-top: 36px;
            }
        }


        .resources {
            .list-resources {
                display: block;

                .resources-item {
                    width: 100%;
                    margin-right: 0;

                    &:not(:first-child) {
                        margin-top: 40px;
                    }

                    .title-wrapper {
                        margin-bottom: 18px;
                        position: relative;
                        padding-left: 33px;
                    }

                    .arrows {
                        &:after {
                            left: 33px;
                            margin-left: 0;
                        }
                    }

                    .resource-link {
                        padding-bottom: 30px;
                        margin-bottom: 14px;
                        padding-right: 0;
                    }
                }
            }

            .title-wrapper {
                &:before {
                    font-family: "Font Awesome 5 Pro";
                    content: "\f054";
                    font-size: 20px;
                    font-weight: 300;
                    color: $black;
                    margin-right: 19px;
                    position: absolute;
                    left: 5px;
                    transition: 0.3s;
                }

                &.rotate {
                    transform: unset;
                }

                &.rotate:before {
                    transform: rotate(90deg);
                    display: inline-block;
                    transition: 0.3s;
                }
            }

            .resource-link {
                display: none !important;

                &.appear {
                    display: block !important;
                    padding-left: 33px;
                }

                &:last-of-type {
                    padding-bottom: 20px;
                }
            }

            .toggle-button {
                display: none !important;
            }
        }

        .g-frequently-question {
            .faq-content {
                padding-bottom: 32px;

                .faq-item {
                    max-width: unset;
                    width: 100%;
                    margin-bottom: 50px;

                    &:not(:first-child) {
                        margin-left: 0;
                    }

                    .faq-title {
                        max-width: 350px;
                        margin: auto;
                        margin-bottom: 32px;
                    }
                }
            }
        }

        .buttons--container .btn,
        .video-section-button .btn,
        .g-artist-list .btn,
        .g-intro__buttons .btn {
            padding-top: 6px;
            padding-bottom: 6px;
        }

        .g-intro__buttons,
        .video-section-button,
        .o-section-block .buttons--container,
        .g-member .form-group .buttons {
            .btn {
                width: 100%;
            }

            .f-buttons .btn {
                margin-bottom: 24px;
            }

            .f-buttons:last-child .btn {
                margin-bottom: 0;
            }
        }

        &+.g-content__question-and-link {
            .g-content__question-and-link-inner .question-and-link-row {
                padding-bottom: 68px;

                .g-content_question-item {
                    &:not(:last-child) {
                        margin-bottom: 60px;
                    }

                    a .arrows-long:after {
                        left: 0;
                    }

                    h3 {
                        margin-bottom: 24px;
                    }
                }
            }
        }

        .image-Text {
            .o-section-block-inner {
                display: block;
            }

            .custom-link {
                margin-top: 24px;
            }

            .title.mobile {
                font-size: 34px;
                line-height: 44px;
            }
        }

        .mobile {
            display: none;
        }
    }

    //section text-image
    .artist-dealer-supporter,
    .about-us-page {
        .o-section-block {
            &:not(.image-Text) {
                .o-section-block-inner {
                    display: block;
                }
            }
            .o-section-block-inner {
                .g-section__description {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .buttons--container {
                display: block;
            }

            .g-section__image-left {
                width: 100%;
            }

            .g-section__image--rounded-frame,
            .g-section__image {
                img {
                    width: 100%;
                }
            }
        }
    }

    .artist-dealer-supporter,
    .public-profile-wrapper {
        .g-member {
            padding-bottom: 48px;
            margin-bottom: 0;

            .form-group {
                .form-inputs {
                    width: 100%;
                    display: block;

                    .custom-select {
                        max-width: 100%;
                        width: 100%;
                        margin-left: 0;

                        .selected-item,
                        .all-items .item {
                            font-size: 16px;
                            line-height: 22px;
                        }

                        .selected-item:after {
                            top: 63%;
                            right: 5px;
                        }
                    }
                }

                .buttons {
                    display: block;
                    margin-top: 40px;
                }

                .form-inputs .input--container {
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 14px;

                    .form-control {
                        width: calc(100% - 31px);
                        height: 38px;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }

            .g-member__slider {
                margin-top: 46px;
            }

            .owl-nav {
                margin-top: 0;
                width: calc(100% + 40px);
                left: -20px;
                right: -20px;
            }
        }
    }

    .artist-page {
        .image-Text-left.section-5 {
            margin-bottom: 32px;

            .o-section-block-inner,
            .title.desktop {
                display: block;
            }

            .title.mobile {
                display: none;
            }

            .title.desktop {
                margin-bottom: 22px;
            }

            .g-section__image-left {
                padding-top: 24px;
            }

            .g-section__image--rounded-frame {
                margin-top: 36px;
            }

            .f-body.image-caption {
                margin-top: 12px;
                margin-bottom: 32px;
            }

            .g-section__description .f-body {
                margin-bottom: 32px;
            }

            .image-caption {
                max-width: 100%;
            }
        }

        .image-Text-right {
            margin-bottom: 62px;

            .o-section-block-inner,
            .g-section__image--mobile,
            .title.mobile {
                display: block;
            }

            .g-section__image-right,
            .title.desktop {
                display: none;
            }

            .title.mobile {
                font-size: 34px;
                line-height: 44px;
            }

            .g-section__image img {
                aspect-ratio: 311/221;
            }

            .g-section__image--mobile .f-body.image-caption {
                margin-top: 12px;
                margin-bottom: 24px;
            }

            .g-section__description .f-body {
                margin-bottom: 32px;

                strong {
                    margin-bottom: 8px;
                }
            }
        }

        .section.resources h2 {
            padding-top: 26px;
            padding-bottom: 20px;
        }
    }

    .g-frequently-question .faq-content .faq-item {
        width: 100%;
        margin-left: 0 !important;

        .faq-title {
            height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            margin-bottom: 32px;
        }
    }
}

.homepage .video-section .mobile-title {
    display: none;
}

.owl-theme .owl-nav .disabled {
    opacity: 0.5 !important;
}

//style for animation only
.artist-page .o-grid-block.section-8 .f-buttons {
    display: block;
}

.artist-dealer-supporter {

    .g-intro__image>*,
    .g-intro__content .g-intro__title,
    .g-intro__content .g-intro__shortdesc p,
    .g-intro__buttons .btn {
        display: none;
    }
}

.artist-dealer-supporter {
    visibility: hidden;
}

//fix layout for error-page
.homepage.error-page .container {
    width: fit-content;
}

// update style guide

.g-member__item--image img {
    border: 1px solid $color-text3;
    border-radius: 50%;
    overflow: hidden;
}

//general style for grid section
.o-grid-block {
    .o-grid-title {
        font-size: 42px;
        line-height: 51px;
        letter-spacing: 0.2px;
        text-align: center;
        max-width: 770px;
        margin: auto;
        padding-top: 40px;
        // padding-bottom: 40px;
    }

    .o-grid-block-inner {
        display: flex;
        padding-top: 42px;
        padding-bottom: 37px;
        @include css-prefix((flex-wrap: wrap));
        flex-wrap: wrap;

        .o-grid-item {
            width: calc(50% - 20px);
            position: relative;
            padding-bottom: 20px;

            .f-body {
                ul:only-child {
                    padding-left: 0;
                }
            }

            .btn {
                display: inline-block;
                line-height: 28px;
                text-align: center;
                margin-bottom: 20px;

                &.btn-secondary {
                    background-color: $white;

                    &:hover {
                        background-color: $black;
                        color: $white;
                    }
                }

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }


            &.actions-toolbar {
                display: inline-block;
                padding-bottom: 0;

                &:first-child {
                    text-align: right;
                }
            }
        }
    }

    .o-grid-block-content,
    .o-grid-block-buttons {
        width: 100%;
        @include display-flex-cs();
        flex-wrap: wrap;
        gap: 40px;
    }
}

//Check-list type
.o-grid-block.check-list {
    li::marker {
        font-size: 12px;
    }

    .o-grid-block-inner {
        .o-grid-item {
            .f-body {
                strong {
                    font-size: 18px;
                    line-height: 24px;
                    display: block;
                    margin-bottom: 8px;
                }
            }

            ul,
            ul:only-child {
                padding-left: 1.7rem;
            }

            li {
                position: relative;
                padding-left: 0.5rem;

                &:before {
                    content: "";
                    background: url(../../public/images/icon-list-v.png) center/100% 100% no-repeat;
                    width: 23px;
                    height: 20px;
                    position: absolute;
                    top: 2px;
                    left: 1px;
                }
            }

            &.text-white {
                li:before {
                    filter: invert(97%) sepia(5%) saturate(457%) hue-rotate(201deg) brightness(118%) contrast(100%);
                }
            }
        }
    }
}

.o-grid-block.alpha-list,
.o-grid-block.roman-list {
    .o-grid-block-inner .o-grid-item {

        ul,
        ol {
            &:only-child {
                padding-left: 1.7rem;
            }

            li {
                list-style-type: inherit;
                padding-left: 1rem;

                &:before {
                    content: "";
                }
            }
        }
    }
}

.o-grid-block.alpha-list .o-grid-block-inner .o-grid-item {

    ul,
    ol {
        list-style-type: lower-alpha;
    }
}

.o-grid-block.roman-list .o-grid-block-inner .o-grid-item {

    ul,
    ol {
        list-style-type: lower-roman;
    }
}

.o-grid-block.step-list {
    .o-grid-title {
        max-width: 100%;
        text-align: left;
    }

    .o-grid-item ol {
        counter-reset: my-awesome-counter;
        display: flex;
        gap: 15px;
        margin-top: 35px;
        flex-wrap: wrap;

        li {
            position: relative;
            counter-increment: my-awesome-counter;
            margin-bottom: 0;
            padding: 32px 15px 20px;
            text-align: center;
            display: block;
            font-size: 18px;
            line-height: 24px;
            flex: 1;
            overflow-x: clip;

            &:before {
                background: unset;
                border: 2px solid $orange;
                border-radius: 5px;
                box-sizing: border-box;
                content: "";
                height: 100%;
                margin-left: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            &:after {
                align-items: center;
                background: #fff;
                border: 2px solid;
                border-radius: 50%;
                color: $orange;
                content: counter(my-awesome-counter);
                display: flex;
                font-weight: 700;
                height: 37.5px;
                justify-content: center;
                left: 50%;
                position: absolute;
                top: -19px;
                transform: translateX(-50%);
                width: 37.5px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .o-grid-block {
        .o-grid-title {
            font-size: 34px;
            line-height: 44px;
            padding-top: 20px;
        }

        .o-grid-block-inner {
            display: block;
            position: relative;
            padding-top: 40px;
            padding-bottom: 56px;

            .o-grid-item {
                width: 100%;
                position: unset;
                padding-bottom: 0;

                h3 {
                    font-size: 28px;
                    line-height: 38px;
                }

                .btn {
                    width: 100%;
                    min-height: 48px;
                    line-height: 34px;
                    margin: 20px 0 0;

                    &:not(:first-child) {
                        margin: 20px 0 0;
                    }
                }

                .f-body ul {
                    margin-bottom: 0;
                    padding-bottom: 0;

                    li {
                        padding-left: 8px;
                        margin-bottom: 8px;
                    }
                }
            }

            &.grid-no-button {
                padding-bottom: 20px;
            }
        }

        .o-grid-block-content,
        .o-grid-block-buttons {
            gap: 0;
        }
    }

    .o-grid-block.step-list {
        .o-grid-item ol {
            display: block;
            padding-left: 0;

            li {
                width: 100%;
                max-width: 300px;
                margin: auto;

                &:not(:last-child) {
                    margin-bottom: 50px;
                }
            }
        }
    }
}
