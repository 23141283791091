.supporter {
    .note-form-group {
        p {
            margin-bottom: 26px;
        }
    }
    .upload-image-wrapper{
        .read-more{
            display: none;
        }
    }
}

.membership-application-form {
    .describe-commercial-activity {
        textarea {
            margin-top: 16px;
        }
    }

    .list-relationships-wrapper {
        .label-group {
            margin-bottom: 6px;
        }
    }
}
.renew-step2{

    ol[type="1"]{
        list-style: decimal;
        padding-left: 27px;
        padding-top: 25px;
        li{
            margin-bottom: 25px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    ol[type="a"]{
        list-style: lower-alpha;
        padding-left: 26px;
        padding-top: 1px;
        li{
            margin-bottom: 1px;
        }
    }
    .form-check.big-size{
        margin-top: 55px;
    }
    .action{
        display: flex;
    }
    .button-action{
        width: 50%;
        button{
            white-space: nowrap;
            max-width: 261px !important;
        }
    }
    .link-action{
        margin-left: auto;
    }
}
.supporter-renew-form,
.dealer-renew-form{
    .action{
        display: flex;
    }
    .button-action{
        width: 50%;
        .btn.btn-primary{
            white-space: nowrap;
        }
        .btn.submit-renewal{
            max-width: 261px;
        }
    }
    .link-action{
        align-items: flex-end;
        margin-left: auto;
        margin-top: 0;
    }
    .form-check.big-size{
        margin-top: 24px;
        margin-bottom: 41px;
    }

}

.membership-application-form-wrapper.supporter{
    .membership-form-step7 .form-check2{
        margin-top: 20px;
    }
}

// Desktop
@media only screen and (min-width: 768px) {
    .supporter {
        .membership-form-step6 {
            .button-action .btn-primary {
                max-width: 255px;
            }
        }
    }
}

// Mobile
@media only screen and (max-width: 767px) {
    .membership-application-form {
        .describe-commercial-activity .form-label {
            margin-bottom: 11px;
        }
        &.membership-form-step2 {
            .note-form-group {
                font-size: 18px;
            }
        }
    }
    .supporter-renew-form,
    .dealer-renew-form{
        .action{
            flex-direction: column;
        }
        .button-action{
            width: 100%;
            margin-bottom: 40px;
            .btn.btn-primary{
                max-width: unset;
            }
        }
    }
    .renew-step2{
        .action{
            flex-direction: column;
        }
        .button-action{
            width: 100%;
            button{
                max-width: unset !important;
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .supporter-renew-form,
    .dealer-renew-form{
        .button-action{
            margin-bottom: 24px;
        }
    }
}
