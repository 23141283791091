.contact-page {
    margin-top: 39px;
    margin-bottom: 48px;

    .mail-form {
        textarea.parsley-error {
            border: 1px solid #e74c39;
        }
    }

    .contact-main {
        justify-content: space-between;
        padding-bottom: 81px;
    }

    .owl-nav {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: calc(100% + 40px);
        top: 18%;
        left: 50%;
        transform: translateX(-50%);

        [class*=owl-] {
            &:hover {
                background: unset;
                color: unset;
            }

            margin: 0;
        }

        button.disabled {
            svg {
                opacity: 0;
            }
        }

        .owl-prev {
            svg {
                transform: rotate(180deg);
                transition: all .5s;
            }

            &:hover {
                svg {
                    transform: rotate(180deg) translateX(5px);
                }
            }
        }

        .owl-next {
            svg {
                transition: all .5s;
            }

            &:hover {
                svg {
                    transform: translateX(5px);
                }
            }
        }
    }

    .owl-theme .owl-dots span {
        margin: 0;
        background-color: #c4c4c4;
    }

    .owl-dots {
        margin-top: 56px !important;
        display: flex;
        justify-content: center;

        .owl-dot.active {
            span {
                background-color: #939598;
            }
        }
    }

    .owl-dot + .owl-dot {
        margin-left: 10px;
    }

    .information {
        width: 100%;
        max-width: 50%;

        h1 {
            margin-bottom: 54px;
        }

        sub {
            display: block;
            margin-bottom: 30px;
        }
    }
}

.grid-box {
    display: grid;
}

.faqs-list-slider {
    width: 90%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;

    &.owl-carousel {
        display: none;
    }
}

.contact-details {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
}

.scroll-to-faq--mobile.arrows-long:after,
.scroll-to-faq.arrows-long:after {
    transform: rotate(90deg);
    bottom: -40px;
    left: -18px;
}

.scroll-to-faq.arrows-long:hover:after {
    transform: rotate(90deg) translateX(5px);
}

.social-icons {
    display: flex;
    margin-top: 24px;
    margin-bottom: 45px;

    a {
        background: none;

        & + a {
            margin-left: 20px;
        }
    }
}

.scroll-to-faq {
    cursor: pointer;
    background: none;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.scroll-to-faq--mobile {
    display: none;
    background: none;
}

.organization {
    font-weight: bold;
}

.mail-form {
    width: 100%;
    max-width: 540px;
    box-sizing: border-box;
    padding: 19px 23px 32px 31px;
    border: solid 2px #000;
    background-color: #ECECEC;
    min-height: 531px;

    h2 {
        padding-bottom: 7.48px;
        border-bottom: solid 1px #000;
    }

    .success-message {
        margin-top: 26px;
        margin-bottom: 0;
    }

    .success-title {
        font-family: $font-family-sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 26px;
    }

    .form-control {
        display: block;
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        height: 32px;
    }

    .form-contact {
        position: relative;
    }

    .form-group {
        margin-top: 30px;

        .message-label {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }

        textarea {
            display: block;
            margin-top: 12px;
            width: 100%;
            cursor: text;
            outline: none;
            border: 1px solid #0A0A0B;
            background: rgba(0, 0, 0, 0.0001);
            font-family: $font-family-sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14.0043px;
            line-height: 18px;
            resize: none;
            height: 137px;
            padding: 9px 7.65px 25px 9px;
            border-radius: 6px;
        }
    }

    .notice,
    .notice > a {
        margin-top: 12px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.3px;
        color: #3D4041;
    }

    .submit-mail-form {
        margin-top: 32px;

        .btn {
            text-transform: uppercase;
            min-width: 162px;
        }
    }
}

.contact-faqs {
    padding-top: 46px;

    h1 {
        text-align: center;
    }
}

.faqs-list {
    margin-top: 58px;
    grid-template-columns: 254px 254px 254px 254px;
    column-gap: 31px;
    row-gap: 96px;
}

.faq-item {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #E74C39;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.faq-title {
    max-width: 254px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.faq-button {
    margin-top: 24px;
    text-transform: uppercase;

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 123px;
        margin: auto;

        &:hover {
            cursor: pointer;
        }
    }
}

.faqs-link {
    margin-top: 68px;
    text-align: center;
}

.box-loading {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 20px);
    height: 100%;
    align-items: center;
    background: #ECECEC;
    z-index: 2;
}

.load::after {
    background: none repeat scroll 0 0 #ececec;
}

.link-arrow {
    img {
        display: inline;
        margin-left: 13px;
    }
}

@media screen and (max-width: 1150px) {
    .faqs-list {
        display: none;
    }
    .faq-title {
        max-width: unset;
    }
    .faqs-list-slider {
        margin-top: 58px;

        &.owl-carousel {
            display: block;
        }
    }

    .contact-page {
        .owl-carousel .owl-stage {
            display: flex;
        }
    }
}

@media screen and (max-width: 768px) {
    .contact-page {
        .contact-main {
            flex-direction: column;
            padding-bottom: 112px;
        }

        .information {
            max-width: unset;

            sub {
                margin-bottom: 8px;
            }
        }

        .contact-details {
            width: unset;
            white-space: unset;
        }
    }
    .mail-form {
        margin-left: 0;
        max-width: unset;
        min-height: unset;
        padding-left: 15px;
        padding-right: 15px;

        .form-group {
            textarea {
                width: 100%;
                max-height: 108px;
                padding: 10px 17px 18px 11px;
            }
        }
    }
    .scroll-to-faq--mobile {
        display: block;
        text-align: center;
        margin-top: 48px;
        width: 100%;
    }
    .scroll-to-faq {
        display: none;
    }
    .scroll-to-faq--mobile.arrows-long:after {
        left: 50%;
        transform: rotate(90deg) translateY(calc(-50% + 43px));
    }
    .scroll-to-faq--mobile.arrows-long:hover:after {
        left: 50%;
        transform: rotate(90deg) translateY(calc(-50% + 43px));
    }
}

@media screen and (max-width: 555px) {
    .faqs-list {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 480px) {
    .faqs-list-slider {
        margin-top: 22px;
    }
    .contact-page {
        margin-top: 13px;
        margin-bottom: 68px;
    }
    .information {
        h1 {
            margin-bottom: 40px;
        }
    }
    .social-icons {
        margin-top: 32px;
        margin-bottom: 49px;
    }
    .contact-faqs {
        padding-top: 38px;

        h1 {
            font-size: 28px;
            line-height: 38px;
        }
    }
    .faq-title {
        height: unset;
        -webkit-line-clamp: 2;
    }
    .faq-button {
        margin-top: 32px;
    }
    .faqs-link {
        margin-top: 32px;
    }
    .link-arrow {
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 8px;
        }
    }
    .mail-form {
        padding-bottom: 24px;

        h2 {
            padding-bottom: 16px;
        }

        .form-group {
            textarea {
                margin-top: 8px;
                padding: 9px 14px 17px 11px;
            }
        }

        .submit-mail-form {
            margin-top: 25px;

            .btn {
                min-width: unset;
                width: 100%;
            }
        }
    }
}
