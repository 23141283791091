.public-profile-body {
    -webkit-tap-highlight-color: transparent;

    .line {
        border: none;
        border-top: 1px solid;
        border-color: $black;
    }

    .mfp-wrap {
        height: 100% !important;
        overflow: visible !important;
    }

    &.mfp-zoom-out-cur {
        overflow: hidden;
    }

    .mfp-s-ready .mfp-preloader {
        display: none !important;
    }

    &.mfp-zoom-out-cur,
    &.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
        cursor: pointer;
    }

    .mfp-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-top: 18px;
        padding-right: 80px;

        > p {
            margin-bottom: 26px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .mfp-preloader {
        display: none !important;
    }

    .mfp-figure {
        position: relative;

        &:after {
            box-shadow: none;
            background: transparent;
        }
    }

    .mfp-bg {
        background: rgba(0, 0, 0, .75);
    }

    .mfp-arrow,
    .mfp-close-btn-in .mfp-close {
        opacity: 1;
    }

    .gallery-desktop {
        .mfp-arrow {
            top: 47px;
            bottom: auto;
            margin: 0;
        }

        .mfp-arrow-left {
            right: 70px;
            left: auto;
        }

        .mfp-arrow-right {
            right: 22px;
        }
    }

    .mfp-image-holder .mfp-close {
        font-size: 0;
        background: url("../../public/images/icons/close-menu-mobile.svg") right/15px 15px no-repeat;
        position: static;
        margin-bottom: 15px;
        height: 18px;
    }

    .mfp-arrow {
        transform: none;
        width: 0;
        height: 0;
        margin: 0;

        &:before {
            font-family: $font-awesome-pro;
            font-size: 37px;
            color: $white;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            opacity: 1;
            margin: 0;
            width: 30px;
            height: 30px;
        }
    }

    .mfp-arrow-left:before {
        content: '\f053';
        margin-left: 0;
    }

    .mfp-arrow-right:before {
        content: '\f054';
    }

    .mfp-arrow-left {
        left: -30px;
    }

    .mfp-arrow-left,
    .mfp-arrow-right {
        &:after {
            display: none;
        }
    }

    .mfp-container {
        display: flex;
        padding: 0 20px;
    }

    .mfp-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    figure img {
        width: 100%;
        max-height: 744px !important;
        display: flex;
        flex-direction: column;
    }

    figcaption {
        display: inline-block;
        width: 100%;
        position: relative;
    }

    .mfp-bottom-bar {
        margin-top: 0;
        position: static;
    }

    img.mfp-img {
        max-height: 100% !important;
        width: 100%;
        object-fit: contain;
        padding: 0;
    }

    .g-content__question-and-link-inner .question-and-link-row {
        border: none;
    }
}

.public-profile-wrapper {
    margin-top: 34px;

    .g-member {
        .line-end {
            display: none;
        }

        .artist-name {
            font-weight: 700;
        }
    }

    .btn {
        text-transform: uppercase;
    }

    .info {
        .browse-atists {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .image-profile img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .image-profile {
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        border: 1px solid $color-text3;
    }

    .name {
        text-transform: capitalize;
    }

    .right-content {
        h1.name {
            letter-spacing: .2px;
        }

        .language-group {
            font-style: italic;
        }

        h1.name,
        .language-group {
            margin-top: 12px;
        }
    }

    .share-article {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 9px 0 14px;
        margin: 17px 0;

        .business-name {
            padding-right: 20px;
        }

        .business-name,
        .social {
            flex-basis: 50%;
        }
    }

    .social {
        display: flex;
        flex-wrap: wrap;

        a {
            position: relative;
            font-size: 0;
            width: 30px;
            height: 30px;
            display: inline-block;
            background: none;

            &:hover {
                opacity: .8;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 30px;
                height: 30px;
                display: block;
            }
        }

        .facebook:after {
            background: url("../../public/images/icons/facebook-new.svg") center/100% 100% no-repeat;
        }

        .instagram:after {
            background: url("../../public/images/icons/instagram-new.svg") center/100% 100% no-repeat;
        }

        .twitter:after {
            background: url("../../public/images/icons/twitter-new.svg") center/100% 100% no-repeat;
        }

        .linkedin:after {
            background: url("../../public/images/icons/linkedin-new.svg") center/100% 100% no-repeat;
        }

        .tiktok:after {
            background: url("../../public/images/icons/tiktok-new.svg") center/100% 100% no-repeat;
        }

        .pinterest:after {
            background: url("../../public/images/icons/pinterest-new.svg") center/100% 100% no-repeat;
        }

    }

    .list-artwork-types {
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
        margin-top: 44px;
        color: $color-focus-input;
    }

    strong.label {
        font-size: 18px;
        line-height: 24px;
    }

    .business-name {
        .address,
        .start-date {
            font-style: italic;
        }

        .start-date {
            color: $orange;
        }
    }

    .info-contact {
        max-width: 500px;

        li {
            position: relative;
            padding-left: 95px;
            min-height: 22px;
            margin-bottom: 8px;
            word-break: break-word;
        }

        li label {
            position: absolute;
            top: 0;
            left: 0;
        }

        p {
            margin-bottom: 7px;
        }
    }

    .contact-detail {
        .label {
            color: $orange;
            margin-top: 15px;
            display: inline-block;
        }

        .info-contact {
            margin-top: 19px;
        }
    }

    .grid-items {
        gap: 30px;

        .grid-item {
            display: none;
            margin-bottom: 25px;
            width: 100%;
            break-inside: avoid;
        }

        a {
            background: transparent;
            display: flex;
            width: 100%;
        }

        .grid-item .content {
            border-top: 1px solid $black;
            margin-top: 24px;
            font-size: 18px;
            line-height: 24px;
            padding: 10px 0 0;
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .artworks-action {
        display: flex;
        margin: 55px 0 36px;
    }

    .show-more-artworks {
        margin-right: 53px;
    }

    .artworks-paginate {
        color: $color-text3;
    }

    //Browse artist member
    .owl-nav {
        display: flex;
        justify-content: space-between;
        position: absolute;

        .nav-btn {
            transition: 0.5s;
        }

        .prev-slide:before {
            font-family: "Font Awesome 5 Pro";
            content: "\f053";
            font-size: 20px;
            font-weight: 300;
            color: $black;
        }

        .next-slide:before {
            font-family: "Font Awesome 5 Pro";
            content: "\f054";
            font-size: 20px;
            font-weight: 300;
            color: $black;
        }

        .owl-prev,
        .owl-next {
            &:hover {
                background-color: transparent;

                .next-slide {
                    transform: translateX(5px);
                }

                .prev-slide {
                    transform: translateX(-5px);
                }
            }
        }
    }

    .g-content__question-and-link-inner .question-and-link-row {
        border: none;
    }

    // Artist
    &.artist {
        .info h1.name {
            color: $orange;
        }

        .associations-title {
            color: $orange;
        }

        .business-name {
            .start-date {
                color: $orange;
            }
        }
    }

    .associations {
        hr {
            border-color: $black;
            border: none;
            border-top: 1px solid;
        }

        display: inline-block;
        width: 100%;
    }

    .associations-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-top: 15px;
    }

    .associations-list {
        margin-top: 19px;

        li {
            margin-bottom: 22px;
        }

        label {
            text-transform: capitalize;
        }
    }

    // Dealer
    &.dealer {
        .info h1.name,
        .g-member .g-member__title {
            color: $light-blue;
        }

        .business-name {
            .start-date {
                color: $blue;
            }
        }

        .contact-detail .label,
        .associations-title {
            color: $light-blue;
        }
    }

    .satellites {
        display: inline-block;
        width: 100%;

        .title {
            font-size: 18px;
            line-height: 24px;
            color: $light-blue;
            display: inline-block;
            margin-top: 15px;
        }

        .name {
            font-weight: 700;
        }

        .items {
            margin-top: 19px;
            row-gap: 50px;
            flex-wrap: wrap;
        }

        .item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 487px;
            .image + .detail{
                max-width: 250px;
                width: calc((250/487)*100%);
            }
        }

        img {
            border-radius: 50%;
            object-fit: contain;
        }

        .social a {
            margin-right: 13px;
        }

        .social {
            margin-top: 19px;
        }

        .info-contact {
            margin-top: 10px;
        }
    }

    // Supporter
    &.supporter {
        .info h1.name,
        .g-member .g-member__title {
            color: $light-green;
        }

        .contact-detail .label,
        .associations-title {
            color: $light-green;
        }

        .business-name {
            .start-date {
                color: $green;
            }
        }

        .associations {
            border: none;
        }
    }

    .associations-content {
        border-top: 1px solid #000;
        display: inline-block;
        width: 100%;
    }
}
.public-profile-wrapper .satellites{
    .image {
        margin-right: 15px;
        max-width: 204px;
        width: calc((204/487)*100%);
        img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
            border: solid 1px $black;
        }
    }
}
// Desktop
@media only screen and (min-width: 1200px) {
    .public-profile-body {
        figure img {
            height: 74vh;
        }
    }
}

@media only screen and (min-width: 1089px) {
    .associations-contact-wrapper {
        grid-template-columns: auto 635px;
    }
}

@media only screen and (min-width: 992px) {
    .public-profile-wrapper {
        .left-content {
            flex-basis: calc(100% - 635px);
        }

        .right-content {
            flex-basis: 635px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .public-profile-body {
        figure img {
            height: 50vh;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1088px) {
    .associations-contact-wrapper {
        grid-template-columns: 380px auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .public-profile-wrapper {
        .left-content {
            flex-basis: 40%;
        }

        .right-content {
            flex-basis: 60%;
        }
    }
}

@media only screen and (min-width: 768px) {
    .public-profile-body {
        .mfp-content {
            max-width: 1000px;
        }

        .g-content__question-and-link-inner .question-and-link-row .g-content_question-item h3 {
            margin-bottom: 59px;
        }
    }
    .public-profile-wrapper {
        .info {
            display: flex;
        }

        .left-content {
            padding-right: 30px;
        }

        .right-content {
            .description {
                margin-top: 54px;
            }
        }

        .contact-detail {
            margin-top: 74px;
        }

        .image-profile {
            width: 380px;
            height: 380px;
            margin: 0 auto;
        }

        .share-article {
            display: flex;

            .social {
                justify-content: flex-end;
            }

            .social a {
                margin-left: 13px;
            }
        }

        .artworks {
            margin-top: 56px;
        }

        .g-member {
            margin-top: 44px;
        }

        .grid-items {
            columns: 17rem;
            margin-top: 32px;
        }

        .associations {
            margin-top: 84px;
        }

        &.supporter .associations-list {
            column-count: initial;
        }

        .associations-list {
            column-count: 2;
        }

        .satellites {
            margin: 33px 0 55px;

            &.mobile {
                display: none;
            }

            .items {
                display: flex;
                justify-content: space-between;

            }

            .item {
                &:nth-child(odd){
                    margin-right: 15px;
                }
                break-inside: avoid;
            }
        }

        .g-content__question-and-link-inner .question-and-link-row {
            padding: 10px 0 99px;

            h3 {
                margin-bottom: 68px;
            }
        }

        // Supporter

        &.supporter {
            .associations {
                margin-top: 0;
            }

            .associations-list {
                display: block;

                li {
                    &:nth-child(2n) {
                        padding-left: 0;
                    }
                }
            }

            .contact-detail {
                margin-top: 0;
            }
        }

        .associations-contact-wrapper {
            display: grid;
            column-gap: 30px;
            margin-top: 64px;
        }
    }
}

// Mobile
@media only screen and (max-width: 767px) {
    .public-profile-body {
        .mfp-container {
            padding: 0 32px;
        }

        figure img {
            height: 40vh;
        }

        .mfp-arrow:before {
            font-size: 20px;
        }

        .mfp-arrow {
            transform: translateY(-50%);
        }

        .mfp-title {
            margin-top: 30px;
            padding-right: 0;
        }
    }
    .public-profile-wrapper {
        margin-top: 44px;
        margin-bottom: 30px;

        .description {
            margin-top: 41px;
        }

        .contact-detail {
            margin-top: 47px;
        }

        .info-contact {

            li {
                padding-left: 83px;
            }
        }

        .share-article {
            padding: 11px 7px 15px;
            margin: 26px 0 24px;

            .social {
                margin-top: 11px;
            }
        }

        .social a {
            margin-right: 13px;
        }

        .browse-atists {
            margin-top: 23px;
            display: inline-block;
        }

        .image-profile {
            width: 106px;
            height: 106px;
        }

        .info .browse-atists {
            font-size: 14px;
            line-height: 18px;
        }

        h1 {
            font-size: 28px;
            line-height: 38px;
        }

        .left-content {
            .name {
                margin-top: 3px;
            }
        }

        .list-artwork-types {
            margin-top: 24px;
        }

        .artworks {
            margin-top: 56px;

            .grid-item {
                margin-bottom: 33px;
            }

            .artworks-action {
                margin: 9px 0 30px;
            }
        }

        .grid-items {
            columns: 14rem;
            margin-top: 39px;
        }

        .artworks-action {
            white-space: nowrap;
        }

        .associations {
            margin-top: 14px;
        }

        .g-member {
            margin-top: 37px;
        }

        .satellites {
            margin-top: 47px;

            &.desktop {
                display: none;
            }

            .item {
                margin-bottom: 49px;

                &:last-child {
                    margin-bottom: 30px;
                }
                .image + .detail{
                    max-width: unset;
                    width: 100%;
                }
            }

            .items {
                margin-top: 30px;
            }

            .image {
                aspect-ratio: 1 / 1;
            }
        }

        .g-member .form-group .buttons {
            .btn {
                width: 100%;
                padding: 12px 23px;
            }

            .btn-primary {
                margin-bottom: 24px;
            }
        }

        .g-content__question-and-link {
            background: $white;
        }

        // Dealer
        &.dealer {
            .satellites {
                margin-top: 40px;
            }
        }

        // Supporter
        .associations-contact-wrapper {
            display: flex;
            flex-direction: column;

            .associations {
                order: 1;
            }
        }
    }
}
@media only screen and (max-width: 480px) {
    .public-profile-wrapper {
        .satellites{
            .item{
                flex-direction: column;
            }
            .image{
                max-width: 106px;
                margin-bottom: 22px;
            }
        }
    }
}
@media only screen and (max-width: 370px) {
    .public-profile-wrapper {
        .artworks-action {
            display: block;
            margin-top: 40px;
        }

        .artworks-paginate {
            margin-top: 20px;
        }
    }
}
