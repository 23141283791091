//  Mixins
@mixin css-prefix($declarations, $prefixes: (webkit moz)) {
    @each $property, $value in $declarations {
        #{$property}: $value;
        @each $prefix in $prefixes {
            #{'-' + $prefix + '-' + $property}: $value;
        }
    }
}

@mixin display-flex-cs() {
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
}

// Font

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans/DMSans-BoldItalic.eot');
    src: url('../fonts/DMSans/DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DMSans/DMSans-BoldItalic.woff2') format('woff2'),
    url('../fonts/DMSans/DMSans-BoldItalic.woff') format('woff'),
    url('../fonts/DMSans/DMSans-BoldItalic.ttf') format('truetype'),
    url('../fonts/DMSans/DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans/DMSans-Regular.eot');
    src: url('../fonts/DMSans/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DMSans/DMSans-Regular.woff2') format('woff2'),
    url('../fonts/DMSans/DMSans-Regular.woff') format('woff'),
    url('../fonts/DMSans/DMSans-Regular.ttf') format('truetype'),
    url('../fonts/DMSans/DMSans-Regular.svg#DMSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans/DMSans-Medium.eot');
    src: url('../fonts/DMSans/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DMSans/DMSans-Medium.woff2') format('woff2'),
    url('../fonts/DMSans/DMSans-Medium.woff') format('woff'),
    url('../fonts/DMSans/DMSans-Medium.ttf') format('truetype'),
    url('../fonts/DMSans/DMSans-Medium.svg#DMSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans/DMSans-MediumItalic.eot');
    src: url('../fonts/DMSans/DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DMSans/DMSans-MediumItalic.woff2') format('woff2'),
    url('../fonts/DMSans/DMSans-MediumItalic.woff') format('woff'),
    url('../fonts/DMSans/DMSans-MediumItalic.ttf') format('truetype'),
    url('../fonts/DMSans/DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans/DMSans-Bold.eot');
    src: url('../fonts/DMSans/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DMSans/DMSans-Bold.woff2') format('woff2'),
    url('../fonts/DMSans/DMSans-Bold.woff') format('woff'),
    url('../fonts/DMSans/DMSans-Bold.ttf') format('truetype'),
    url('../fonts/DMSans/DMSans-Bold.svg#DMSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans/DMSans-Italic.eot');
    src: url('../fonts/DMSans/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DMSans/DMSans-Italic.woff2') format('woff2'),
    url('../fonts/DMSans/DMSans-Italic.woff') format('woff'),
    url('../fonts/DMSans/DMSans-Italic.ttf') format('truetype'),
    url('../fonts/DMSans/DMSans-Italic.svg#DMSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-VariableFont_wght.ttf');
    font-weight: normal;
    font-display: swap;
}

.styleguide {
    margin-top: 50px;
    margin-bottom: 100px;

    h3 {
        margin: 15px 0 0;
    }

    .box {
        margin: 30px 0 0;
    }
}

// General
* {
    box-sizing: border-box;
}

body {
    font-family: $font-family-sans-serif;
    margin: 0;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: 400;
    color: $black;

    .body2,
    &.body2 {
        font-size: 18px;
        line-height: 24px;
    }
}

h1,
h2,
.h2-title-config,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

b,
strong {
    font-weight: bold;
}

sub,
sup {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    position: relative;
    vertical-align: baseline;
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}

input.form-control,
select,
textarea {
    font-family: $font-family-sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: $black;
    margin: 0
}

select {
    font-size: $font-size-base;
    line-height: $line-height-base;
    text-transform: none;
    cursor: pointer;
}

blockquote,
dl,
dd,
h1,
h2,
.h2-title-config,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
}

legend {
    padding: 0;
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

input,
textarea {
    &::placeholder,
    &::-moz-placeholder {
        opacity: 1;
        color: $black;
    }
}

button {
    cursor: pointer;
}

:disabled {
    cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}

img,
video {
    max-width: 100%;
    height: auto;
}

.container {
    margin: 0 auto;
}

.black {
    color: $black;
}

.light-black {
    color: $light-black;
}

.orange {
    color: $orange;
}

.light-orange {
    color: $light-orange;
}

.blue {
    color: $blue;
}

.light-blue {
    color: $light-blue;
}

.green {
    color: $green;
}

.light-green {
    color: $light-green;
}

.full-size-bg {
    height: 100%;
    background: $color-border-load;
}

// Style content editor
body {
    .image-caption,
    .video--caption,
    .g-intro__caption,
    .img-description,
    .ql-editor.image-caption {
        font-size: 14px;
        line-height: 16px;
    }

    .image-caption,
    .video--caption,
    .g-intro__caption,
    .img-description {
        .ql-editor {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .ql-editor {
        font-size: $font-size-base;
        line-height: $line-height-base;
        height: auto;
        overflow: unset;
        white-space: normal;

        p,
        ol,
        ul,
        pre,
        blockquote,
        h1,
        h2,
        .h2-title-config,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 1em;
            counter-reset: item;
        }

        h1 {
            font-size: 42px;
            line-height: 51px;
        }

        h2,
        .h2-title-config,
        h3 {
            font-size: 34px;
            line-height: 44px;
        }

        h4 {
            font-size: 18px;
            line-height: 24px;
        }

        h5 {
            font-size: 1em;
            line-height: 1.25em;
        }

        h6 {
            font-size: .67em;
        }

        ol {
            counter-reset: item;

            li {

                &:before {
                    content: counter(item) ". ";
                    counter-increment: item;
                }
            }
        }

        li:not(.ql-direction-rtl):before {
            margin-right: 0.5em;
            margin-left: -1.7em;
        }

        ol,
        ul {
            padding-left: 1.7em;
        }
    }

    .body2 .ql-editor,
    .body2.ql-editor {
        font-size: 18px;
        line-height: 24px;
    }

    .g-intro-shortdesc,
    .f-body-wrapper {
        margin-top: 22px;
    }
}

// Custom select
.custom-select,
.custom-select-special,
.custom-select-state {
    position: relative;

    select {
        position: absolute;
        left: -999999999px;
    }

    .selected-item {
        padding: 10px 28px 7px 0;
        color: $black;
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;
        z-index: 99;
        border-bottom: 1px solid $black;
        white-space: nowrap;
        overflow: hidden;
        @include css-prefix((transition: 0.5s));
        @include css-prefix((user-select: none));

        &:after {
            content: '';
            background: url('../../public/images/arrow-base.svg') center / 100% 100% no-repeat;
            width: 21px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 0;
            @include css-prefix((transition: 0.5s));
            @include css-prefix((transform: translateY(-50%)));
        }

        &:before {
            content: '';
            height: calc(100% - 2px);
            width: 28px;
            position: absolute;
            right: 0;
            top: 0;
            background: transparent;
        }
    }

    .arrowanim.selected-item:after {
        @include css-prefix((transform: translateY(-50%) rotate(180deg)));
    }

    .arrowanim.selected-item {
        padding-left: 7px;
    }

    .item {
        padding: 6px 7px;
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;

        &.selected {
            color: $orange;
        }

        &:hover,
        &:focus {
            background: $black;
            color: $white;
        }
    }

    .all-items {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 220px;
        z-index: 100;
        border: 1px solid $black;
        border-top: none;
        background: $white;
        padding: 5px 0;
        scrollbar-color: $black $color-border-load;
        scrollbar-width: auto;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 8px;
        }

        // Track
        &::-webkit-scrollbar-track {
            background: $color-border-load;
            cursor: pointer;
        }

        // Handle
        &::-webkit-scrollbar-thumb {
            background: $black;
            cursor: pointer;
        }
    }

    .filled + .selected-item.parsley-error,
    .filled + .selected-item {
        border-color: $orange;
    }

    .filled + .selected-item.parsley-success {
        border-color: $black;
    }

    .all-items-hide {
        display: none;
    }
}

// Form

.form-select {
    border: none;
    @include css-prefix((border-radius: 0));
    border-bottom: 1px solid $black;
}

.form-select:focus,
.form-control:focus,
.form-check-input:focus {
    @include css-prefix((box-shadow: none));
}

.form-check-input[type=checkbox] {
    @include css-prefix((border-radius: 0));
    border-color: $black;
    width: 25px;
    height: 25px;
}

.form-check-input:checked {
    background-color: $black;
    border-color: $black;
}

.form-check {
    padding-left: 0;
    position: relative;

    input[type="radio"] {
        position: absolute;
        left: -50000px;
    }

    .radio-label {
        font-size: 14px;
        line-height: 22px;
        font-weight: bold;
        text-transform: uppercase;
        border: 1px solid $color-border-radio;
        width: 64px;
        height: 40px;
        padding: 10px;
        @include display-flex-cs();
        @include css-prefix((align-items: center, justify-content: center, border-radius: 3px));
        cursor: pointer;
    }

    .radio-label-circle {
        padding-left: 31px;
        position: relative;
        font-size: $font-size-base;
        line-height: $line-height-base;
        color: $color-focus-input;
        cursor: pointer;
        display: flex;

        &:after {
            content: '';
            width: 18px;
            height: 18px;
            border: 1px solid $black;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: 0;
            box-sizing: border-box;
        }
    }

    input[type="radio"] {
        &:focus {
            + .radio-label {
                border-width: 2px;
            }

            + .radio-label-circle:after {
                border-width: 2px;
            }
        }
    }

    .form-check-input:checked + .radio-label {
        background: $black;
        color: $white;
    }

    .form-check-input:checked + .radio-label-circle:after {
        background: $black;
    }

    .form-check-input[type=checkbox] {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        position: absolute;
        left: -50000px;

        &:checked + .checkbox-label:after {
            content: '';
            display: block;
            position: absolute;
            top: 3px;
            left: 6px;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        &:checked + .checkbox-label:before {
            background-color: $black;
        }
    }

    .checkbox-label {
        position: relative;
        cursor: pointer;
        color: $color-focus-input;
    }

    .checkbox-label:before {
        content: '';
        @include css-prefix((appearance: none));
        background-color: transparent;
        border: 1px solid $black;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
    }

    input[type="checkbox"] {
        &:focus {
            + .checkbox-label:before {
                border-width: 2px;
            }
        }
    }
}

.form-control {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 3px 0 7px;
    @include css-prefix((border-radius: 0));
    border-bottom: 1px solid $black;

    &::placeholder {
        color: $black;
    }

    &.parsley-error {
        border-color: $orange;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input:disabled {
    cursor: not-allowed;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input.form-control {
    height: 36px;

    &:focus {
        border-bottom: 2.5px solid $black
    }

    &.parsley-error {
        &:focus {
            border-color: $orange;
        }
    }
}

textarea.form-control {
    border: 1px solid $black;
    @include css-prefix((border-radius: 7px));
    padding: 10px 12px;
}

.parsley-errors-list {
    color: $orange;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
}

.parsley-error .form-check-label {
    border-color: $orange;
}

.form-group {
    position: relative;
    color: $black;

    label {
        @include css-prefix((user-select: none));
    }

    .form-label {
        color: $color-form-label;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
        display: block;
    }

}

// Link
a {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $black;
    --d: 100%;
    cursor: pointer;
    text-decoration: none;
    background: linear-gradient(90deg, $black calc(100% / 3), transparent 0 calc(200% / 3), $black 0) var(--d, 0%) 90% /300% 1px no-repeat;
    transition: 0.5s;
    width: fit-content;

    &:hover {
        --d: 0;

        .arrows {
            &:after {
                @include css-prefix((transform: translateX(5px)));
            }
        }
    }

    &:focus {
        outline-color: $black;
    }

    &.arrows-wrap {
        --d: 0;
        background: none;
    }
}

.arrows-wrap {
    text-decoration: none;

    .arrow-icon {
        transition: transform 0.3s ease-out
    }
}

.arrows-wrap .link-text {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $black;
    --d: 100%;
    cursor: pointer;
    text-decoration: none;
    background: linear-gradient(90deg, $black calc(100% / 3), transparent 0 calc(200% / 3), $black 0) var(--d, 0%) 90% /300% 1px no-repeat;
    transition: 0.5s;
    width: fit-content;
    margin-right: 7px;

    &:hover {
        --d: 0;

        + .arrow-icon {
            transform: translateX(5px)
        }
    }

    &:focus {
        outline-color: $black;
    }
}

.arrows-wrap .arrow-icon {
    position: relative;
}

.nav-link {
    font-size: 14px;
    line-height: 18px;
    --d: 0;
    background: linear-gradient($black 0 0) 0 92% /var(--d, 0) 1px no-repeat;
    transition: 0.2s;

    &:hover {
        --d: 100%;
    }
}

.social-link {
    height: 30px;
    width: 30px;
    color: $white;
    display: inline-block;
    text-align: center;
    @include css-prefix((border-radius: 100%));
    @include display-flex-cs();
    @include css-prefix((align-items: center, justify-content: center));
    background: $black;
    text-decoration: none;

    &:hover {
        background: $light-black;
        color: $white;
        background-size: 0;
    }
}

// Arrows custom
.arrows {
    position: relative;

    &:after {
        content: '';
        @include css-prefix((transition: transform 0.3s ease-out));
        display: inline-block;
        position: absolute;
        bottom: 3px;
        margin-left: 13px;
    }

    &:before {
        content: '';
        width: 30px;
        height: 12px;
        background: transparent;
        position: absolute;
        bottom: 2px;
        right: -20px;
    }

    &:hover {
        &:after {
            @include css-prefix((transform: translateX(5px)));
        }
    }

    &.orange {
        &:after {
            filter: invert(42%) sepia(14%) saturate(5543%) hue-rotate(333deg) brightness(92%) contrast(97%);
        }
    }

    &.blue {
        &:after {
            filter: invert(16%) sepia(89%) saturate(2859%) hue-rotate(193deg) brightness(87%) contrast(101%);
        }
    }

    &.green {
        &:after {
            filter: invert(36%) sepia(72%) saturate(5713%) hue-rotate(155deg) brightness(86%) contrast(101%);
        }
    }

    &.white {
        &:after {
            filter: invert(100%) saturate(5713%) hue-rotate(155deg) brightness(100%) contrast(101%);
        }
    }
}

.arrows-long {
    &:after {
        background: url('../../public/images/arrow-long.svg') center / 100% 100% no-repeat;
        min-width: 46px;
        height: 12px;
        bottom: 5px;
    }

    .arrow-icon {
        top: 2px;
        background: url('../../public/images/arrow-long.svg') center / 100% 100% no-repeat;
        min-width: 46px;
        height: 12px;
        display: inline-block;
    }
}

.arrows-long-green {
    position: relative;

    &:after {
        content: '';
        @include css-prefix((transition: transform 0.3s ease-out));
        display: inline-block;
        position: absolute;
        bottom: 3px;
        margin-left: 13px;
        background: url('../../public/images/arrow-long-green.svg') center / 100% 100% no-repeat;
        min-width: 46px;
        height: 12px;
        bottom: 5px;
        position: static;
    }
}

.arrows-long-orange {
    position: relative;

    &:after {
        content: '';
        @include css-prefix((transition: transform 0.3s ease-out));
        display: inline-block;
        position: absolute;
        bottom: 3px;
        margin-left: 13px;
        background: url('../../public/images/arrow-long-orange.svg') center / 100% 100% no-repeat;
        min-width: 46px;
        height: 12px;
        bottom: 5px;
        position: static;
    }
}

.arrows-long-blue {
    position: relative;

    &:after {
        content: '';
        @include css-prefix((transition: transform 0.3s ease-out));
        display: inline-block;
        position: absolute;
        bottom: 3px;
        margin-left: 13px;
        background: url('../../public/images/arrow-long-blue.svg') center / 100% 100% no-repeat;
        min-width: 46px;
        height: 12px;
        bottom: 5px;
        position: static;
    }
}

.arrows-short {
    &:after {
        background: url('../../public/images/arrow-short.svg') center / 100% 100% no-repeat;
        width: 21px;
        height: 12px;
    }
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

// Button
.article-video .play {
    transition: 0.5s
}

.btn {
    font-family: $font-family-sans-serif;
    padding: 6px 15px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    min-height: 40px;
    border: 1px solid $black;
    font-size: 14px;
    line-height: 22px;
    @include css-prefix((border-radius: 3px));

    &:hover {
        transition: 0.5s !important;
    }


    &.btn-primary {
        background: $black;
        border-color: $black;
        color: $white;

        &:hover {
            background: $light-black;
            border-color: $black;
        }
    }

    &.btn-secondary {
        background: transparent;
        color: $black;
        border-color: $black;

        &:hover {
            background: $black;
            color: $white;
        }
    }

    &.download {
        padding: 6px 36px 6px 12px;
        background: $black url("../../public/images/download-icon.svg") 95% 45%/20px 14px no-repeat;
        border-color: #000;
        color: #fff;

        &:hover {
            background: $light-black url("../../public/images/download-icon.svg") 95% 45%/20px 14px no-repeat;
        }
    }
}

a.btn {
    @include display-flex-cs();
    @include css-prefix((align-items: center, justify-content: center));
    background: transparent;
    transition-property: none;
}

.btn-special {
    border: 2px solid;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    max-width: 254px;
    padding: 6px 50px;
    background: transparent;

    &.arrows {
        &:after {
            margin: 9px auto 0;
            margin-left: 0;
            position: static;
        }

        &:before {
            display: none;
        }
    }

    &.orange {
        color: $orange;
        border-color: $orange;
    }

    &.blue {
        color: $blue;
        border-color: $blue;
    }

    &.green {
        color: $green;
        border-color: $green;
    }

    &:hover {
        background: $black;
        border-color: $black;
        background-size: 0;
        color: $white;

        &:after {
            @include css-prefix((transform: translateX(5px)));
        }

        &:after {
            filter: invert(93%) sepia(7%) saturate(0%) hue-rotate(64deg) brightness(107%) contrast(106%) !important;
        }
    }
}

// Loading Circle (CSS Animation)
.load {
    position: relative;
    display: inline-block;
    height: 100px;
    width: 100px;
    margin: 10px;
    background: none repeat scroll 0 0 $color-border-load;
    overflow: hidden;
    @include css-prefix((transform: rotate(180deg), border-radius: 50%));

    &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        display: block;
        height: 96px;
        width: 96px;
        background: none repeat scroll 0 0 $white;
        border-radius: 50%;
        @include css-prefix((transform: translate3d(0, 0, 0)));
    }

    > span {
        position: absolute;
        height: 100%;
        width: 50%;
        overflow: hidden;
    }

    .left {
        left: 0
    }

    .right {
        left: 50%
    }

    .anim {
        position: absolute;
        left: 100%;
        top: 0;
        height: 100%;
        width: 100%;
        background: none repeat scroll 0 0 $orange;
        @include css-prefix((
                border-radius: 999px,
                animation: ui-spinner-rotate-left 3s infinite,
                transform-origin: 0 50% 0
        ));
    }

    .left .anim {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .right .anim {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        left: -100%;
        @include css-prefix((transform-origin: 100% 50% 0));
    }

    .anim {
        @include css-prefix((
                animation-delay: 0s,
                animation-duration: 3s,
                animation-timing-function: linear
        ));
    }

    .right .anim {
        @include css-prefix((
                animation-name: ui-spinner-rotate-right,
                animation-delay: 1.5s
        ));

    }
}

@keyframes ui-spinner-rotate-right {
    0% {
        transform: rotate(0deg)
    }
    25% {
        transform: rotate(180deg)
    }
    50% {
        transform: rotate(180deg)
    }
    75% {
        transform: rotate(360deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes ui-spinner-rotate-left {
    0% {
        transform: rotate(0deg)
    }
    25% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    75% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes ui-spinner-rotate-right {
    0% {
        -webkit-transform: rotate(0deg)
    }
    25% {
        -webkit-transform: rotate(180deg)
    }
    50% {
        -webkit-transform: rotate(180deg)
    }
    75% {
        -webkit-transform: rotate(360deg)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes ui-spinner-rotate-left {
    0% {
        -webkit-transform: rotate(0deg)
    }
    25% {
        -webkit-transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(180deg)
    }
    75% {
        -webkit-transform: rotate(180deg)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }
}

// Desktop
@media all and (min-width: 768px) {
    h1 {
        font-size: 42px;
        line-height: 51px;
    }

    h2,
    .h2-title-config,
    h3 {
        font-size: 34px;
        line-height: 44px;
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
    }

    caption {
        font-size: 14px;
        line-height: 16px;
    }
    .container {
        max-width: 1150px;
        padding: 0 20px;
        width: 100%;
    }

    .mobile {
        display: none;
    }
}

// Mobile
@media all and (max-width: 767px) {
    h1 {
        font-size: 34px;
        line-height: 44px;
    }
    h2,
    .h2-title-config {
        font-size: 28px;
        line-height: 38px;
    }

    caption {
        font-size: 12px;
        line-height: 14px;
    }

    .container {
        max-width: 100%;
        padding: 0 32px;
    }

    .desktop {
        display: none;
    }

    body {
        .image-caption,
        .video--caption,
        .g-intro__caption,
        .img-description,
        .ql-editor.image-caption {
            font-size: 12px;
            line-height: 14px;
        }

        .image-caption,
        .video--caption,
        .g-intro__caption,
        .img-description {
            .ql-editor {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}
